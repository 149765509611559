import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { TYPE_SUCCESS, TYPE_INFO, INTERVAL, PHONE_NUMBER_PATTERN, emailPattern, PAN_NUMBER_PATTERN, CIN_NUMBER_PATTERN, GSTIN_NUMBER_PATTERN, TYPE_FAIL } from "assets/constants/Constants";
import { EMPLOYER } from "../../assets/constants/Icons";
import ShowToast from "components/ShowToast";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { fetchMetadata} from 'lib/utils';
import { createEmployerCall , updateEmployerCall, getMetaDataCall} from "lib/axiosUtils";

const CreateEmployer = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { employerId, employerName: initialEmployerName = null, unitName: initialUnitName = "", employerLocation: initialLocation = "", employerRepresentativeName: initialrepresentativeName = "", employerRepresentativeEmail: initialrepresentativeEmail = "", employerRepresentativeMobile: initialsetRepresentativePhone = "", companyWebsiteUrl: initialcompanyWebsiteUrl = "", pinCode: initialPinCode = "", socialMediaUrl: initialSocialMediaUrl = "", panCardNumber: initialPanCardNumber = "", cinNumber: initialCinNumber = "", gstinNumber: initialGstinNumber = "", unitBankName: initialUnitBankName = "", unitBankAddress: initialUnitBankAddress = "", employerList } = location.state || {};
    const [employerName, setEmployerName] = useState(initialEmployerName ? {label: initialEmployerName, value:initialEmployerName} : null);
    const [unitName, setUnitName] = useState(initialUnitName);
    const [employerLocation, setEmployerLocation] = useState(initialLocation);
    const [representativeName, setRepresentativeName] = useState(initialrepresentativeName);
    const [representativeEmail, setRepresentativeEmail] = useState(initialrepresentativeEmail);
    const [representativeMobile, setRepresentativePhone] = useState(initialsetRepresentativePhone);
    const [unitStatus, setunitStatus] = useState(location.state ? (location.state.unitStatus ? location.state.unitStatus : "") : "");
    const [selectedUnitStatusOption, setUnitStatusSelectedOption] = useState(location.state ? (location.state.unitStatus ? { value: location.state.unitStatus, label: location.state.unitStatus } : "") : "");
    const [state, setState] = useState(props.location.state ? (props.location.state.state ? props.location.state.state : null) : null);
    const [selectedStateOption, setStateSelectedOption] = useState(props.location.state ? (props.location.state.state ? { value: props.location.state.state_id, label: props.location.state.state } : "") : "");
    const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState(initialcompanyWebsiteUrl);
    const [pinCode, setPinCode] = useState(initialPinCode);
    const [socialMediaUrl, setSocialMediaUrl] = useState(initialSocialMediaUrl);
    const [panCardNumber, setPanCardNumber] = useState(initialPanCardNumber);
    const [cinNumber, setCinNumber] = useState(initialCinNumber);
    const [gstinNumber, setGstinNumber] = useState(initialGstinNumber);
    const [unitBankName, setUnitBankName] = useState(initialUnitBankName);
    const [unitBankAddress, setUnitBankAddress] = useState(initialUnitBankAddress);
    const [selectedCityOption, setCitySelectedOption] = useState(location.state ? (location.state.city ? { value: location.state.city, label: location.state.city } : "") : "");
    const [city, setCity] = useState(location.state ? (location.state.city ? location.state.city : "") : "");
    const [error, setError] = useState({});
    const [disabled, setDisabled] =useState(props.location.state ? props.location.state.state ? false :true : true);
    const [companyListComponent, setCompanyListComponent] = useState([]);
    const [unitAccountNumber, setUnitAccountNumber] = useState(props.location.state ? props.location.state.unitAccountNumber ? props.location.state.unitAccountNumber :"" : "");
    const [billingEntityName, setBillingEntityName] =useState(props.location.state ? props.location.state.billingEntityName ? props.location.state.billingEntityName :"" : "")
    const [metaData, setMetaData] = useState(JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : []);
    const[states,setStates] = useState(metaData.length > 0 ? metaData[0].meta_data.states: []);
    const[cities,setCities] = useState(metaData.length > 0 ? metaData[0].meta_data.cities: []);

    useEffect(() =>{
        const fetchData = async() => {
            if(metaData && metaData.length === 0){
                const metaResult = await fetchMetadata();
                setMetaData(metaResult)
                setStates(metaResult[0].meta_data.states);
                setCities(metaResult[0].meta_data.cities);
            }
        }
        fetchData();
    },[metaData])

    useEffect((state) => {
        const setStatesListComponent = (states) => {
            let tempState = "";
            let stateName = "";
            if (props.location.state) {
                stateName = props.location.state.state
            }
            const stateList = states.map((item) => {
                if (item.state_name === stateName) {
                    tempState = { value: item.state_id, label: item.state_name };
                }
                return ({ value: item.state_id, label: item.state_name });
            })
            setStateSelectedOption(stateList);
            setState(tempState);
        }
        setStatesListComponent(states, state);
    }, [states, (props.location.state)]);


    useEffect(() => {
        const setCompanyComponentList = async() => {
            const companyMetaData = metaData[0].meta_data.companies;
            let listComponent = companyMetaData.map((item) => {
                if (props.location.state) {
                    if (employerName === item.company_name) {
                        setEmployerName({ label: item.company_name, value: item.company_id });
                    }
                }
                return ({ label: item.company_name, value: item.company_id })
            })
            setCompanyListComponent(listComponent);
        }
        setCompanyComponentList();
    }, [employerName,(props.location.state),metaData]);

    const setCityListComponent = (cities) => {
        let tempCity = "";
        let cityName = "";
        if (props.location.state) {
            cityName = props.location.state.city;
        }
        const cityList = cities.map((item) => {
            if (item.city_name === cityName) {
                tempCity = { value: item.city_id, label: item.city_name };
            }
            return ({ value: item.city_id, label: item.city_name });
        })
        setCitySelectedOption(cityList);
        setCity(tempCity);
    }
    useEffect(() => {
        const setCityFromState = () => {
            let cityArray = cities.filter((item) => { return item.state_id === state.value });
            let cityName = "";
            let tempCity = "";
            if (props.location.state) {
                cityName = props.location.state.city;
            }
            let cityComponent = cityArray.map((item) => {
                if (item.city_name === cityName) {
                    tempCity = { value: item.city_id, label: item.city_name };
                }
                return ({ label: item.city_name, value: item.city_id })
            })
            setCitySelectedOption(cityComponent);
            setCity(tempCity);
        }
        if (state) {
            setCityFromState();
        }
        else {
        }
    }, [state, cities, (props.location.state)]);

    const unitStatusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'pre-opening', label: 'Pre-Opening' }
    ];


    const createEmployerHandler = async (event) => {
        event.preventDefault();
        if (isFormValid()) {
            const requestData = {
                employerName: (employerName && employerName.__isNew__) ? employerName : employerName.label,
                unitName,
                employerLocation,
                unitStatus: unitStatus.label,
                representativeName,
                representativePhoneNo: representativeMobile,
                representativeEmailId: representativeEmail,
                state: state.label,
                city: city.label,
                companyWebsiteUrl,
                pinCode,
                socialMediaUrl,
                panCardNumber,
                cinNumber,
                gstinNumber,
                unitBankName,
                unitBankAddress,
                unitAccountNumber,
                billingEntityName,
            };
            let dataResponse = null;
            if (employerId === null) {
                dataResponse = await createEmployerCall({},requestData);
            } else {
                dataResponse = await updateEmployerCall({},requestData, employerId);
            }

            if (dataResponse.statusCode === 200) {
                if (employerId === null) {
                    if (dataResponse.data.message === "ExistedEmployer") {
                        const existedUnits = dataResponse.data.result.toString();
                        ShowToast(`${existedUnits} already exist`, TYPE_INFO, INTERVAL, "", "");
                    } else {
                        ShowToast('Please wait while Employer created', TYPE_INFO, INTERVAL, "");
                        if (employerName && employerName.__isNew__) {
                            await updateMetadataInLocalStorage();
                        }
                        ShowToast('Employer created Successfully', TYPE_SUCCESS, INTERVAL, props, "/employer-page");
                    }
                } else {
                    ShowToast('Please wait while Employer updated', TYPE_INFO, INTERVAL, "");
                    if (employerName && employerName.__isNew__) {
                        await updateMetadataInLocalStorage();
                    }
                    ShowToast('Employer updated Successfully', TYPE_SUCCESS, INTERVAL, props, "/employer-page");
                }
            }
        }
    };

    const updateMetadataInLocalStorage = async () => {
        try {
            const getMetaData = await getMetaDataCall({}, {});
            if (getMetaData && getMetaData.data && getMetaData.data.result) {
                localStorage.setItem("metadata", null);
                localStorage.setItem("metadata", JSON.stringify(getMetaData.data.result));
            }
        }
        catch (error) {
            console.log("Error occurred while updating metada in local storage", error);
        }
    }

    const cancelHandler = () => {
        history.push("./employer-page");
    };

    const isFormValid = () => {
        let isValid = true;
        let error = {};

        if (!employerName) {
            isValid = false;
            error.employerNameError = "Please enter company name";
        }

        if (!unitName) {
            isValid = false;
            error.employerUnitError = "Please enter unit name";
        }

        if (!employerLocation) {
            isValid = false;
            error.employerLocationError = "Please enter an employer address";
        }
        if (!unitStatus) {
            isValid = false;
            error.unitStatusError = "Please select unit status";
        }
        if (!representativeName) {
            isValid = false;
            error.representativeNameError = "Please enter a unit representative name";
          }
      
          if (representativeEmail) {
            if (!emailPattern.test(representativeEmail)) {
              isValid = false;
              error.representativeEmailIdError = "Please enter a valid email address.";
            }
          } else {
            isValid = false;
            error.representativeEmailIdError = "Please enter a unit representative email address";
          }
      
          if (representativeMobile) {
            if (!PHONE_NUMBER_PATTERN.test(representativeMobile)) {
              isValid = false;
              error.representativePhoneError = "Please enter a valid mobile number.";
            }
          } else {
            isValid = false;
            error.representativePhoneError = "Please enter a unit representative number";
          }

        if (!pinCode) {
            isValid = false;
            error.pinCodeError = "Please enter a Pin Code number";
        } else {
            const tempPin = pinCode;
            if (tempPin.toString().length !== 6) {
                isValid = false;
                error.pinCodeError = "Please enter a valid Pin Code number";
            }
        }
        if (panCardNumber !== "") {
            if (!PAN_NUMBER_PATTERN.test(panCardNumber)) {
                isValid = false;
                error.panCardNumberError = "Please enter a valid PAN number";
            }
        }
        if (cinNumber !== "") {
            if (!CIN_NUMBER_PATTERN.test(cinNumber)) {
                isValid = false;
                error.cinError = "Please enter a CIN number";
            }
        }
        if (gstinNumber !== "") {
            if (!GSTIN_NUMBER_PATTERN.test(gstinNumber)) {
                isValid = false;
                error.gstinError = "Please enter a GSTIN number";
            }
        }
        
        setError(error);
        return isValid;
    };

    const handleUnitStatusChange = (selected) => {
        setUnitStatusSelectedOption(selected);
        setunitStatus(selected);
    };
    const handleStateChange = (selected) => {
        let selectedCites = cities.filter((item) => {
            return selected.value === item.state_id
        });
        setCityListComponent(selectedCites);
        setDisabled(false);
        setState(selected);
    };

    const setEmployerNameHandler = (event) => {
        setEmployerName(event);
    }

    const handleCityChange = (selected) => {
        setCity(selected);
    };

    const setAccountNumber = (event) => {
        const regularExpression = /^[0-9\b]+$/;
        if (event.target.value === '' || regularExpression.test(event.target.value)) {
            setUnitAccountNumber(event.target.value);
        }
    }

    const handlePinCodeChange = (event) => {
        if (!isNaN(event.target.value) && event.target.value.length < 7) {
            setPinCode(event.target.value);
        }
    }

    const handlePanNumberChange = (event) => {
        if (event.target.value.length < 11) {
            setPanCardNumber(event.target.value)
        }
    }

    const handleGstinNumberChange = (event) => {
        if (event.target.value.length < 16) {
            setGstinNumber(event.target.value)
        }
    }

    const handleCinNumberChange = (event) => {
        if (event.target.value.length < 22) {
            setCinNumber(event.target.value)
        }
    }

    const handleUnitRepresentativeNumberChange = (event) => {
        if (!isNaN(event.target.value)) {
            setRepresentativePhone(event.target.value)
        }
    }

    const isValidEmployerUnit = (event) => {
        const trimValue = event.target.value.trim();
        if (employerList && employerList.length > 0) {
            const duplicateEmployerFound = employerList.find((findItem) => findItem.unit_name.trim() === trimValue && (employerName ? findItem.employer_name === employerName.label ? true : false : true));
            if (duplicateEmployerFound) {
                if (employerId) {
                    if (duplicateEmployerFound._id !== employerId) {
                        ShowToast("Same employer unit '" + trimValue + "' is already present. Please enter different employer unit", TYPE_FAIL, INTERVAL, "", "");
                        setUnitName("");
                    }
                }
                else {
                    ShowToast("Same employer unit '" + trimValue + "' is already present. Please enter different employer unit", TYPE_FAIL, INTERVAL, "", "");
                    setUnitName("");
                }
            }
        }
    }

    return (
        <div className="main-content">
            <div className="back">
                <ul className="breadcrumb1">
                    <li><a href="/employer-page">Employer</a></li>
                    <li>{employerId === null ? "Add New Employer unit" : "Edit Unit"}</li>
                </ul>
            </div>
            <div className="add-meter-main-view">
                <div className="mx-auto text-left border create-meter-div">
                    <form className="pt-1">
                        <div className="meter-icon-wrapper">
                            <h4>{employerId === null ? "Add New Employer unit" : "Edit Unit"}</h4>
                            <i className={`${EMPLOYER} list-table-icon`}></i>
                        </div>

                        <div className="row">
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Company Name <span className="text-danger">*</span></label>
                                <CreatableSelect
                                    placeholder={`Select Company Name`}
                                    name="unitStatus"
                                    value={employerName}
                                    onChange={setEmployerNameHandler}
                                    options={companyListComponent}
                                />
                                <div className="text-danger error-msg">
                                    {error.employerNameError}
                                </div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit Name <span className="text-danger">*</span></label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={unitName} onChange={(e) => setUnitName(e.target.value)} placeholder="Enter Unit Name" onBlur={(e) => isValidEmployerUnit(e)}/>
                                <div className="text-danger error-msg">{error.employerUnitError}</div>
                            </div>
                            <div className="input-label-group col-md-6  ">
                                <label className="form-label">State </label>
                                <div >
                                <Select
                                    placeholder={`Select State`}
                                    name="state"
                                    value={state}
                                    onChange={handleStateChange}
                                    options={selectedStateOption}
                                />
                                </div>
                                <div className="text-danger error-msg">
                                    {error.state}
                                </div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">City </label>
                                <div >
                                <Select className="dropdown-width"
                                    placeholder={`Select City`}
                                    name="city"
                                    value={city}
                                    options={selectedCityOption}
                                    onChange={handleCityChange}
                                    isDisabled={disabled}>
                                </Select>
                                </div>
                                <div className="text-danger error-msg">
                                    {error.city}
                                </div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Address <span className="text-danger">*</span></label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={employerLocation} onChange={(e) => setEmployerLocation(e.target.value)} placeholder="Enter Employer Address" />
                                <div className="text-danger error-msg">{error.employerLocationError}</div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">PIN Code <span className="text-danger">*</span></label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={pinCode} onChange={(e) => { handlePinCodeChange(e) }} placeholder=" Enter PIN Code" />
                                <div className="text-danger error-msg">{error.pinCodeError}</div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit Status <span className="text-danger">*</span></label>
                                <Select
                                    placeholder={`Select Unit Status`}
                                    name="unitStatus"
                                    value={selectedUnitStatusOption}
                                    onChange={handleUnitStatusChange}
                                    options={unitStatusOptions}
                                />
                                <div className="text-danger error-msg">
                                    {error.unitStatusError}
                                </div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit Representative Name <span className="text-danger">*</span></label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={representativeName} onChange={(e) => { setRepresentativeName(e.target.value) }} placeholder="Enter Unit Representative Name" />
                                <div className="text-danger error-msg">{error.representativeNameError}</div>
                            </div>
                            <div className="input-label-group col-md-6 email-input">
                                <label className="form-label">Unit Representative Email <span className="text-danger">*</span></label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input email-small" value={representativeEmail} onChange={(e) => { setRepresentativeEmail(e.target.value) }} placeholder="Enter Unit Representative Email" />
                                <div className="text-danger error-msg">{error.representativeEmailIdError}</div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit Representative Number <span className="text-danger">*</span></label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={representativeMobile} onChange={(e) => { handleUnitRepresentativeNumberChange(e) }} placeholder="Enter Unit Representative Number" />
                                <div className="text-danger error-msg">{error.representativePhoneError}</div>
                            </div>
                            <div className="input-label-group col-md-6 email-input">
                                <label className="form-label">Unit Website Link</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={companyWebsiteUrl} onChange={(e) => { setCompanyWebsiteUrl(e.target.value) }} placeholder=" Enter Unit Website Link" />
                            </div>
                            <div className="input-label-group col-md-6 email-input">
                                <label className="form-label">Unit Social Media</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={socialMediaUrl} onChange={(e) => { setSocialMediaUrl(e.target.value) }} placeholder=" Enter Unit Social Media" />
                            </div>
                            <div className="input-label-group col-md-6"></div>
                            <div className="bank-details-div">
                                <label className="bank-detail-label">Please input the below details to ensure a smooth process for The Job Plus and finance through the project </label>
                                <div className="section-div"></div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Billing Entity Name</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={billingEntityName} onChange={(e) => { setBillingEntityName(e.target.value) }} placeholder=" Enter Billing Entity Name" />
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit CIN Number</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input text-transform-uppercase" value={cinNumber} onChange={(e) => { handleCinNumberChange(e) }} placeholder=" Enter CIN Number" />
                                <div className="text-danger error-msg">{error.cinError}</div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit GSTIN Number</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input text-transform-uppercase" value={gstinNumber} onChange={(e) => { handleGstinNumberChange(e) }} placeholder=" Enter GSTIN Number" />
                                <div className="text-danger error-msg">{error.gstinError}</div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit PAN Card Number</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input text-transform-uppercase" value={panCardNumber} onChange={(e) => { handlePanNumberChange(e) }} placeholder=" Enter Unit PAN Card Number" />
                                <div className="text-danger error-msg">{error.panCardNumberError}</div>
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit Account Number</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={unitAccountNumber} onChange={(e) => { setAccountNumber(e) }} placeholder=" Enter Unit Account Number" />
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit Bank Name</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={unitBankName} onChange={(e) => { setUnitBankName(e.target.value) }} placeholder=" Enter Unit Bank Name" />
                            </div>
                            <div className="input-label-group col-md-6">
                                <label className="form-label">Unit Bank Address</label>
                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={unitBankAddress} onChange={(e) => { setUnitBankAddress(e.target.value) }} placeholder=" Enter Unit Bank Address" />
                            </div>
                        </div>
                        <div className="row mt-4 mb-5">
                            <div className="form-button-div">
                                <button className="brand-button" onClick={createEmployerHandler}>Submit</button>
                                <button className="cancel_button ml-5" onClick={cancelHandler}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateEmployer;