import jwt from 'jsonwebtoken';
import { refreshAccessTokenCall } from "lib/axiosUtils";
import Toast from "light-toast";

export const setTokenExpireTime = async (accessToken) => {
    const decodedAccessToken = decodeToken(accessToken);

    if (decodedAccessToken && decodedAccessToken.exp) {
        localStorage.setItem("tokenExpireTime", decodedAccessToken.exp);
    }
};

const decodeToken = (token) => {
    try {
        return jwt.decode(token);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

export const refreshAccessToken = async (refreshToken) => {
    try {
        if (refreshToken !== null) {
            const response = await refreshAccessTokenCall({}, refreshToken);
            localStorage.removeItem("JWToken");
            localStorage.setItem("JWToken", response.data.result.tkn);
            await setTokenExpireTime(response.data.result.tkn);
        } else {
            Toast.fail("Session expired. Redirecting to login page...", 3000, () => {
                localStorage.clear();
                window.location.href = "/login-page";
            });
        }
    } catch (error) {
        localStorage.removeItem("JWToken");
        console.error('Error refreshing access token:', error);
    }
};