import React, { useState, useContext, useEffect } from "react";
import { LOADING_SPIN } from "assets/constants/Icons";
import AuthContext from "context/AuthProvider";
import { TJP_URL } from "assets/constants/Constants";
import { useParams } from "react-router-dom";
import { createJobseeker } from "lib/axiosUtils";

const AcceptanceCandidate = () => {
    const authContext = useContext(AuthContext);
    const { candidateEmail } = useParams();
    const { styles } = authContext;
    const brandButton = styles.brandButton;

    const [isLoading, setIsLoading] = useState(true);
    const [creatingString, setCreatingString] = useState("Please wait while Creating Profile..");
    const [buttonName, setButtonName] = useState("Login Now!");
    const [emailID] = useState(decodeURIComponent(candidateEmail));

    useEffect(() => {
        const createJobseekerProfile = async () => {
            const requestData = {
                email: emailID
            }
            if (emailID) {
                try {
                    const dataResponse = await createJobseeker({}, requestData);
                    if (dataResponse.data.success) {

                        if (dataResponse.data.message === "No Candidate Found on SP") {
                            setCreatingString("No Candidate Found on Project, Please Registration by below button");
                            setButtonName("Register Now");
                        }

                        console.log("dataResponse.data.result.emailResponse:", dataResponse.data.result.emailResponse);
                        if (dataResponse.data.message === "Jobseeker profile response" && dataResponse.data.result.emailResponse) {
                            setCreatingString(`Your profile has been successfully created! Your credentials sent on your email ID ${emailID} Please login by below button`);
                        }

                        if (dataResponse.data.result === "Username already exists. Handle accordingly.") {
                            setCreatingString(`Your profile is already created on The Job Plus platform using email ${emailID}, Please login by below button`);
                        }

                    } else {
                        setCreatingString(`Something went wrong, Please try after sometimes or Register by below button`);
                        setButtonName("Register Now");
                    }
                    setIsLoading(false);
                } catch (error) {
                    console.log("Error:", error);
                    setIsLoading(false);
                }
            }
        }
        createJobseekerProfile();
    }, [emailID]);


    const handleLogin = () => {
        if (buttonName === "Register Now") {
            window.open(`${TJP_URL}/sign-up`, "_blank");
        } else {
            window.open(`${TJP_URL}/login`, "_blank");
        }
        window.close();
    }

    return (
        <>
            <div className={`outer_login_div`}>
                <div className="main_div">
                    <div className="brand_logo_view">
                        <img alt="Brand Logo" className="img-no-padding img-responsive" src="https://www.thejobplus.com/img/logo-default-286x52.webp" />
                    </div>
                    <div className="tjp-title">The Job Plus</div>
                    <p className="creating-para">{creatingString}</p>
                    <form id="redirectURL">
                        {
                            isLoading ? (
                                <p className="creating-para"><i className={LOADING_SPIN} /></p>
                            ) : (
                                <div className={`input_box ${brandButton}`}>
                                    <button type="submit" onClick={handleLogin}>{buttonName}</button>
                                </div>
                            )
                        }
                    </form>
                </div>
            </div>
        </>
    )
}

AcceptanceCandidate.contextType = AuthContext;
export default AcceptanceCandidate;