import moment from "moment";
import Toast from 'light-toast';
import ShowToast from 'components/ShowToast';
import { TYPE_SUCCESS,TYPE_FAIL, INTERVAL, TYPE_INFO } from "assets/constants/Constants";
import { getMetaDataCall, createBulkCandidate, sendDropoutEmail } from "lib/axiosUtils";

export const onSortColumn = (dataArray, column, orderFlag) => {
    let sortedData = [];
    if (orderFlag === "asc") {
        sortedData = dataArray.sort((a, b) => {
            if (a[column] < b[column]) return -1;
            if (a[column] > b[column]) return 1;
            if (a[column] === b[column]) return 1;
            return 1;
        });
    } else {
        sortedData = dataArray.sort((a, b) => {
            if (a[column] > b[column]) return -1;
            if (a[column] < b[column]) return 1;
            if (a[column] === b[column]) return 1;
            return 1;
        });
    }
    return sortedData;
}

export const defaultFormatDate = (item) => {
    let formatedDate = null;
    formatedDate = moment(item).format("DD-MM-YYYY"); 
    return formatedDate;
}

export const showLoading = (message = "Please wait...") => {
    Toast.loading(message, () => {});
}

export const hideLoading = () => {
    Toast.hide();
}

export const registerBulkCandidate = async (requestData) => {
    let importData = [];
    if (requestData && requestData.length > 0) {
        try {
            const dataResponse = await createBulkCandidate({}, requestData);
            importData = dataResponse.data.result;
            ShowToast("Candidate Imported successfully", TYPE_SUCCESS, INTERVAL, "", "");
            return importData;
        }
        catch (error) {
            console.log("Error Occurred while registring bulk candidate through excel import", error);
            ShowToast("Something went wrong. Please try importing again after sometime!", TYPE_FAIL, INTERVAL, "", "");
            return importData;
        }
    }
    else{
        ShowToast("No Candidate in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
        return importData;
    }
}

export const validateCandidateData = (requestData) => {
    let validateFlag = true;
    requestData.forEach((candidateItem) => {
        if (validateFlag === true && candidateItem.validCandidate === false) {
            validateFlag = false;
        }
    })
    return validateFlag;
}

export const fetchMetadata = async () => {
    try {
        const getMetaData = await getMetaDataCall({},{});
        localStorage.setItem("metadata", JSON.stringify(getMetaData.data.result));
        return getMetaData.data.result;
    }
    catch (error) {
        console.log("Error Occurred while fetching metadata", error);
    }
}

export const toCapitaliseWord = (string) => {
    return string.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
};

export const sendDroppedEmailCandidate = async (droppedData) => {
    try {
        if (droppedData.email) {
            const emailData = {
                candidateEmailId: droppedData.email,
                firstName: droppedData.first_name,
                lastName: droppedData.last_name,
                adhaarNo: droppedData.adhaar_no,
                programId: droppedData.programId
            }
            await sendDropoutEmail({}, emailData);
        }
    } catch (error) {
        console.log("Error while sending dropped candidate email", error);
    }
}

export const validateEmployerData = (requestData) => {
    let validateFlag = true;
    requestData.forEach((employerItem) => {
        if (validateFlag === true && employerItem.validEmployer === false) {
            validateFlag = false;
        }
    })
    return validateFlag;
}

export const validatePartnerData = (requestData) => {
    let validateFlag = true;
    requestData.forEach((partnerItem) => {
        if (validateFlag === true && partnerItem.validPartner === false) {
            validateFlag = false;
        }
    })
    return validateFlag;
}

export const generateFinanceYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return [
        { label: "All Years", value: "all" },
        { label: `${currentYear} - ${currentYear + 1}`, value: `${currentYear}-${currentYear + 1}` },
        { label: `${currentYear - 1} - ${currentYear}`, value: `${currentYear - 1}-${currentYear}` },
        // { label: `${currentYear - 2} - ${currentYear - 1}`, value: `${currentYear - 2}-${currentYear - 1}` }
    ]
}

export const detectDateFormat = (stringDate) => {
    const possibleFormats = [
        'YYYY-MM-DD',
        'DD/MM/YYYY',
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'MM/DD/YYYY',
        'MMM DD YYYY',
        'DD MMM YYYY',
        'Do MMM YYYY',
        'DD/MM/YY',
        'DD-MM-YY',
        'DD.MM.YY',
        'DD MMM YY',
    ];

    let detectedFormat = null;
    let detectedFlag = false;
    possibleFormats.forEach(format => {
        if (moment(stringDate, format, true).isValid()) {
            if (detectedFlag === false) {
                detectedFormat = format;
                detectedFlag = true;
            }
        }
    });
    return detectedFormat;
}

export const decimalToTime = (decimalTime) => {
    const totalSeconds = decimalTime * 24 * 60 * 60;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const formattedHours = hours % 12 || 12; // Handle 0 hours as 12 AM
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const ampm = hours < 12 ? 'AM' : 'PM';
    const formattedTime = formattedHours + ':' + formattedMinutes + ' ' + ampm;
    return formattedTime;
}