import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar }  from "material-table";
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL, TYPE_INFO } from "assets/constants/Constants";
import { EDIT_ICON, TRASH_ICON, METER_ICON, EXPORT_ICON } from "assets/constants/Icons";
import ShowToast from 'components/ShowToast';
import ExcelExportComponent from "components/ExcelExportComponent";
import { hasPermission } from 'components/Permission';
import Spinner from 'components/Spinner';
import { getPartnerList, deletePartner, createBulkPartner } from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { importPartner } from "components/TrainingPartner/ImportPartner";
import { validatePartnerData } from "lib/utils";
import ImportErrorPopup from "components/ImportErrorPopup";

const PartnerPage = () => {
  const history = useHistory();
  const [partnerData, setPartnerData] = useState([]);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const mytheme =  createTheme({});
  const fileInputRef = useRef(null);
  const [importLoading, setImportLoading] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [partnerImportData, setPartnerImportData] = useState([]);

  useEffect(() => {
    fetchPartnerData();
  }, []);

  const fetchPartnerData = async () => {
    try {
      const response = await getPartnerList({},{});
      const data = response.data.result;
      setPartnerData(data);
      setLoadingComplete(true);
    } catch (error) {
      console.log("Error while fetching Partner Data", error);
      setLoadingComplete(true);
    }
  }

  const addPartner = () => {
    history.push("/add-partner-page", { partnerId: null, partnerList: partnerData });
  }

  const editPartner = (data) => {
    const propsData = {
      ...data,
      partnerList: partnerData
    }
    history.push("/add-partner-page", propsData);
  }

  const handleDelete = async (item) => {
    if (window.confirm("Are you sure you want to delete this Partner?")) {
      try {
        await deletePartner({},{},item);
        await fetchPartnerData();
        ShowToast('Training Partner deleted successfully', TYPE_SUCCESS, INTERVAL, "", "");
      } catch (error) {
        ShowToast('Something went wrong. Please delete again after some time!', TYPE_FAIL, INTERVAL, "", "");
      }
    }
  }

  const tableColumns = [
    { title: "Partner Name", field: "partnerName" },
    { title: "Training Partner", field:"trainingPartner"},
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    { title: "Representative Name", field: "representativeName" },
    { title: "Representative Email Id", field: "representativeEmail" },
    { title: "Representative Mobile No", field: "representativeMobile" },
    { title: "PAN Card Number", field: "panCardNumber"},
    { title: "Billing Entity Name", field: "invoiceName"},
    { title: "CIN Number", field: "cinNumber"},
    { title: "GSTIN Number", field: "gstinNumber"},
    { title: "Pincode", field: "pinCode" },
    { title: "Address Line 1", field: "addressLineOne" },
    { title: "Address Line 2", field: "addressLineTwo" },
    { title: "Partner Account Number", field: "partnerAccountNumber" },
    { title: "Partner Bank Name", field: "partnerBankName" },
    { title: "Partner Bank Address", field: "partnerBankAddress" }
 
  ];

  const handlePartnerDashboard = (partnerId) => {
    history.push("/partner-dashboard-page", partnerId);
  }

  const tableActions = [
    hasPermission("training_partner", "view_dashboard") && (
    {
      icon: () => <i className={`${METER_ICON}`} title="Partner Dashboard"></i>,
      onClick: (event, rowData) => handlePartnerDashboard(rowData.partnerId),
    }),
    hasPermission("training_partner", "update") && (
      {
        icon: () => <i className={`${EDIT_ICON}`} title="Edit Partner"></i>,
        onClick: (event, rowData) => editPartner(rowData)
      }
    ),
    {
      icon: () => <i className={`${TRASH_ICON}`} title="Delete Partner"></i>,
      onClick: (event, rowData) => handleDelete(rowData.partnerId)
    }
  ];

  const tableOptions = {
    actionsColumnIndex: 9,
    headerStyle: {
      color: '#999998',
      fontWeight: 'bolder',
      fontSize: 15
    },
    sorting: true,
    columnsButton: true,
    exportButton: true,
    exportFileName: "Partner List"
  };

  const importPartnerExcel = async () => {
    fileInputRef.current.click();
}

const handlePartnerExcelUpload = async (event) => {
    setImportLoading(true);
    try {
        if (event && event.target && event.target.files[0]) {
            const requestData = await importPartner(event, partnerData);
            if (requestData && requestData.length > 0) {
                const validateFlag = validatePartnerData(requestData);
                try {
                    if (validateFlag) {
                        const importData = await createBulkPartner({}, requestData);
                        if (importData.data && importData.data.result && importData.data.result.length > 0) {
                            if (importData.data.message === "ExistedPartner") {
                                const existedPartners = importData.data.result.toString()
                                ShowToast(`${existedPartners} already exist`, TYPE_INFO, INTERVAL, "", "");
                                setImportLoading(false);
                            } else {
                                await fetchPartnerData();
                                ShowToast(`${importData.data.result.length} Partners imported successfully`, TYPE_SUCCESS, INTERVAL, "", "");
                            }
                        } else {
                            ShowToast("Something went wrong. Please try again!", TYPE_FAIL, INTERVAL, "", "");
                        }
                    }
                    else {
                        setIsModelOpen(true);
                        setPartnerImportData(requestData);
                    }

                    setImportLoading(false);
                }
                catch (error) {
                    console.log("Error Occurred while importing employer", error);
                    setImportLoading(false);
                }
            }
            else {
                ShowToast("No employer in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
                setImportLoading(false);
            }
        }
        else {
            ShowToast("Please select employer excel for Importing", TYPE_INFO, INTERVAL, "", "");
            setImportLoading(false);
        }
    }
    catch (error) {
        console.log("Error Occurred while importing", error);
        setImportLoading(false);
    }
}

const cancelHandle = () => {
    if (isModelOpen === true) {
        setIsModelOpen(false);
        window.location.reload();
    }
}

  return (<>
    {
      loadingComplete === false ? (
        <Spinner />
      ) : (
        <div className="main-content">
          <div className="list-wrapper">
            {
              hasPermission("training_partner", "create") && (
                <div className="form-button-div">
                  <button className="brand-button width-auto" onClick={addPartner}>Add Training Partner</button>
                  <input
                    type="file"
                    accept=".xlsx"
                    onChange={handlePartnerExcelUpload}
                    onClick={(e) => { e.target.files[0] && handlePartnerExcelUpload(e) }}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  {
                    importLoading === true ? (
                      <div className="ml-5">
                        <div className="spinner-loader">
                        </div>
                      </div>
                    ) : (
                      <button className="button-secondary width-auto ml-5" onClick={importPartnerExcel}>Import Training Partner&nbsp;&nbsp;&nbsp;<i className={EXPORT_ICON} /></button>
                    )
                  }
                </div>
              )
            }
            <div>
              <div className="customer-button-div"></div>
              <div className="mt-4 border list-table-div candidate-material-table-div program-candidate-material-table-div" style={{ marginBottom: 50 }}>
                  <ThemeProvider theme={mytheme}>
                    <MaterialTable
                      columns={tableColumns}
                      data={partnerData.map(item => ({
                        partnerName: item.partner_name,
                        trainingPartner: item.training_partner,
                        representativeName: item.representative_name,
                        representativeEmail: item.representative_email_id,
                        representativeMobile: item.representative_phone_no,
                        partnerId: item._id,
                        addressLineOne: item.address_line_one,
                        addressLineTwo: item.address_line_two,
                        state: item.state,
                        city: item.city,
                        pinCode: item.pin_code,
                        panCardNumber: item.pan_card_number,
                        invoiceName: item.invoice_name,
                        cinNumber: item.cin_number,
                        gstinNumber: item.gstin_number,
                        partnerBankName: item.partner_bank_name,
                        partnerBankAddress: item.partner_bank_address,
                        partnerAccountNumber: item.partner_account_number,
                      }))}
                      actions={tableActions}
                      options={tableOptions}
                      components={{
                        Toolbar: (props) => (
                          <div>
                            <MTableToolbar {...props} />
                            {
                              partnerData.length > 0 && loadingComplete === true && (
                                hasPermission("training_partner", "download_excel") && (
                                  <ExcelExportComponent {...props} partnerModule={true} />
                                )
                              )
                            }
                          </div>
                        ),
                      }}
                    />
                  </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
     )
    }
    <ImportErrorPopup modelOpen={isModelOpen} closeModel={cancelHandle} partnerImportData={partnerImportData} />
  </>);
}

export default PartnerPage;