import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root')
function ImportErrorPopup(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [errorModule, setErrorModule] = useState("candidate");
    useEffect(() => {
        setModalOpen(props.modelOpen);
        if (props.candidateImportData && props.candidateImportData.length > 0) {
            let candidateList = [];
            props.candidateImportData.forEach((item) => {
                if (item.validCandidate === false) {
                    const pushObject = {
                        ...item,
                        errorMessage: item.errorMessage.join(", ")
                    }
                    candidateList.push(pushObject)
                }
            })
            setErrorList(candidateList)
        }

        if (props.employerImportData && props.employerImportData.length > 0) {
            setErrorModule("employer");
            let employerList = [];
            props.employerImportData.forEach((item) => {
                if (item.validEmployer === false) {
                    const pushObject = {
                        ...item,
                        errorMessage: item.errorMessage.join(", ")
                    }
                    employerList.push(pushObject)
                }
            })
            setErrorList(employerList);
        }

        if (props.partnerImportData && props.partnerImportData.length > 0) {
            setErrorModule("partner");
            let partnerList = [];
            props.partnerImportData.forEach((item) => {
                if (item.validPartner === false) {
                    const pushObject = {
                        ...item,
                        errorMessage: item.errorMessage.join(", ")
                    }
                    partnerList.push(pushObject)
                }
            })
            setErrorList(partnerList);
        }
    }, [props]);

    const closeModal = () => {
        setModalOpen(false);
        props.cancelHandle();
    }

    const cancelHandle = (event) => {
        setModalOpen(false);
        props.closeModel();
    }

    return (
        <>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="program-modal" overlayClassName="program-modal-overlay">
                <div className="program-modal-container job-disabled-width partner-modal-div program-modal-div" role="document">
                    <div className="program-modal-inner-div error-modal-inner-div">
                        <h4 className="import-error-heading">ERROR OCCURRED !!</h4>
                        {
                            <table>
                                <thead>
                                    <tr className="program-modal-main-row-header">
                                        <th>Sr No</th>
                                        <th>{errorModule === "candidate" ? "Candidate Name" : (errorModule === "employer" ? "Company Name" : "Training Partner")}</th>
                                        <th>Error Occurred</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        errorList.length > 0 && (
                                            errorList.map((listItem, index) => {
                                                let rowClass = "";
                                                if (index % 2) {
                                                    rowClass = "program-modal-row-background-color"
                                                }
                                                return (
                                                    <tr className={rowClass} key={`error-${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{errorModule === "candidate" ? listItem.firstName +" "+ listItem.lastName : (errorModule === "partner" ? listItem.training_partner : listItem.employer_name)}</td>
                                                        <td>{listItem.errorMessage}</td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="form-button-div mt-5">
                        <button className="brand-button" onClick={cancelHandle}>Try Again</button>
                        <button className="button-secondary ml-3" onClick={cancelHandle}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </>
    );

}
export default ImportErrorPopup;