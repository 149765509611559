import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root')
function CandidateHistoryPopupComponent(props) {
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(props.modelOpen);
    }, [props]);

    const closeModal = () => {
        setModalOpen(false);
        props.cancelHistoryModalHandle();
    }

    const cancelHandle = (event) => {
        setModalOpen(false);
        props.closeModel();
    }

    return (
        <>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="program-modal" overlayClassName="program-modal-overlay">
                <div className="program-modal-container job-disabled-width partner-modal-div program-modal-div" role="document">
                    <div className="program-modal-inner-div history-popup-modal-inner-div">
                        {
                            props && props.candidateHistoryData.length > 0 && (<>
                                <h4 className="import-error-heading">PROJECT HISTORY</h4>
                                <table>
                                    <tr className="program-modal-main-row-header">
                                        <th>Sr No</th>
                                        <th>Project Name</th>
                                        <th>History</th>
                                    </tr>
                                    {
                                        props.candidateHistoryData.map((historyItem, index) => {
                                            let rowClass = "";
                                            if (index % 2) {
                                                rowClass = "program-modal-row-background-color"
                                            }
                                            return (
                                                <tr className={rowClass}>
                                                    <td>{index + 1}</td>
                                                    <td>{historyItem.program_name}</td>
                                                    <td>{historyItem.status === "Completed" ? "Project Completed" : historyItem.status}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </>)
                        }
                    </div>
                    <div className="form-button-div mt-5">
                        <button className="button-secondary ml-3" onClick={cancelHandle}>Close</button>
                    </div>
                </div>
            </Modal>
        </>
    );

}
export default CandidateHistoryPopupComponent;