import React, { useState , useEffect } from "react";
import { USERS_ICON } from "../../assets/constants/Icons";
import { TYPE_SUCCESS, TYPE_INFO, INTERVAL, emailPattern, PHONE_NUMBER_PATTERN } from "assets/constants/Constants";
import ShowToast from 'components/ShowToast';
import { useLocation ,useHistory } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import "./candidate.css";
import { fetchMetadata} from 'lib/utils';
import { getEmployerList, getPartnerList, createCandidateCall, updateCandidateCall, checkEmailPresent, checkAadharPresent } from "lib/axiosUtils";

const CreateCandidate = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [candidateID] = useState(location.state ? (location.state.candidateId ? location.state.candidateId : null): null);
    const [firstName, setFirstName] = useState(location.state ? (location.state.firstName ? location.state.firstName : "") : "");
    const [lastName, setLastName] = useState(location.state ? (location.state.lastName ? location.state.lastName : ""): "");
    const [fatherName, setFatherName] = useState(location.state ? (location.state.fatherName ? location.state.fatherName : ""):"");
    const [dobDate, setdobDate] = useState(location.state ? (location.state.dobDate ? new Date(location.state.dobDate) : null): "");
    const [ageNumber, setAgeNumber] = useState(location.state ? (location.state.ageNumber ? location.state.ageNumber : "") : "");
    const [mobileNumber, setMobileNumber] = useState(location.state ? (location.state.mobileNumber ? location.state.mobileNumber : ""): "");
    const [qualificationsName, setQualificationsName] = useState(location.state ? (location.state.qualificationsName ? location.state.qualificationsName : ""): "");
    const [adharNo, setAdharNo] = useState(location.state ? (location.state.adharNo ? location.state.adharNo : ""): "");
    const [email, setEmail] = useState(location.state ? (location.state.email ? location.state.email : ""): "");
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [gender, setGender] = useState(location.state ? (location.state.gender ? location.state.gender : ""): "");
    const [error, setError] = useState({});
    const [selectedGenderOption, setGenderSelectedOption] = useState(location.state ? (location.state.gender ? { value: location.state.gender, label: location.state.gender } : ""): "");
    const [qualificationsListComponent, setQualificationsListComponent] = useState([]);
    const [departmentSelected, setDepartmentSelected] = useState(location.state ? (location.state.departmentSelected ? {label:location.state.departmentSelected,value:location.state.departmentSelected} : null) : null);
    const [departmentListComponent, setDepartmentListComponent] = useState("<option> Select Department </option>");
    const [employerListComponent, setEmployerListComponent] = useState("<option> Select Employer unit </option>");
    const [partnerListComponent, setPartnerListComponent] = useState("<option> Select Partner </option>");
    const [employerUnit, setEmployerUnit] = useState(location.state ? (location.state.employerUnit ? {label:location.state.employerUnit,value:location.state.employerId} : null) : null);
    const [trainingPartner, setTrainingPartner] = useState(location.state ? (location.state.trainingPartner ? {label:location.state.trainingPartner,value:location.state.partnerId} : null) : null);
    const [stateComponent, setStateComponent] = useState("<option> Select State </option>");
    const [cityComponent, setCityComponent] = useState("<option> Select City </option>");
    const [metaData,setMetaData] = useState(JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : []);
    const [programId] = useState(location.state ? (location.state.programId ? location.state.programId : "") : "");
    
    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' }
    ];

    useEffect(() =>{
        const fetchData = async() => {
            if(metaData && metaData.length === 0){
                const metaResult = await fetchMetadata();
                setMetaData(metaResult)
            }
        }
        fetchData();
    },[metaData])

    useEffect(() => {
        const setQualificationsComponentList = () => {
            const qualificationsMetaData = metaData[0].meta_data.qualifications;
            let listQualifications = qualificationsMetaData.map((item) => {
                if (props.location.state) {
                    if (qualificationsName === item.education_name) {
                        setQualificationsName({ label: item.education_name, value: item.education_name });
                    }
                }
                return ({ label: item.education_name, value: item.education_name })
            })
            setQualificationsListComponent(listQualifications);
        }
        setQualificationsComponentList();
        setDepartmentComponentList();
        // eslint-disable-next-line
    }, [qualificationsName,(props.location.state),metaData]);

    const setUnitComponent = (list) => {
        let listComponent =  list.map((item) => {
            return ({label: item.unit_name, value: item._id});
        })
        setEmployerListComponent(listComponent);
    }

    const setPartnerComponentList = (list) => {
        let listComponent =  list.map((item) => {
            return ({label: item.training_partner, value: item._id});
        })
        setPartnerListComponent(listComponent); 
    }

    useEffect(() =>{
        const fetchEmployerList = async () => {
            try {
                const data = await getEmployerList();
                setUnitComponent(data.data.result);
            }
            catch (error) {
                console.log("error while fetching data", error);
            }
        }
        fetchEmployerList();
        const fetchPartnerList = async () => {
            try {
                const response = await getPartnerList();
                setPartnerComponentList(response.data.result);
            }
            catch (error) {
                console.log("Error Occurred while fetching training partner list", error);
            }
        }
        fetchPartnerList();
    },[props])

    useEffect(() => {
        setStateListComponent();
        setCityListComponent();
        // eslint-disable-next-line
    },[props]);

    const setStateListComponent = () =>{ 
        const list  = metaData[0].meta_data.states.map((item) => {
            if(props.location.state && props.location.state.state){
                if(item.state_name.toLowerCase() === props.location.state.state.toLowerCase() ){
                    setState({label: item.state_name, value: item.state_id });
                }
            }
            return ({label: item.state_name, value: item.state_id})
        });
        setStateComponent(list);
    }

    const setCityListComponent = () => {
        const list =  metaData[0].meta_data.cities.map((item) => {
            if(props.location.state && props.location.state.city){
                if(item.city_name.toLowerCase() === props.location.state.city.toLowerCase() ){
                    setCity({label: item.city_name, value: item.city_id });
                }
            }
            return ({label: item.city_name, value: item.city_id});
        })
        setCityComponent(list);
    } 

    const setDepartmentComponentList = () => {
        const department =  metaData[0].meta_data.department;
        const list = department.map((item) => {
            return ({ label: item, value: item });
        })
        setDepartmentListComponent(list);
    }

    const adharChangeHandler = (event) => {
        if(!isNaN(event.target.value)){
            setAdharNo(event.target.value);
        }
    }
    
    const mobileNumberHandler = (event) => {
        if(!isNaN(event.target.value)){
            setMobileNumber(event.target.value);
        }
    }

    const ageHandler = (event) => {
        if(!isNaN(event.target.value)){
            setAgeNumber(event.target.value);
        }
    }

    const createCandidateHandler = async (event) => {
        event.preventDefault();
        if (isFormValid()) {
            const isEmailPresent = await checkEmailPresent({}, {}, email);
            const isAadharPresent = await checkAadharPresent({}, {}, adharNo);

            if ((isEmailPresent.data.result.length > 0 || isAadharPresent.data.result.length > 0) && !candidateID) {
                let error = {};
                if (isEmailPresent.data.result.length > 0) {
                    error.emailIdError = "Entered Email ID is associated with another Candidate";
                }

                if (isAadharPresent.data.result.length > 0) {
                    error.aadharNumberError = "Entered Aadhar number is associated with another Candidate";
                }
                setError(error);
            } else {
                setError({});
                const requestData = {
                    firstName: firstName,
                    lastName: lastName,
                    fatherName: fatherName,
                    dobDate: dobDate,
                    ageNumber: ageNumber,
                    mobileNumber: mobileNumber,
                    adhaarNo: adharNo,
                    qualification: qualificationsName.label,
                    email: email,
                    gender: gender,
                    state: state ? state.label : "",
                    city: city ? city.label : "",
                    departmentSelected: departmentSelected ? departmentSelected.label : "",
                    employerUnit: employerUnit ? employerUnit.label : "",
                    trainingPartner: trainingPartner ? trainingPartner.label : "",
                    partnerId: trainingPartner ? trainingPartner.value : "",
                    employerId: employerUnit ? employerUnit.value : "",
                    programId: programId
                }

                try {
                    let dataResponse = null;
                    if (candidateID === null) {
                        dataResponse = await createCandidateCall({}, requestData);
                    }
                    else {
                        dataResponse = await updateCandidateCall({}, requestData, candidateID);
                    }
                    if (dataResponse && dataResponse.statusCode === 200) {

                        if (candidateID === null) {
                            ShowToast('Please wait while Candidate created', TYPE_INFO, INTERVAL, "");
                            ShowToast('Candidate created Successfully', TYPE_SUCCESS, INTERVAL, props, "/candidate-page");
                        }
                        else {
                            ShowToast('Please wait while candidate updated', TYPE_INFO, INTERVAL, "");
                            ShowToast('Candidate updated Successfully', TYPE_SUCCESS, INTERVAL, props, "/candidate-page");
                        }
                    }
                    else {
                        ShowToast(`Error Occurred while ${candidateID ? "Updating" : "Creating"} candidate.`, TYPE_INFO, INTERVAL, "");
                    }
                }
                catch (error) {
                    console.log("Error Occured while creating/updating candidate", error);
                }
            }
        }
    }

    const cancelHandler = () => {
        history.push("./candidate-page");
    }

    const isFormValid = () => {
        let isValid = true;
        let error = {};

        if (!firstName) {
            isValid = false;
            error.firstNameError = "Please enter first name";
        }

        if (!dobDate) {
            isValid = false;
            error.dobDateError = "Please select date of birth";
        }

        if (!ageNumber) {
            isValid = false;
            error.ageNumberError = "Please enter age";
        }
        else{
            if(ageNumber.length > 3){
                isValid = false;
                error.ageNumberError ="Please enter valid age";
            }
        }

        if (!gender) {
            isValid = false;
            error.genderError = "Please select gender";
        }

        if (email) {
            if (!emailPattern.test(email)) {
                isValid = false;
                error.emailIdError = "Please enter a valid email address.";
            }
        } else {
            isValid = false;
            error.emailIdError = "Please enter an email address";
        }

        if (mobileNumber) {
            if (!PHONE_NUMBER_PATTERN.test(mobileNumber)) {
                isValid = false;
                error.MobileNoError = "Please enter a valid mobile number.";
            }
        } else {
            isValid = false;
            error.MobileNoError = "Please enter a mobile number";
        }

        if (!qualificationsName) {
            isValid = false;
            error.qualificationsError = "Please select qualification";
        }

        if (!state) {
            isValid = false;
            error.stateError = "Please enter state";
        }

        if (!city) {
            isValid = false;
            error.cityError = "Please enter city";
        }
        
        if(!adharNo){
            isValid = false;
            error.aadharNumberError = "Please enter Aadhaar number";
        }

        setError(error);
        return isValid;
    };

    const handleGenderChange = (selected) => {
        setGenderSelectedOption(selected);
        setGender(selected.value);
    };

    const handleDepartmentChange = (event) =>{
        setDepartmentSelected(event);
    }

    const handleEmployerUnitChange = (event) => {
        setEmployerUnit(event)
    }

    const handleStateChange = (event) => {
        setState(event);
        changeCityComponent(event);
        setCity(null);
    }

    const changeCityComponent = (event) => {
        let list = metaData[0].meta_data.cities.filter((item) => item.state_id === event.value);
        list  =  list.map((item) => {
            return ({label: item.city_name, value: item.city_id});
        })
        setCityComponent(list);
    }

    const handleCityChange  = (event) =>{ 
        setCity(event)
    }

    const handlePartnerChange = (event) => {
        setTrainingPartner(event);
    }

    const handleQualificationsChange = (event) => {
        setQualificationsName(event)
    };

    const handleDateChange = (date) => {
        setdobDate(date);
        // const formattedDate = date.toLocaleDateString('en-IN', {
        //     day: '2-digit',
        //     month: '2-digit',
        //     year: 'numeric',
        // });
        // console.log("date:", date);
        // console.log("Selected Date:", formattedDate);
        // setdobDate(formattedDate);
      };

    return (
        <>
            <div className="main-content">
                <div className="back">
                    <ul className="breadcrumb1">

                    </ul>
                </div>
                <div className="add-meter-main-view">
                    <div className="mx-auto text-left border create-meter-div">
                        <form className="pt-1">
                            <div className="meter-icon-wrapper">
                                <h4>{candidateID === null ? "Add New Candidate" : "Edit Candidate"}</h4>
                                <i className={`${USERS_ICON} list-table-icon`}></i>
                            </div>

                            <div className="row">
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">First Name <span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} placeholder="Enter First Name" />
                                    <div className="text-danger error-msg">
                                        {error.firstNameError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Last Name </label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={lastName} onChange={(e) => { setLastName(e.target.value) }} placeholder="Enter Last Name" />
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Father Name </label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={fatherName} onChange={(e) => { setFatherName(e.target.value) }} placeholder="Enter Father Name" />
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">
                                        Date of Birth <span className="text-danger">* </span>
                                    </label>
                                    <DatePicker
                                        className="form-control brand-font p-3 placeholderColor add-meter-input"
                                        selected={dobDate}
                                        onChange={handleDateChange}
                                        placeholderText="Select Date of Birth"
                                    />
                                    <div className="text-danger error-msg">{error.dobDateError}</div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Age<span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ageNumber} onChange={ageHandler} placeholder="Enter Age" />
                                    <div className="text-danger error-msg">
                                        {error.ageNumberError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Mobile<span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={mobileNumber} onChange={mobileNumberHandler} placeholder="Enter Mobile Number" />
                                    <div className="text-danger error-msg">
                                        {error.MobileNoError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Aadhar Number<span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={adharNo} onChange={adharChangeHandler} placeholder="Enter Aadhar No." />
                                    <div className="text-danger error-msg">
                                        {error.aadharNumberError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6 email-input">
                                    <label className="form-label">Email <span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter Email" />
                                    <div className="text-danger error-msg">
                                        {error.emailIdError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Gender <span className="text-danger">*</span></label>
                                    <Select
                                        name="gender"
                                        placeholder={"Select Gender"}
                                        value={selectedGenderOption}
                                        options={genderOptions}
                                        onChange={handleGenderChange}
                                    />
                                    <div className="text-danger error-msg">
                                        {error.genderError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Qualification <span className="text-danger">*</span></label>
                                    <Select
                                        name="qualification"
                                        placeholder={"Select Qualification"}
                                        value={qualificationsName}
                                        options={qualificationsListComponent}
                                        onChange={handleQualificationsChange}
                                    />
                                    <div className="text-danger error-msg">
                                        {error.qualificationsError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Select Department</label>
                                    <Select
                                        name="department"
                                        placeholder={"Select Department"}
                                        value={departmentSelected}
                                        options={departmentListComponent}
                                        onChange={handleDepartmentChange}
                                    />
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Select Employer Unit</label>
                                    <Select
                                        name="employerUnit"
                                        placeholder={"Select Employer Unit"}
                                        value={employerUnit}
                                        options={employerListComponent}
                                        onChange={handleEmployerUnitChange}
                                    />
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Select Training Partner</label>
                                    <Select
                                        name="trainingPartner"
                                        placeholder={"Select Training Partner"}
                                        value={trainingPartner}
                                        options={partnerListComponent}
                                        onChange={handlePartnerChange}
                                    />
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Select State<span className="text-danger">*</span></label>
                                    <Select
                                        name="state"
                                        placeholder={"Select State"}
                                        value={state}
                                        options={stateComponent}
                                        onChange={handleStateChange}
                                    />
                                    <div className="text-danger error-msg">
                                        {error.stateError}
                                    </div>
                                </div>
                                <div className="input-label-group col-md-6">
                                    <label className="form-label">Select City<span className="text-danger">*</span></label>
                                    <Select
                                        name="city"
                                        placeholder={"Select City"}
                                        value={city}
                                        options={cityComponent}
                                        onChange={handleCityChange}
                                    />
                                    <div className="text-danger error-msg">
                                        {error.stateError}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 mb-5">
                                <div className="form-button-div">
                                    <button className="brand-button" onClick={createCandidateHandler}>Submit</button>
                                    <button className="cancel_button ml-5" onClick={cancelHandler}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateCandidate;