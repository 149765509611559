import React from 'react';
const Spinner = () => {
    return (
        <div className="spinner-wrapper">
            <div className="text-center pt-2">
                <label>
                    Loading...
                </label>
            </div>
        </div>
    );
};

export default Spinner;