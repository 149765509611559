import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");
const CompareDataModal = (props) => {
    const [isModalOpen, setModalOpen] = useState(true);

    const headers = Object.keys(props.changedFields[0]); // Get column headers
    const headerMapping = {
        first_name: "First Name",
        last_name: "Last Name",
        fathers_name: "Fathers Name",
        date_of_birth: "DOB",
        age: "Age",
        mobile_no: "Contact no",
        adhaar_no: "Aadhar No",
        email: "Email ID",
        gender: "Gender",
        qualification: "Qualification",
        state: "Candidate State",
        city: "Candidate City",
        department_selected: "Department Selected",
        training_partner: "Training Partner",
        employer_unit: "Employer Unit"
    }

    const closeModal = () => {
        setModalOpen(false);
        props.closeModel();
    }

    const saveCandidateData = () => {
        props.saveCandidateData();
    }

    const formattedDate = (dateShow) => {
        const date = new Date(dateShow);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
    }

    return (
        <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="compare-candidate-modal" overlayClassName="program-modal-overlay">
            <div role="document">
                <div className="candidate-model-scroll">
                    <h4 className="import-candidate-heading">Candidate Details</h4>
                    <table>
                        <thead>
                            <tr className="compare-modal-main-row-header">
                                <th>S.No</th>
                                {headers.map((header, index) => (
                                    <th key={index}>{headerMapping[header]}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.changedFields.map((rowData, rowIndex) => (
                                    <tr key={`${rowIndex}-${Math.floor(Math.random() * 100) + 1}`}>
                                        <td>{rowIndex + 1}</td>
                                        {headers.map((header, headerIndex) => (
                                            <React.Fragment key={headerIndex}>
                                                {rowData[header] && rowData[header].updateValue !== undefined ? (
                                                    <td key={`${header}-${headerIndex}-update`}>
                                                        <span className="previous-value"><del>{header === "date_of_birth" ? formattedDate(rowData[header].previousValue) : rowData[header].previousValue}</del></span>{" "}
                                                        <span className="update-value">{header === "date_of_birth" ? formattedDate(rowData[header].updateValue) : rowData[header].updateValue}</span>
                                                    </td>
                                                ) : (rowData[header] && typeof rowData[header] === "object" && rowData[header].noValueChanged ? (
                                                    <td key={`${header}-${headerIndex}-nochange`}>
                                                        <span className="no-value">{header === "date_of_birth" ? formattedDate(rowData[header].noValueChanged) : rowData[header].noValueChanged.toString()}</span>
                                                    </td>
                                                ) : (
                                                    rowData[header] && typeof rowData[header] === 'object' ?
                                                        (
                                                            <td key={`${header}-${headerIndex}-nochange`}>
                                                                <span className="no-value">{header === "date_of_birth" ? formattedDate(rowData[header].noValueChanged) : rowData[header].noValueChanged.toString()}</span>
                                                            </td>
                                                        ) : (
                                                            <td key={`${header}-${headerIndex}-newrow`} className="new-row-value">
                                                                <span>{rowData[header] && (header === "date_of_birth" ? formattedDate(rowData[header]) : rowData[header].toString())}</span>
                                                            </td>
                                                        )
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="form-button-div mt-5">
                    <button className="brand-button" onClick={saveCandidateData}>Confirm</button>
                    <button className="button-secondary ml-3" onClick={closeModal}>Cancel</button>
                </div>
            </div>
        </Modal>
    );
}

export default CompareDataModal;