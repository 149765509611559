import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { EDIT_ICON, TRASH_ICON, CALENDER_ICON,PROGRAM_ICON, USERS_ICON } from "assets/constants/Icons";
import ShowToast from 'components/ShowToast';
import moment from 'moment';
import Spinner from 'components/Spinner';
import "./program.css";
import ExcelExportComponent from "components/ExcelExportComponent";
import { hasPermission } from 'components/Permission';
import { fetchCandidateByCandidateIdArray, deleteProgramCall, getProgramListByEmployer, getProgramList, getProgramListByPartner } from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { fetchMetadata } from 'lib/utils';

const ProgramListPage = () => {
  const history = useHistory();
  const [programList, setProgramList] = useState([]);
  const [showStep, setShowStep] = useState([]);
  const [showCandidate, setShowCandidate] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [candidateLoading, setCandidateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [showUnit, setShowUnit] = useState([]);
  const [showTrainingPartner, setShowTrainingPartner] = useState([]);
  const [showLocation, setShowLocation] = useState([]);
  const userDetail = JSON.parse(localStorage.getItem("userData"));
  const mytheme =  createTheme({});
  const [tableActionsArray,setTableActionsArray] =useState([]);
  const [metaData, setMetaData] = useState(JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : []);

  useEffect(() => {
    fetchProgramData();
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    async function fetchMetaDataResult() {
      if (metaData && metaData.length === 0) {
        const metaDataResult = await fetchMetadata();
        setMetaData(metaDataResult);
      }
    }
    fetchMetaDataResult();
  }, [metaData]);

  const fetchProgramData = async () => {
    try {
      // Change/apply condition for Partner roles also
      let response= null;
      if (userDetail && "employer_id" in userDetail && userDetail.employer_id) {
        response = await getProgramListByEmployer({},{},userDetail.employer_id);
      }
      else if(userDetail && "partner_id" in userDetail && userDetail.partner_id){
        response = await getProgramListByPartner({},{},userDetail.partner_id);
      } 
      else {
        response = await getProgramList({},{});
      }
      
      const data = response.data.result;
      let showSteps = [];
      let showCandidates = [];
      let showUnit = [];
      let showTrainingPartner = [];
      let showLocation = [];
      data.forEach((item) => {
        if (item.workflow_steps) {
          item.noOfSteps = item.workflow_steps.length;
        }
        if (item.candidate_id) {
          item.noOfCandidate = item.candidate_id.length;
        }
        if (item.company_id) {
          const companyName = metaData[0].meta_data.companies.find((findItem) => findItem.company_id === item.company_id);
          if (companyName) {
            item.employerName = companyName.company_name;
          }
        }
        if (item.project_type) {
          const projectType = metaData[0].meta_data.project_type.find((findItem) => findItem.toLowerCase() === item.project_type.toLowerCase());
          if (projectType) {
            item.projectType = projectType;
          }
        }
        showCandidates.push(false);
        showSteps.push(false);
        showUnit.push(false);
        showTrainingPartner.push(false);
        showLocation.push(false);
      });
      setProgramList(data);
      setShowStep(showSteps);
      setShowCandidate(showCandidates);
      setShowUnit(showUnit);
      setShowTrainingPartner(showTrainingPartner);
      setShowLocation(showLocation);
      setIsLoading(false);
      setLoadingComplete(true);
    } catch (error) {
      setIsLoading(false);
      setLoadingComplete(true);
      console.log("Error while fetching Program Data", error);
    }
  }

  const fetchCandidateList = async (candidateId) => {
    setCandidateLoading(true);
    ShowToast('Loading Please Wait', TYPE_SUCCESS, 400, "", "");
    const candidateParams = {
      candidateId
    }
    try {
      const responseResult = await fetchCandidateByCandidateIdArray({},candidateParams)
      let candidateList = responseResult.data.result;
      let tempCandidateList = [];
      candidateList.forEach((item) => {
        if (item.first_name && item.last_name) {
          tempCandidateList.push(`${item.first_name} ${item.last_name}`)
        }
      });
      setCandidateLoading(false);
      return tempCandidateList;
    }
    catch (error) {
      setCandidateLoading(false);
      console.log("Error Occurred While fetching Candidate ", error);
      return [];
    }
  }

  const createProgram = () => {
    window.location.href = "/create-program-page";
  }

  const editProgram = (data) => {
    history.push("/create-program-page", data);
  }

  const handleCandidateManagement = (data) => {
    history.push("/project-candidate-managment",data);
  }

  const handleDelete = async (item) => {
    if (window.confirm("Are you sure you want to delete this Project?")) {
      setIsLoading(true);
      try {
        await deleteProgramCall({},{},item);
        ShowToast('Project deleted successfully', TYPE_SUCCESS, INTERVAL, "", "");
        await fetchProgramData();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        ShowToast('Something went wrong. Please delete again after some time!', TYPE_FAIL, INTERVAL, "", "");
      }
    }
  }

  useEffect(() =>{
    const tableActions = () => {
      let tableActions = [];
      tableActions.push({
        icon: () => <i className={`${USERS_ICON}`} title="Candidate Management"></i>,
        onClick: (event, rowData) => handleCandidateManagement(rowData)
      })
      if (hasPermission("project", "update")) {
        tableActions.push({
          icon: () => <i className={`${EDIT_ICON}`} title="Edit program"></i>,
          onClick: (event, rowData) => editProgram(rowData)
        })
      }
      tableActions.push({
        icon: () => <i className={`${TRASH_ICON}`} title="Delete program"></i>,
        onClick: (event, rowData) => handleDelete(rowData.programId)
      });
      setTableActionsArray(tableActions);
    }
    tableActions();
    // eslint-disable-next-line
  },[])

  const tableOptions = {
    actionsColumnIndex: -1,
    headerStyle: {
      color: '#999998',
      fontWeight: 'bolder',
      fontSize: 15
    },
    sorting: true,
    columnsButton: true,
    exportButton: true,
    exportFileName: "Project List"
  };

  return (<>
    {
      isLoading === true ? (
        <Spinner />
      ) : (
        <div className="main-content">
          <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
            <div className="col-md-10 sm_device"><i className={`${PROGRAM_ICON}`}></i> Project List</div>
            <div className="col-md-2 sm_device" style={{ textAlign: 'right' }}><i className={`${CALENDER_ICON}`}></i> {moment().format("DD MMM YYYY")}</div>
          </div>
          <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
          <div className="list-wrapper">
              {
                hasPermission("project", "create") && (
                  <div className="form-button-div">
                    <button className="brand-button" onClick={createProgram}>Create</button>
                  </div>
                )
              }
            <div>
              <div className="customer-button-div"></div>
              <div className="mt-4 border list-table-div project-list-table-div" style={{ marginBottom: 50 }}>
              <ThemeProvider theme={mytheme}>
                <MaterialTable
                  columns={[
                    { title: "Code", field: "programCode" },
                    {
                      title: "Project Name",
                      field: "programName",
                      render: (rowData) => (
                        <a href={`program-tracking/${rowData.programId}`} className="program-name-style">
                          {rowData.programName}
                        </a>
                      ),
                    },
                    { title: "Project Type", field: "projectType" },
                    { title: "Partner", field: "partnerName" },
                    { title: "Employer", field: "employerName" },
                    {
                      title: "Employer Unit", field: "unitName",
                      render: (row, index) => (
                        <div className="cursor-pointer" onClick={() => {
                          let unitShow = [...showUnit];
                          unitShow = unitShow.map((item) => {
                            return false;
                          })
                          if (showUnit[row.tableData.id] === true) {
                            unitShow[row.tableData.id] = false;
                          }
                          else {
                            unitShow[row.tableData.id] = true;
                          }
                          setShowUnit(unitShow);
                        }}>{
                          showUnit[row.tableData.id] === true && row.employerItem ? (<>
                              {
                                row.employerItem.map((item,index) => {
                                  return (<p key={`unit-${index}`}>{index + 1}) {item.unit_name}</p>)
                                })
                              }
                            </>) : (<>
                              <span className="steps-label-class"><b>{row.employerItem ? row.employerItem.length : 0}</b></span>
                            </>)
                          }
                        </div>
                      )
                    },
                    {
                      title: "Location", field: "location",
                      render: (row, index) => (
                        <div className="cursor-pointer" onClick={() => {
                          let locationShow = [...showLocation];
                          locationShow = locationShow.map((item) => {
                            return false;
                          })
                          if (showLocation[row.tableData.id] === true) {
                            locationShow[row.tableData.id] = false;
                          }
                          else {
                            locationShow[row.tableData.id] = true;
                          }
                          setShowLocation(locationShow);
                        }}>{
                          showLocation[row.tableData.id] === true && row.employerItem ? (<>
                              {
                                row.employerItem.map((item,index) => {
                                  return (<p key={`location-${index}`}>{index + 1}) {item.employer_location}</p>)
                                })
                              }
                            </>) : (<>
                              <span className="steps-label-class"><b>{row.employerItem ? row.employerItem.length : 0}</b></span>
                            </>)
                          }
                        </div>
                      )
                    },
                    {
                      title: "Training Partner", field: "trainingPartner",
                      render: (row, index) => (
                        <div className="cursor-pointer" onClick={() => {
                          let showPartner = [...showTrainingPartner];
                          showPartner = showPartner.map((item) => {
                            return false;
                          })
                          if (showTrainingPartner[row.tableData.id] === true) {
                            showPartner[row.tableData.id] = false;
                          }
                          else {
                            showPartner[row.tableData.id] = true;
                          }
                          setShowTrainingPartner(showPartner);
                        }}>{
                          showTrainingPartner[row.tableData.id] === true ? (<>
                              {
                                row.partnerItem.map((item,index) => {
                                  return (<p key={`training-partner-${index}`}>{index + 1}) {item.training_partner}</p>)
                                })
                              }
                            </>) : (<>
                              <span className="steps-label-class"><b>{row.partnerItem ? row.partnerItem.length : " "}</b></span>
                            </>)
                          }
                        </div>
                      )
                    },
                    { title: "Start Date", field: "disaplayStartDateOfProgram" },
                    { title: "Status", field: "programStatus" },
                    { title: "Supporting Notes", field: "supportingNotes" },
                    { title: "No of Required Candidate", field: "requiredNoOfCandidate" },
                    {
                      title: "No of Steps", field: "noOfSteps",
                      render: (row, index) => (
                        <div className="cursor-pointer" onClick={() => {
                          let showSteps = [...showStep];
                          showSteps = showSteps.map((item) => {
                            return false;
                          })
                          if (showStep[row.tableData.id] === true) {
                            showSteps[row.tableData.id] = false;
                          }
                          else {
                            showSteps[row.tableData.id] = true;
                          }
                          setShowStep(showSteps);
                        }}>{
                            showStep[row.tableData.id] === true ? (<>
                              {
                                row.workFlowSteps.sort((a, b) => a.step_no - b.step_no) &&
                                row.workFlowSteps.map((item) => {
                                  return (<p key={`step-${item.step_no}`}>{item.step_no}) {item.stepTitle}</p>);
                                })
                              }
                            </>) : (<>
                              <span className="steps-label-class"><b>{row.noOfSteps}</b></span>
                            </>)
                          }
                        </div>
                      )
                    },
                    {
                      title: "No of Candidate", field: "noOfCandidate",
                      render: (row, index) => (
                        <div className="cursor-pointer" onClick={async () => {
                          setCandidateLoading(true);
                          let showCandidates = [...showCandidate];
                          showCandidates = showCandidates.map((item) => {
                            return false;
                          })
                          if (showCandidate[row.tableData.id] === true) {
                            showCandidates[row.tableData.id] = false;
                          }
                          else {
                            showCandidates[row.tableData.id] = true;
                          }
                          let result = await fetchCandidateList(row.candidateId);
                          setCandidateLoading(false);
                          setShowCandidate(showCandidates);
                          setCandidateList(result);
                        }}>{
                            showCandidate[row.tableData.id] === true ? (<>
                              {
                                candidateList.length > 0 ? (
                                  candidateList.map((item, index) => {
                                    return (<p key={`candidate-${index}`}>{index + 1}) {item}</p>)
                                  })
                                ) : (<>
                                  {
                                    candidateLoading === false && (
                                      <span className="steps-label-class"><b>{row.noOfCandidate}</b></span>
                                    )
                                  }
                                </>)
                              }
                            </>) : (<>
                              <span className="steps-label-class"><b>{row.noOfCandidate}</b></span>
                            </>)
                          }
                        </div>
                      )
                    }
                  ]}
                  data={programList.map(item => ({
                    programName: item.program_name,
                    noOfSteps: item.noOfSteps,
                    employerId: item.employer_id,
                    partnerId: item.partner_id,
                    noOfCandidate: item.noOfCandidate,
                    requiredNoOfCandidate: item.no_of_candidate,
                    programId: item._id,
                    candidateId: item.candidate_id,
                    employerName: item.employerName ? item.employerName : "",
                    partnerName: item.partnerItem ? item.partnerItem[0].partner_name : "TIMES-PRO",
                    workFlowSteps: item.workflow_steps,
                    programCode: item.program_code,
                    programStatus: item.program_status,
                    disaplayStartDateOfProgram: new Date(item.start_date_of_program).toLocaleDateString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    }),
                    startDateOfProgram: item.start_date_of_program,
                    unitName: item.employerItem ? item.employerItem.length : 0,
                    location: item.employerItem ? item.employerItem.length : 0,
                    trainingPartner: item.partnerItem ? item.partnerItem.length :  null,
                    supportingNotes: item.supporting_notes,
                    canidateDepartment: item.candidate_department,
                    employerItem: item.employerItem,
                    partnerItem: item.partnerItem,
                    candidateSplit: item.candidate_split,
                    departmentList: item.department_list,
                    unitSplitData: item.unit_split_array,
                    partnerSplitData: item.partner_split_array,
                    projectType: item.projectType,
                    csrEmployerUnit: item.csr_employer_unit
                  }))}
                  actions={tableActionsArray}
                  options={tableOptions}
                  components={{
                    Toolbar: (props) => (
                      <div>
                        <MTableToolbar {...props} />
                        {
                          programList.length > 0 && loadingComplete === true && (
                            hasPermission("project", "download_excel") && (
                              <ExcelExportComponent {...props} />
                            )
                          )
                        }
                      </div>
                    ),
                  }}
                />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </>);
}

export default ProgramListPage;