import React, { useState, useContext, useEffect } from 'react'
import AuthContext from 'context/AuthProvider';
import { USER_ICON } from "assets/constants/Icons";

function NavigationBar(props) {
    const [brandLogo, setBrandLogo] = useState("");

    const authContext = useContext(AuthContext);
    const userDetail = JSON.parse(localStorage.getItem("userData"));

    useEffect(() => {
    }, []);

    useEffect(() => {
        const setBrandLogoFun = () => {
            setBrandLogo(authContext.BRAND_LOGO);
        }

        setBrandLogoFun();
    }, [authContext.BRAND_LOGO])

    const addEditUserHandler = (userDetail) => {
        props.history.push({
            pathname: "/create-user-page",
            state: {...userDetail, isFromProfile: true}
        });
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <nav className="top-navigation-bar">
            <div className="logo-container">
                <a href={userDetail ? "/project-list" : "/login-page"} className="navigation-header-anchor">
                    <img alt="Brand Logo" className="img-no-padding img-responsive" src={brandLogo} />
                </a>
            </div>
            <div className="site-select-dropdown-and-user-info-container">
                {userDetail !== null && (
                    <div className="user-info-container">
                        <label className="capitalize-text">{`Hi, ${capitalizeFirstLetter(userDetail.first_name)} ${capitalizeFirstLetter(userDetail.last_name)}`}</label>
                        <div className="top-navigation-bar-icon-container" onClick={() => addEditUserHandler(userDetail)} title="Edit user information">
                            <i className={`${USER_ICON} top-navigation-bar-icon`} ></i>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default NavigationBar;

