import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar }  from "material-table";
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL, TYPE_INFO } from "assets/constants/Constants";
import { EDIT_ICON, TRASH_ICON, METER_ICON, EXPORT_ICON } from "assets/constants/Icons";
import ShowToast from 'components/ShowToast';
import ExcelExportComponent from "components/ExcelExportComponent";
import { hasPermission } from 'components/Permission';
import Spinner from 'components/Spinner';
import { getEmployerList, deleteEmployer, createBulkEmployer } from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { importEmployer } from "components/Employer/ImportEmployer";
import { validateEmployerData } from "lib/utils";
import ImportErrorPopup from "components/ImportErrorPopup";

const Dashboard = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const mytheme =  createTheme({});
    const fileInputRef = useRef(null);
    const [importLoading, setImportLoading] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [employerImportData, setEmployerImportData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await getEmployerList({},{});
            const { result } = response.data;
            setData(result || []);
            setLoadingComplete(true);
        } catch (error) {
            console.log("Error while fetching data", error);
            setLoadingComplete(true);
        }
    };

    const addEmployer = () => {
        history.push('/add-employer-page', { employerId: null, employerList: data });
    };

    const editEmployer = (employerData) => {
        const propsData = {
            ...employerData,
            employerList: data
        }
        history.push('/add-employer-page', propsData);
    };

    const handleEmployerDashboard = (employerId) => {
        history.push("/employer-dashboard-page",employerId);
    }

    const handleDelete = async (employerId) => {
        if (window.confirm("Are you sure you want to delete this Employer?")) {
            try {
                const deleteResponse = await deleteEmployer({},{},employerId)
                if (deleteResponse.statusCode === 200) {
                    await fetchData();
                    ShowToast('Employer deleted successfully', TYPE_SUCCESS, INTERVAL, "", "");
                } else {
                    ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, "", "");
                }
            } catch (error) {
                console.log("Error while deleting employer", error);
            }
        }
    };

    const columns = [
        {
            title: "Company Name", field: "employerName",
            cellStyle: { cellWidth: '14% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14 }
        },
        {
            title: "Unit Name", field: "unitName",
            cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 },
            sorting: false
        },
        { title: "City", field: "city", cellStyle: { width: '5%', color: '#808080', fontSize: 14 } },
        { title: "State", field: "state", cellStyle: { width: '5%', color: '#808080', fontSize: 14 } },
        {
            title: "Unit Representative Name", field: "employerRepresentativeName",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit Representative Email ", field: "employerRepresentativeEmail",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit Representative Number ", field: "employerRepresentativeMobile",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        { title: "Unit Status", field: "unitStatus", cellStyle: { width: '5%', color: '#808080', fontSize: 14 } },
        {
            title: "Address", field: "employerLocation",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "PIN Code", field: "pinCode",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit Website Link", field: "companyWebsiteUrl",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit Social Media", field: "socialMediaUrl",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "PAN Card Number", field: "panCardNumber",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "CIN Number", field: "cinNumber",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Billing Entity Name", field: "billingEntityName",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit Acccount Number", field: "unitAccountNumber",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit GSTIN Number", field: "gstinNumber",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit Bank Name", field: "unitBankName",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
        {
            title: "Unit Bank Address", field: "unitBankAddress",
            cellStyle: { cellWidth: '9%', color: '#808080', fontSize: 14 }
        },
    ];

    const importEmployerExcel = async () => {
        fileInputRef.current.click();
    }

    const handleEmployerExcelUpload = async (event) => {
        setImportLoading(true);
        try {
            if (event && event.target && event.target.files[0]) {
                const requestData = await importEmployer(event, data);
                if (requestData && requestData.length > 0) {
                    const validateFlag = validateEmployerData(requestData);
                    try {
                        if (validateFlag) {
                            const importData = await createBulkEmployer({}, requestData);
                            if (importData.data && importData.data.result && importData.data.result.length > 0) {
                                if (importData.data.message === "ExistedEmployer") {
                                    const existedUnits = importData.data.result.toString()
                                    ShowToast(`${existedUnits} already exist`, TYPE_INFO, INTERVAL, "", "");
                                    setImportLoading(false);
                                } else {
                                    await fetchData();
                                    ShowToast(`${importData.data.result.length} Employers imported successfully`, TYPE_SUCCESS, INTERVAL, "", "");
                                }
                            } else {
                                ShowToast("Something went wrong. Please try again!", TYPE_FAIL, INTERVAL, "", "");
                            }
                        }
                        else {
                            setIsModelOpen(true);
                            setEmployerImportData(requestData);
                        }

                        setImportLoading(false);
                    }
                    catch (error) {
                        console.log("Error Occurred while importing employer", error);
                        setImportLoading(false);
                    }
                }
                else {
                    ShowToast("No employer in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
                    setImportLoading(false);
                }
            }
            else {
                ShowToast("Please select employer excel for Importing", TYPE_INFO, INTERVAL, "", "");
                setImportLoading(false);
            }
        }
        catch (error) {
            console.log("Error Occurred while importing", error);
            setImportLoading(false);
        }
    }

    const cancelHandle = () => {
        if (isModelOpen === true) {
            setIsModelOpen(false);
            window.location.reload();
        }
    }

    return (<>
        {
            loadingComplete === false ? (
                <Spinner />
            ) : (
                <div className="main-content">
                    <div className="list-wrapper">
                        {
                            hasPermission("employer", "create") && (
                                <div className="form-button-div">
                                    <button className="brand-button width-auto" onClick={addEmployer}>Add Employer</button>
                                        <input
                                            type="file"
                                            accept=".xlsx"
                                            onChange={handleEmployerExcelUpload}
                                            onClick={(e) => { e.target.files[0] && handleEmployerExcelUpload(e) }}
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                        />
                                        {
                                            importLoading === true ? (
                                                <div className="ml-5">
                                                    <div className="spinner-loader">
                                                    </div>
                                                </div>
                                            ) : (
                                                <button className="button-secondary width-auto ml-5" onClick={importEmployerExcel}>Import Employer&nbsp;&nbsp;&nbsp;<i className={EXPORT_ICON} /></button>
                                            )
                                        }
                                </div>
                            )
                        }
                        <div>
                            <div className="mt-4 border list-table-div candidate-material-table-div program-candidate-material-table-div" style={{ marginBottom: 50 }}>
                                {data && (
                                        <ThemeProvider theme={mytheme}>
                                            <MaterialTable
                                                columns={columns}
                                                data={data.map((item) => ({
                                                    employerName: item.employer_name,
                                                    // brandName: item.brand_name,
                                                    unitName: item.unit_name,
                                                    // resprentativeName: item.representative_name,
                                                    employerLocation: item.employer_location,
                                                    unitStatus: item.unit_status,
                                                    employerRepresentativeName: item.representative_name,
                                                    employerRepresentativeEmail: item.representative_email_id,
                                                    employerRepresentativeMobile: item.representative_phone_no,
                                                    state: item.state,
                                                    city: item.city,
                                                    companyWebsiteUrl: item.company_Website_Url,
                                                    brandName: item.brand_Name,
                                                    pinCode: item.pin_code,
                                                    socialMediaUrl: item.social_media_url,
                                                    panCardNumber: item.pan_card_number,
                                                    billingEntityName: item.billing_entity_name,
                                                    cinNumber: item.cin_number,
                                                    gstinNumber: item.gstin_number,
                                                    unitBankName: item.unit_bank_name,
                                                    unitBankAddress: item.unit_bank_address,
                                                    unitAccountNumber: item.unit_account_number,
                                                    employerId: item._id,
                                                }))}
                                                actions={[
                                                    hasPermission("employer", "view_dashboard") && (
                                                        {
                                                            icon: () => <i className={`${METER_ICON}`} title="Employer Dashboard"></i>,
                                                            onClick: (event, rowData) => handleEmployerDashboard(rowData.employerId),
                                                        }),
                                                    hasPermission("employer", "update") && (
                                                        {
                                                            icon: () => <i className={`${EDIT_ICON}`} title="Edit customer"></i>,
                                                            onClick: (event, rowData) => editEmployer(rowData),
                                                        }
                                                    ),
                                                    {
                                                        icon: () => <i className={`${TRASH_ICON}`} title="Delete Employer"></i>,
                                                        onClick: (event, rowData) => handleDelete(rowData.employerId),
                                                    },
                                                ]}
                                                options={{
                                                    actionsColumnIndex: 9,
                                                    headerStyle: { color: '#999998', fontWeight: 'bolder', fontSize: 15 },
                                                    sorting: true,
                                                    columnsButton: true,
                                                    exportButton: true,
                                                    exportFileName: "Employer List",
                                                }}
                                                components={{
                                                    Toolbar: (props) => (
                                                        <div>
                                                            <MTableToolbar {...props} />
                                                            {
                                                                data.length > 0 && loadingComplete === true && (
                                                                    hasPermission("employer", "download_excel") && (
                                                                        <ExcelExportComponent {...props} employerModule={true} />
                                                                    )
                                                                )
                                                            }
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        <ImportErrorPopup modelOpen={isModelOpen} closeModel={cancelHandle} employerImportData={employerImportData} />
    </>);
};

export default Dashboard;