import React from 'react';

const FileDownloadWidget = (props) => {
  const { value, label } = props;

  return (
    <div>
      {value && (
        <div>
          <a href={value} className="underline-link" download={label.replace("Upload", "Download")}>{label.replace("Upload", "Download")}</a>
        </div>
      )}
    </div>
  );
};

export default FileDownloadWidget;