import React, { useState, useEffect } from "react";
import { TRAININGPART } from "../../assets/constants/Icons";
import { TYPE_SUCCESS, TYPE_INFO, INTERVAL, PHONE_NUMBER_PATTERN, emailPattern, PAN_NUMBER_PATTERN, CIN_NUMBER_PATTERN, GSTIN_NUMBER_PATTERN, TYPE_FAIL } from "assets/constants/Constants";
import ShowToast from "components/ShowToast";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { fetchMetadata} from 'lib/utils';
import { createPartnerCall, updatePartnerCall } from "lib/axiosUtils";

const CreatePartner = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { partnerId, representativeName: initialrepresentativeName = "", representativeEmail: initialrepresentativeEmail = "", representativeMobile: initialsetRepresentativePhone = "", trainingPartner: initialtrainingPartner = "", addressLineOne: initialAddressLineOne = "", addressLineTwo: initialAddressLineTwo = "", pinCode: initialPinCode = "", partnerBankName: initialPartnerBankName = "", partnerBankAddress: initialPartnerBankAddress, partnerList } = location.state || {};
  const [partnerName, setPartnerName] = useState(location.state ? (location.state.partnerName ? location.state.partnerName : "") : "");
  const [selectedPartnerNameOption, setPartnerNameSelectedOption] = useState(location.state ? (location.state.partnerName ? { value: location.state.partnerName, label: location.state.partnerName } : "") : "");
  const [trainingPartner, setTrainingPartner] = useState(initialtrainingPartner);
  const [representativeName, setRepresentativeName] = useState(initialrepresentativeName);
  const [representativeEmail, setRepresentativeEmail] = useState(initialrepresentativeEmail);
  const [representativeMobile, setRepresentativePhone] = useState(initialsetRepresentativePhone);
  const [addressLineOne, setAddressLineOne]=useState(initialAddressLineOne);
  const [addressLineTwo, setAddressLineTwo]=useState(initialAddressLineTwo);
  const [pinCode, setPinCode] =useState(initialPinCode);
  const [state, setState] = useState(props.location.state ? (props.location.state.state ? props.location.state.state : null) : null);
  const [selectedStateOption, setStateSelectedOption] = useState(props.location.state ? (props.location.state.state ? { value: props.location.state.state_id, label: props.location.state.state } : "") : "");
  const [selectedCityOption ,setCitySelectedOption]=useState(props.location.state ? { label: `${props.location.state.city_name}`, value: props.location.state.city_id } : null);
  const [city , setCity]= useState(location.state ? (location.state.city ? location.state.city : ""): "");
  const[disabled, setDisabled] =useState(props.location.state ? props.location.state.state ? false :true : true);
  const [error, setError] = useState({});
  const [metaData, setMetaData] = useState(JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : []);
  const[states,setStates] = useState(metaData.length > 0 ? metaData[0].meta_data.states: []);
  const[cities,setCities] = useState(metaData.length > 0 ? metaData[0].meta_data.cities: []);
  const [panCardNumber, setPanCardNumber] = useState(props.location.state ? props.location.state.panCardNumber ? props.location.state.panCardNumber :"" : "");
  const [cinNumber, setCinNumber] = useState(props.location.state ? props.location.state.cinNumber ? props.location.state.cinNumber :"" : "");
  const [gstinNumber, setGstinNumber] = useState(props.location.state ? props.location.state.gstinNumber ? props.location.state.gstinNumber :"" : "");
  const [invoiceName, setInvoiceName] =useState(props.location.state ? props.location.state.invoiceName ? props.location.state.invoiceName :"" : "")
  const [partnerBankName, setPartnerBankName] = useState(initialPartnerBankName);
  const [partnerBankAddress, setPartnerBankAddress] = useState(initialPartnerBankAddress);
  const [partnerAccountNumber, setPartnerAccountNumber] = useState(props.location.state ? props.location.state.partnerAccountNumber ? props.location.state.partnerAccountNumber :"" : "");
  const [programNames, setProgramName] = useState(metaData.length > 0 ? metaData[0].meta_data.program : []);

  useEffect(() => {
    const fetchData = async () => {
      if (metaData && metaData.length === 0) {
        const metaResult = await fetchMetadata();
        setMetaData(metaResult)
        setStates(metaResult[0].meta_data.states);
        setCities(metaResult[0].meta_data.cities);
        setProgramName(metaResult[0].meta_data.program);
      }
    }
    fetchData();
  }, [metaData])

  useEffect((state) => {
  const setStatesListComponent = (states) => {
    let tempState = "";
    let stateName = "";
    if(props.location.state){
        stateName = props.location.state.state
      }
      const stateList = states.map((item) => {
        if (item.state_name === stateName) {
          tempState = { value: item.state_id, label: item.state_name };
        }
        return ({ value: item.state_id, label: item.state_name });
      })
      setStateSelectedOption(stateList);
      setState(tempState);
    }
    setStatesListComponent(states, state);
  }, [states, (props.location.state)]);

  const setCityListComponent = (cities) => {
    let tempCity = "";
    let cityName = "";
    if (props.location.state) {
      cityName = props.location.state.city;
    }
    const cityList = cities.map((item) => {
      if (item.city_name === cityName) {
        tempCity = { value: item.city_id, label: item.city_name };
      }
      return ({ value: item.city_id, label: item.city_name });
    })
    setCitySelectedOption(cityList);
    setCity(tempCity)
  }
  useEffect(() => {
    const setCityFromState = () => {
      let cityArray = cities.filter((item) => { return item.state_id === state.value });
      let cityName = "";
      let tempCity = "";
      if (props.location.state) {
        cityName = props.location.state.city;
      }
      let cityComponent = cityArray.map((item) => {
        if (item.city_name === cityName) {
          tempCity = { value: item.city_id, label: item.city_name };
        }
        return ({ label: item.city_name, value: item.city_id })
      })
      setCitySelectedOption(cityComponent);
      setCity(tempCity);
    }
    if (state) {
      setCityFromState();
    }
    else {
    }
  }, [state, cities, (props.location.state)]);

  useEffect((partnerName) => {
    const setProgramListComponent = (programNames) => {
      let tempProgram = "";
      let programsName = "";
      if (location.state.partnerName) {
        programsName = location.state.partnerName;
      }
      const programList = programNames.map((item) => {
        if (item === programsName) {
          tempProgram = { value: item, label: item };
        }
        return ({ value: item, label: item });
      })
      setPartnerNameSelectedOption(programList);
      setPartnerName(tempProgram)
    }
    setProgramListComponent(programNames, partnerName);
  }, [programNames, (location.state.partnerName)]);

  const createPartnerHandler = async (event) => {
    event.preventDefault();
    if (isFormValid()) {
      const requestData = {
        partnerName: partnerName.label,
        trainingPartner:trainingPartner,
        representativeName: representativeName,
        representativePhoneNo: representativeMobile,
        representativeEmailId: representativeEmail,
        state: state.label,
        city: city.label,
        addressLineOne,
        addressLineTwo,
        pinCode,
        panCardNumber,
        invoiceName,
        cinNumber,
        gstinNumber,
        partnerBankName,
        partnerBankAddress,
        partnerAccountNumber
      }

      let dataResponse = null;
      if (partnerId === null) {
        dataResponse = await createPartnerCall({},requestData);
      } else {
        dataResponse = await updatePartnerCall({},requestData,partnerId);
      }

      if (dataResponse.statusCode === 200) {
        if (partnerId === null) {
          if (dataResponse.data.message === "ExistedPartner") {
            const existedPartner = dataResponse.data.result.toString();
            ShowToast(`${existedPartner} already exist`, TYPE_INFO, INTERVAL, "", "");
          } else {
            ShowToast('Please wait while Partner created', TYPE_INFO, INTERVAL, "");
            ShowToast('Partner created Successfully', TYPE_SUCCESS, INTERVAL, props, "/partner-page");
          }
        } else {
          ShowToast('Please wait while Partner updated', TYPE_INFO, INTERVAL, "");
          ShowToast('Partner updated Successfully', TYPE_SUCCESS, INTERVAL, props, "/partner-page");
        }
      }
    }
  }

  const cancelHandler = () => {
    history.push("./partner-page");
  }

  const isFormValid = () => {
    let isValid = true;
    let error = {};

    if (!partnerName) {
      isValid = false;
      error.partnerNameError = "Please select Program name";
    }
    if (!trainingPartner) {
      isValid = false;
      error.trainingPartnerError = "Please enter Training partner";
    }
    if (!representativeName) {
      isValid = false;
      error.representativeNameError = "Please enter representative name";
    }

    if (representativeEmail) {
      if (!emailPattern.test(representativeEmail)) {
        isValid = false;
        error.representativeEmailIdError = "Please enter a valid email address.";
      }
    } else {
      isValid = false;
      error.representativeEmailIdError = "Please enter an email address";
    }

    if (representativeMobile) {
      if (!PHONE_NUMBER_PATTERN.test(representativeMobile)) {
        isValid = false;
        error.representativePhoneError = "Please enter a valid mobile number.";
      }
    } else {
      isValid = false;
      error.representativePhoneError = "Please enter a mobile number";
    }
    if (pinCode) {
      const tempPin = pinCode;
      if (tempPin.toString().length !== 6) {
        isValid = false;
        error.pinCodeError = "Please enter a valid Pin Code number";
      }
    } else {
      isValid = false;
      error.pinCodeError = "Please enter Pin Code number";
    }
    if (panCardNumber !== "") {
      if (!PAN_NUMBER_PATTERN.test(panCardNumber)) {
        isValid = false;
        error.panCardNumberError = "Please enter a valid PAN number";
      }
    }
    if (cinNumber !== "") {
      if (!CIN_NUMBER_PATTERN.test(cinNumber)) {
        isValid = false;
        error.cinError = "Please enter a valid CIN number";
      }
    }
    if (gstinNumber !== "") {
      if (!GSTIN_NUMBER_PATTERN.test(gstinNumber)) {
        isValid = false;
        error.gstinError = "Please enter a valid GSTIN number";
      }
    }

    setError(error);
    return isValid;
  };

  const handleStateChange = (selected) => {
    let selectedCites = metaData[0].meta_data.cities.filter((item) => {
        return selected.value === item.state_id
    });
    setCityListComponent(selectedCites);
    setDisabled(false);
    setState(selected);
};

const handleCityChange = (selected) => {
    setCity(selected);
};

  const handlePartnerNameChange = (selected) => {
    setPartnerName(selected);
  };

const setAccountNumber = (event) => {
  const regularExpression = /^[0-9\b]+$/;
  if (event.target.value === '' || regularExpression.test(event.target.value)) {
      setPartnerAccountNumber(event.target.value);
  }
}

const handlePartnerRepresentativeNumberChange = (event) => {
  if (!isNaN(event.target.value)) {
      setRepresentativePhone(event.target.value) 
  }
}

const handlePinCodeChange = (event) => {
  if (!isNaN(event.target.value) && event.target.value.length < 7) {
      setPinCode(event.target.value);
  }
}

const handlePanNumberChange = (event) => {
  if (event.target.value.length < 11) {
      setPanCardNumber(event.target.value)
  }
}
const handleGstinNumberChange = (event) => {
  if (event.target.value.length < 16) {
      setGstinNumber(event.target.value)
  }
}

const handleCinNumberChange = (event) => {
  if (event.target.value.length < 22) {
      setCinNumber(event.target.value)
  }
}

const isValidTrainingPartner = (event) => {
  const trimValue = event.target.value.trim();
  if (partnerList && partnerList.length > 0) {
    const duplicatePartnerFound = partnerList.find((findItem) => findItem.training_partner.trim() === trimValue);
    if (duplicatePartnerFound) {
      if (partnerId) {
        if (duplicatePartnerFound._id !== partnerId) {
          ShowToast("Same training partner '" + trimValue + "' is already present. Please enter different training partner", TYPE_FAIL, INTERVAL, "", "");
          setTrainingPartner("");
        }
      }
      else {
        ShowToast("Same training partner '" + trimValue + "' is already present. Please enter different training partner", TYPE_FAIL, INTERVAL, "", "");
        setTrainingPartner("");
      }
    }
  }
}

  return (
    <>
      <div className="main-content">
        <div className="back">
          <ul className="breadcrumb1"></ul>
        </div>
        <div className="add-meter-main-view">
          <div className="mx-auto text-left border create-meter-div">
            <form className="pt-1">
              <div className="meter-icon-wrapper">
                <h4>{partnerId === null ? "Add New Partner" : "Edit Partner"}</h4>
                <i className={`${TRAININGPART} list-table-icon`}></i>
              </div>

              <div className="row">
                <div className="input-label-group col-md-6">
                  <label className="form-label">Program Name<span className="text-danger">*</span></label>
                  <CreatableSelect
                    placeholder={`Select Program Name`}
                    name="unitStatus"
                    value={partnerName}
                    onChange={handlePartnerNameChange}
                    options={selectedPartnerNameOption}
                  />
                  <div className="text-danger error-msg">
                    {error.partnerNameError}
                  </div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Training Partner<span className="text-danger">*</span></label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={trainingPartner} onChange={(e) => { setTrainingPartner(e.target.value) }} placeholder="Enter Training Partner " onBlur={(e) => isValidTrainingPartner(e)} />
                  <div className="text-danger error-msg">{error.trainingPartnerError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Representative Name <span className="text-danger">*</span></label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={representativeName} onChange={(e) => { setRepresentativeName(e.target.value) }} placeholder="Enter Name" />
                  <div className="text-danger error-msg">{error.representativeNameError}</div>
                </div>
                <div className="input-label-group col-md-6 email-input">
                  <label className="form-label">Representative Email <span className="text-danger">*</span></label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input email-lowercase" value={representativeEmail} onChange={(e) => { setRepresentativeEmail(e.target.value) }} placeholder="Enter Email" />
                  <div className="text-danger error-msg">{error.representativeEmailIdError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Representative Mobile <span className="text-danger">*</span></label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={representativeMobile} onChange={(e) => { handlePartnerRepresentativeNumberChange(e) }} placeholder="Enter Contact No." />
                  <div className="text-danger error-msg">{error.representativePhoneError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">State </label>
                  <Select
                    placeholder={`Select State`}
                    name="state"
                    value={state}
                    onChange={handleStateChange}
                    options={selectedStateOption}
                  />
                  <div className="text-danger error-msg">
                    {error.state}
                  </div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">City </label>
                  <Select
                    placeholder={`Select City`}
                    name="city"
                    value={city}
                    options={selectedCityOption}
                    onChange={handleCityChange}
                    isDisabled={disabled}
                  />
                  <div className="text-danger error-msg">
                    {error.city}
                  </div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">PIN Code <span className="text-danger">*</span></label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={pinCode} onChange={(e) => { handlePinCodeChange(e) }} placeholder=" Enter PIN Code" />
                  <div className="text-danger error-msg">{error.pinCodeError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Address Line 1</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={addressLineOne} onChange={(e) => { setAddressLineOne(e.target.value) }} placeholder=" Enter Your Address" />
                  <div className="text-danger error-msg">{error.addressLineOne}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Address Line 2</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={addressLineTwo} onChange={(e) => { setAddressLineTwo(e.target.value) }} placeholder=" Enter Your Address" />
                  <div className="text-danger error-msg">{error.addressLineTwo}</div>
                </div>
                <div className="bank-details-div">
                    <label className="bank-detail-label">Please input the below details to ensure a smooth process for The Job Plus and finance through the project </label>
                    <div className="section-div"></div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Billing Entity Name</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={invoiceName} onChange={(e) => { setInvoiceName(e.target.value) }} placeholder=" Enter Billing Entity Name" />
                  <div className="text-danger error-msg">{error.invoiceError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">CIN Number</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input text-transform-uppercase" value={cinNumber} onChange={(e) => { handleCinNumberChange(e) }} placeholder=" Enter CIN Number" />
                  <div className="text-danger error-msg">{error.cinError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">GSTIN Number</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input text-transform-uppercase" value={gstinNumber} onChange={(e) => { handleGstinNumberChange(e) }} placeholder=" Enter GSTIN Number" />
                  <div className="text-danger error-msg">{error.gstinError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">PAN Card Number</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input text-transform-uppercase" value={panCardNumber} onChange={(e) => { handlePanNumberChange(e) }} placeholder=" Enter PAN Card Number" />
                  <div className="text-danger error-msg">{error.panCardNumberError}</div>
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Partner Account Number</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={partnerAccountNumber} onChange={(e) => { setAccountNumber(e) }} placeholder=" Enter Partner Account Number" />
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Partner Bank Name</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={partnerBankName} onChange={(e) => { setPartnerBankName(e.target.value) }} placeholder=" Enter Partner Bank Name" />
                </div>
                <div className="input-label-group col-md-6">
                  <label className="form-label">Partner Bank Address</label>
                  <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={partnerBankAddress} onChange={(e) => { setPartnerBankAddress(e.target.value) }} placeholder=" Enter Partner Bank Address" />
                </div>
              </div>
              <div className="row mt-4 mb-5">
                <div className="form-button-div">
                  <button className="brand-button" onClick={createPartnerHandler}>Submit</button>
                  <button className="cancel_button ml-5" onClick={cancelHandler}>Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreatePartner;