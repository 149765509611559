import React, { Component } from 'react';
import Spinner from 'components/Spinner';
import MaterialTable from "material-table";
import { EYE_ICON, TRASH_ICON, EDIT_ICON, FILE_ICON, CALENDER_ICON } from 'assets/constants/Icons';
import { INTERVAL, TYPE_SUCCESS, TYPE_FAIL} from 'assets/constants/Constants';
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv6";
import ShowToast from 'components/ShowToast';
import moment from 'moment';
import { getStepList , deleteStep} from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

export class StepListPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            viewform:false,
            formData:null,
            buttonText: "Create Step",
            formList:[],
            mytheme: createTheme({})
        }
    }

    async componentDidMount () {
        const formsData = await getStepList({},{});
        this.setState({
            formList: formsData.data.result,
            isLoading: false
        });
    }

    formbuilderHandler = async (data) => {
        if(data){
            this.props.history.push({
                pathname:"/create-step-page",
                state:data
            })
        }
        else{
            this.props.history.push({
                pathname: "/create-step-page",
                state:null
            })
        }
    }

    viewFormHandler = (data) => {
        this.setState({
            viewform:true, 
            formData:data
        });
    }


    handleDelete = async (data) => {
        let isValid = true;
        if(isValid){
            if (window.confirm("Are you sure you want to delete this Step?")) {
                this.setState({ isLoading: true });
                try{
                    let deleteResponse = await deleteStep({},{},data.stepId);
                    if (deleteResponse.statusCode === 200) {
                        let fetchResponse = await getStepList({},{});
                        this.setState({
                            formList: fetchResponse.data.result,
                            isLoading: false
                        });
                        ShowToast('Form deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                    }
                    else {
                        ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
                    }
                }
    
                catch(error){
                    console.log("Error Occurred while deleting step")
                }
            }
        }
        else{
            ShowToast("You should not perform delete operation because form has been assigned", TYPE_FAIL, INTERVAL, this.props,"")
        }
        
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="pagetitle row" style={{color:'black', paddingTop:15}}>
                        <div className ="col-md-10 sm_device"><i className={`${FILE_ICON}`}></i> Step List</div>
                        <div className ="col-md-2 sm_device" style={{textAlign:'right'}}><i className={`${CALENDER_ICON}`}></i> {moment().format("DD MMM YYYY")}</div>
                    </div>
                    <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop:15 }} />
                    <div className="list-wrapper">
                        <div className="form-button-div">
                            <button className="brand-button" onClick={ () => this.formbuilderHandler(null) }>{ this.state.buttonText }</button>
                        </div>
                        <div  className="mt-4 border list-table-div" style={{marginBottom:50}}>
                        {
                            this.state.formList.length > 0 ? (
                                    <ThemeProvider theme={this.state.mytheme}>
                                        <MaterialTable
                                            title=""
                                            columns={[
                                                {
                                                    title: "Sr No", field: "serialNo",
                                                    cellStyle: { width: '10%', fontWeight: 'bolder', color: '#000000', fontSize: 14 }
                                                },
                                                {
                                                    title: "Step Name", field: "stepTitle",
                                                    cellStyle: { cellWidth: '20%', color: '#808080', fontSize: 14 },
                                                    sorting: true
                                                },
                                                {
                                                    title: "Description", field: "shortDescription",
                                                    cellStyle: { cellWidth: '20%', color: '#808080', fontSize: 14, textAlign: 'justify', textTransform: 'none' },
                                                    sorting: false
                                                }
                                            ]}
                                            data={
                                                this.state.formList.map((item, index) => {
                                                    return (
                                                        {
                                                            serialNo: (index + 1),
                                                            stepId: item._id,
                                                            stepTitle: item.step_title,
                                                            shortDescription: item.short_description,
                                                            formSchema: item.form_schema,
                                                            uiSchema: item.ui_schema,
                                                            phase: item.phase,
                                                            sendNotification: item.send_notification,
                                                            sendNotificationEmailTo : item.send_notification_email_to
                                                        }
                                                    )
                                                })
                                            }
                                            actions={[
                                                {
                                                    icon: () => <i className={`${EYE_ICON}`} title="View Form"></i>,
                                                    onClick: (event, rowData) => this.viewFormHandler(rowData)
                                                },
                                                {
                                                    icon: () => <i className={`${EDIT_ICON}`} title='Edit Form'></i>,
                                                    onClick: (event, rowData) => this.formbuilderHandler(rowData)
                                                },
                                                {
                                                    icon: () => <i className={`${TRASH_ICON}`} title="Delete Form"></i>,
                                                    onClick: (event, rowData) => this.handleDelete(rowData)
                                                }
                                            ]}
                                            options={{
                                                pageSize: 25,
                                                pageSizeOptions: [25, 50, 100],
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    color: '#999998',
                                                    fontWeight: 'bolder',
                                                    fontSize: 15
                                                },
                                                filtering: true,
                                                sorting: true,
                                                columnsButton: true,
                                                exportButton: true,
                                                exportFileName: "fb_forms",
                                            }}
                                        />
                                    </ThemeProvider>
                            ): (
                                <div className="text-center pt-2">
                                    <label>Records not found!</label>
                                </div>
                            )
                        }
                        {
                            this.state.viewform && (
                            <div className="custom_modal">
                                <div className="modal_body">
                                <button type="button" style={{fontSize:25}} className="close text-right" onClick={() => this.setState({viewform:false})}>&times;</button>
                                <Form schema={this.state.formData.formSchema} uiSchema={this.state.formData.uiSchema} validator={validator} />
                                </div>
                            </div>
                            )
                        }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default StepListPage