import React, { useEffect, useState,useRef  } from "react";
import { TYPE_SUCCESS, TYPE_FAIL,TYPE_INFO, INTERVAL } from "assets/constants/Constants";
import { PROGRAM_ICON, TRASH_ICON, ARROW_UP_ICON, ARROW_DOWN_ICON, PLUS_ICON, ALIGNJUSTIFY_ICON, USERS_ICON, STEPS_ICON, EXPORT_ICON, LOADING_SPIN } from "assets/constants/Icons";
import ShowToast from 'components/ShowToast';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import MaterialTable, { MTableToolbar } from "material-table";
// import ModalPopupProgram from 'components/ModalPopupProgram';
import {importCandidate} from 'components/ImportCandidateComponent';
import ImportErrorPopup from 'components/ImportErrorPopup';
import { registerBulkCandidate, validateCandidateData, fetchMetadata } from 'lib/utils';
import ExcelExportComponent from "components/ExcelExportComponent";
import { getEmployerList, getPartnerList, getStepList , updateProgramCall, createProgramCall, getProgramCandidateList, getMetaDataCall} from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import CreatableSelect from 'react-select/creatable';

const CreateProgramPage = (props) => {
    const [employerList, setEmployerList] = useState([]);
    const [programId] = useState(props.location.state ? props.location.state.programId : null);
    const [partnerList, setPartnerList] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [stepList, setStepList] = useState([]);
    const [employerListComponent, setEmployerListComponent] = useState("<option> Employer List </option>");
    const [projectTypeComponent, setProjectTypeComponent] = useState("<option> Project Type </option>");
    const [partnerListComponent, setPartnerListComponent] = useState("<option> Partner List </option>");
    const [stepListComponent, setStepListComponent] = useState([]);
    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [selectedPartner, setSelectedPartner] = useState((props.location.state ? { label: props.location.state.partnerName, value: props.location.state.partnerId } : null));
    const [selectedProgram, setSelectedProgram] = useState(props.location.state ? props.location.state.programName : "");
    const [stepSchemaOldFields, setFormSchemaOldFields] = useState(props.location.state ? props.location.state.workFlowSteps : null);
    const [dragItem, setDragItem] = useState(null);
    const [dragOverItem, setDragOverItem] = useState(null);
    const [selectedStep, setSelectedStep] = useState([{ title: "" }]);
    const [error, setError] = useState({});
    const [tempStepList, setTempStepList] = useState("<option> Step List </option>");
    const [stepFetched, setStepFetched] = useState(false);
    const [noOfCandidate, setNoOfCandidate] = useState(props.location.state ? props.location.state.requiredNoOfCandidate : 0);
    const [supportingNotes, setSupportingNotes] = useState(props.location.state ? props.location.state.supportingNotes : "");
    const [startDateOfProgram, setStartDateOfProgram] = useState(props.location.state ? new Date(props.location.state.startDateOfProgram) : null);
    const [selectedTrainingPartner, setSelectedTrainingPartner] = useState((props.location.state && props.location.state.partnerId && props.location.state.partnerId.length > 0 ? { label: props.location.state.trainingPartner, value: props.location.state.partnerId } : null));
    const [trainingPartnerComponent, setTrainingPartnerComponent] = useState("<option> Training Partner List </option>");
    const [employerUnitComponent, setEmployerUnitComponent] = useState("<option> Employer Unit List </option>");
    const [employerUnit, setEmployerUnit] = useState(null);
    const [disableTrainingPartner, setDisableTrainingPartner] = useState(props.location.state && props.location.state.partnerId && props.location.state.partnerId.length > 0 ? false : true);
    const [departmentListComponent, setDepartmentListComponent] = useState("<option> Select Department Unit </option>");
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [isErrorModelOpen, setIsErrorModelOpen] = useState(false);
    const [candidateImportData, setCandidateImportData] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [unitSplitArray, setUnitSplitArray] = useState([]);
    const [departmentSplitArray, setDepartmentSplitArray] = useState([]);
    const [unitSplitData, setUnitSplitData] = useState(props.location.state ? props.location.state.unitSplitData : []);
    const [partnerSplitArray, setPartnerSplitArray] = useState([]);
    const [partnerSplitData, setPartnerSplitData] = useState(props.location.state ? props.location.state.partnerSplitData : []);
    const [candidateLoadingComplete, setCandidateLoadingComplete] = useState(false);
    const [candidateSelectedList, setCandidateSelectedList] = useState([]);
    const [metaData, setMetaData] = useState(JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : []);
    const [uncheckedCandidates, assignUncheckedcandidates] = useState([]);
    const [projectType, setProjectType] = useState(props.location.state ? props.location.state.projectType ? props.location.state.projectType.toLowerCase() : null : null);
    const [csrEmployerUnit,setCsrEmployerUnit] = useState(null);
    const [createProgramBtnLoading , setCreateProgramBtnLoading] = useState(false);
    const [employerDisabled , setEmployerDisabled] = useState((props.location.state && props.location.state.programId) ? false : true);
    const fileInputRef = useRef(null);
    const mytheme =  createTheme({});

    useEffect(() =>{
        const fetchData = async() => {
            if(metaData && metaData.length === 0){
                const metaResult = await fetchMetadata();
                setMetaData(metaResult);
            }
            const projectTypeComponentList = metaData[0].meta_data.project_type.map((item) => {
                if (projectType && item.toLowerCase() === projectType) {
                    setProjectType({ label: item, value: item.toLowerCase() });
                }
                return ({ label: item, value: item.toLowerCase() });
            })
            setProjectTypeComponent(projectTypeComponentList);
        }
        fetchData();
    },[metaData,projectType])

    useEffect(() => {
        const setEmployerUnitCsr = () => {
            if (props.location.state && props.location.state.csrEmployerUnit && props.location.state.csrEmployerUnit.length > 0) {
                const csrEmployerUnit = props.location.state.csrEmployerUnit.map((item) => {
                    return ({ label: item, value: item });
                })
                setCsrEmployerUnit(csrEmployerUnit);
            }
        }
        setEmployerUnitCsr();
    }, [props.location.state])

    useEffect(() => {
        if (projectType && projectType.value === "csr" && !props.location.state) {
            setPredifinedSteps(metaData[0].meta_data.workflow_steps_CSR);
        }
    }, [projectType, metaData,props]);

    useEffect(() => {
        if (props.location.state) {
            let unitEmployer = [];
            if (employerUnit) {
                employerUnit.forEach((item) => {
                    unitEmployer.push(item.label);
                })
            }
            unitEmployer.sort((a, b) => (a > b) ? 1: -1);
            setUnitSplitArray(unitEmployer);
            let department = [];
            if (selectedDepartment) {
                selectedDepartment.forEach((item) => {
                    department.push(item.value)
                })
                setDepartmentSplitArray(department);
            }
            let splitArrayUnit = {};
            if (employerUnit && employerUnit.length > 0 && unitSplitData.length > 0) {
                unitSplitData.forEach((item) => {
                    const foundObject = employerUnit.find((findItem) => findItem.value === item.unit_id);
                    if (foundObject) {
                        splitArrayUnit[foundObject.label] = {
                            ...splitArrayUnit[foundObject.label],
                            [item.department]: item.candidate_needed,
                        }
                    }
                })
                setUnitSplitData(splitArrayUnit);
            }
        }
    }, [employerUnit, props, selectedDepartment, unitSplitData])

    useEffect(() => {
        if (props.location.state) {
            let trainingPartner = [];
            let department = [];
            if (selectedDepartment) {
                selectedDepartment.forEach((item) => {
                    department.push(item.value)
                })
                setDepartmentSplitArray(department);
            }
            let splitArrayPartner = {};

            if (selectedTrainingPartner && selectedTrainingPartner.length > 0) {
                selectedTrainingPartner.forEach((item) => {
                    trainingPartner.push(item.label);
                })
                trainingPartner.sort((a, b) => (a > b) ? 1: -1);
                setPartnerSplitArray(trainingPartner);
            }
            if (selectedTrainingPartner && selectedTrainingPartner.length > 0 && partnerSplitData && partnerSplitData.length > 0) {
                partnerSplitData.forEach((item) => {
                    const foundObject = selectedTrainingPartner.find((findItem) => findItem.value === item.partner_id);
                    if (foundObject) {
                        splitArrayPartner[foundObject.label] = {
                            ...splitArrayPartner[foundObject.label],
                            [item.department]: item.candidate_needed,
                        }
                    }
                    setPartnerSplitData(splitArrayPartner);
                })
            }
        }
    }, [selectedTrainingPartner, props, selectedDepartment, partnerSplitData])

    useEffect(() => {
        const fetchEmployerList = async () => {
            try {
                const data = await getEmployerList({},{});
                setEmployerList(data.data.result);
                setListComponent();
            }
            catch (error) {
                console.log("error while fetching data", error);
            }
        }
        fetchEmployerList();

        const setListComponent = async () => {
            const companyMetaData = metaData[0].meta_data.companies;
            const listComponent = companyMetaData.map((item) => {
                if (props.location.state) {
                    if (props.location.state.employerName === item.company_name) {
                        setSelectedEmployer({ label: item.company_name, value: item.company_id });
                    }
                }
                return { label: item.company_name, value: item.company_id }
            });
            setEmployerListComponent(listComponent);
        }
        const fetchPartnerList = async () => {
            try {
                const response = await getPartnerList({},{});
                const data = response.data.result;
                setPartnerList(data);
                setPartnerComponent(data);
            }
            catch (error) {
                console.log("Error Occurred while fetching training partner list", error);
            }
        }
        fetchPartnerList();
        fetchCandidateList();
        const fetchStepList = async () => {
            try {
                const stepData = await getStepList({},{});
                setStepList(stepData.data.result);
                setStepFetched(true);
                setComponentStepList(stepData.data.result);
            }
            catch (error) {
                console.log("Error Occurred while fetching step list", error);
            }
        }
        fetchStepList();
        setDepartmentComponentList();
        setFormSchemaOldFields(stepSchemaOldFields);
        if (stepSchemaOldFields !== null) {
            let stepDataArray = [];
            let data = [];
            stepSchemaOldFields.sort((a, b) => a.step_no - b.step_no);
            stepSchemaOldFields.forEach((item) => {
                data.push(item.stepTitle)
            })
            data.map((item) => {
                stepDataArray.push({ "title": item })
                return true;
            });
            const uniqueArray = Array.from(
                stepDataArray.reduce((map, obj) => map.set(obj.title, obj), new Map()).values()
            );
            setSelectedStep(uniqueArray);
        }
        // eslint-disable-next-line
    }, [(props.location.state), stepSchemaOldFields]);

    useEffect(() =>{
        const setDepartment = () => {
            if (props.location.state) {
                if (props.location.state.departmentList && props.location.state.departmentList.length > 0) {
                    const departmentSelected = props.location.state.departmentList.map((item) => {
                        return { label: item, value: item };
                    })
                    setSelectedDepartment(departmentSelected);
                }
            }
        }
        setDepartment();
    },[props])

    const setDepartmentComponentList = () => {
        if(metaData[0].meta_data.department){
            const list = metaData[0].meta_data.department.map((item) => {
                return ({label: item, value: item});
            })
            setDepartmentListComponent(list);
        }
    }

    const setPartnerComponent = async (list) => {
        let listComponent = [];
        list.forEach((item) => {
            if(item.partner_name){
                listComponent.push({ label: item.partner_name, value: item._id })
            }
        });
        const uniqueArray = Array.from(
            listComponent.reduce((map, obj) => map.set(obj.label, obj), new Map()).values()
        );
        setPartnerListComponent(uniqueArray);
    }

    const setTrainingPartnerListComponent = async (selectedPartner,list) => {
        let listComponent = [];
        list.forEach((item) => {
            if(item.partner_name === selectedPartner){
                if(item.training_partner){
                    listComponent.push({ label: item.training_partner, value: item._id })
                }
            }
        });
        setTrainingPartnerComponent(listComponent);
    }

    const fetchCandidateList = async (importData = null) => {
        try {
            let previousData = [];
            if (candidateList.length > 0) {
                previousData = [...candidateList];
            }
            const data = await getProgramCandidateList({}, {}, programId);
            let list = [...data.data.result];
            if (importData === null) {
                if (list.length > 0) {
                    list.forEach((item) => {
                        item.selected = false;
                    });
                }
                if (props.location.state) {
                    const candidateId = [...props.location.state.candidateId]
                    list.forEach((listItem) => {
                        candidateId.forEach((item) => {
                            if (listItem._id === item) {
                                listItem.selected = true;
                            }
                        })
                    });
                }
            }
            else if (importData && importData.length > 0) {
                list.forEach((item) => {
                    item.selected = false;
                    importData.forEach((importItem) => {
                        if (importItem._id === item._id) {
                            item.selected = true;
                        }
                    })
                    previousData.forEach((previousItem) => {
                        if (previousItem._id === item._id) {
                            if (previousItem.selected === true) {
                                item.selected = true;
                            }
                        }
                    })
                });
            }
            list.sort((a, b) => {
                if (a.selected && !b.selected) {
                    return -1;
                } else if (!a.selected && b.selected) {
                    return 1;
                } else {
                    return 0;
                }
            });
            setCandidateList(list);
            const listSelected = list.filter((item) => item.selected === true)
            setCandidateSelectedList(listSelected);
            setCandidateLoadingComplete(true);
        }
        catch (error) {
            console.log("Error Occurred while fetching candidate list", error);
        }
    }

    const setComponentStepList = async (list) => {
        const listComponent = list.map((item) => {
            return { label: item.step_title, value: item._id };
        });
        setStepListComponent(listComponent);
        setTempStepList(listComponent);
    }

    const changeEmployer = (event) => {
        setSelectedEmployer(event);
        setEmployerUnitListComponent(event);
        setEmployerDisabled(false);
    }

    useEffect(() =>{
        let unitList = [];
        let trainingPartnerList = [];
        if(props.location.state){
            if (props.location.state.employerItem && props.location.state.employerItem.length > 0) {
                props.location.state.employerItem.forEach((item) => {
                    unitList.push({label: item.unit_name, value: item._id})
                })
            }
            if(props.location.state.partnerItem && props.location.state.partnerItem.length > 0){
                props.location.state.partnerItem.forEach((item) => {
                    trainingPartnerList.push({label: item.training_partner, value: item._id})
                })
            }
        }
        if(unitList.length > 0){
            setEmployerUnit(unitList);
        }
        if(trainingPartnerList.length > 0){
            setSelectedTrainingPartner(trainingPartnerList);
        }
    },[props])

    const setEmployerUnitListComponent = (event) => {
        let list = [];
        employerList.forEach((item) => {
            if(event.label === item.employer_name){
                list.push({label: item.unit_name, value:item._id});
            }
        })
        setEmployerUnitComponent(list);
    }

    useEffect(() =>{
        const employerUnitComponentSet = () =>{
            if(selectedEmployer && employerList && employerList.length > 0){
                let list =[];
                employerList.forEach((item) => {
                    if(selectedEmployer.label === item.employer_name){
                        list.push({label: item.unit_name, value:item._id});
                    }
                })
                setEmployerUnitComponent(list);
            }
        }
        employerUnitComponentSet();
    },[selectedEmployer,employerList])

    useEffect(() =>{
        const partnerComponentSet = () =>{
            if(selectedPartner && partnerList && partnerList.length > 0){
                let list =[];
                partnerList.forEach((item) => {
                    if(selectedPartner.label === item.partner_name){
                        list.push({label: item.training_partner, value:item._id});
                    }
                })
                setTrainingPartnerComponent(list);
            }
        }
        partnerComponentSet();
    },[selectedTrainingPartner,partnerList,selectedPartner])


    const changeProgram = (event) => {
        setSelectedProgram(event.target.value);
    }

    const changeNoOfCandidate = (event) => {
        const regularExpression = /^[0-9\b]+$/;
        if (event.target.value === '' || regularExpression.test(event.target.value)) {
            setNoOfCandidate(event.target.value);
        }
    }

    const changeProjectType = (event) => {
        setProjectType(event);
        if (event && event.value !== "csr") {
            setSelectedStep([{ title: "" }]);
        } else {
            setPredifinedSteps(metaData[0].meta_data.workflow_steps_CSR);
        }
        setSelectedPartner(null);
    }

    const changeSupportingNotes = (event) => {
        setSupportingNotes(event.target.value);
    }

    const changeStartDateOfProgram = (date) => {
        setStartDateOfProgram(date);
    };

    const changeParnter = (event) => {
        setSelectedPartner({ label: event.label, value: event.value });
        setTrainingPartnerListComponent(event.label,partnerList);
        if(event.label ==="TIMES-PRO"){
            setDisableTrainingPartner(true)
            setSelectedTrainingPartner(null);
        }
        else{
            setDisableTrainingPartner(false)
        }
        if (projectType && projectType.value !== "csr") {
            if (event.label.toLowerCase().trim() === "thsc-rtd") {
                setPredifinedSteps(metaData[0].meta_data.workflow_steps_THSC);
            }
            else {
                setPredifinedSteps(metaData[0].meta_data.workflow_steps_TimesPro);
            }
        }
    }

    const changeTrainingParnter = (event) =>{
        if (event.length > 10){
            ShowToast('Please select only 10 training partner.', TYPE_INFO, INTERVAL, null, "");
        }else{
            setSelectedTrainingPartner(event);
            let partnerArray = [];
            if(event.length > 0){
                event.forEach((item) => {
                    partnerArray.push(item.label);
                })
                partnerArray.sort((a,b) => (a > b) ? 1 : -1);
            }
            setPartnerSplitArray(partnerArray);
            updateSplitDataForPartner(partnerArray);
        }
    }

    const updateSplitDataForPartner = (partnerArray) => {
        if(Object.entries(partnerSplitData).length > 0 && departmentSplitArray.length > 0){
            let splitDataPartner = {
                ...partnerSplitData
            }
            partnerArray.forEach((partnerItem) => {
                let findObject = false;
                for(let i in partnerSplitData){
                    if(findObject === false && partnerItem === i){
                        findObject = true;
                    }
                }
                if(findObject === false){
                    splitDataPartner={
                        ...partnerSplitData,
                        [partnerItem]:{}
                    }
                    departmentSplitArray.forEach((item)=>{
                        splitDataPartner[partnerItem][item]= 0
                    })
                }
            })
            setPartnerSplitData(splitDataPartner)
        }
    }

    const changeDepartment = (event) => {
        if (event.length > 5) {
            ShowToast('Please select only 5 Department.', TYPE_INFO, INTERVAL, null, "");
        } else {
            setSelectedDepartment(event);
            let departmentArray = [];
            if (event.length > 0) {
                event.forEach((eventItem) => {
                    departmentArray.push(eventItem.label)
                })
                setDepartmentSplitArray(departmentArray);
                if(props.location.state){
                    updateSplitDataUnit(departmentArray);
                    updateSplitDataPartner(departmentArray);
                }
            }
        }
    }

    const updateSplitDataPartner = (departmentArray) => {
        let splitArrayPartner = {};
        for (let i in partnerSplitData) {
            for (let j in partnerSplitData[i]) {
                const findDepartment = departmentArray.find((findItem) => findItem === j)
                if (findDepartment) {
                    splitArrayPartner[i] = {
                        ...splitArrayPartner[i],
                        [j]: partnerSplitData[i][j]
                    }
                }
            }
        }
        if (Object.entries(splitArrayPartner).length > 0) {
            setPartnerSplitData(splitArrayPartner);
        }
    }
    
    const updateSplitDataUnit = (departmentArray) => {
        let splitArrayUnit = {};
        for (let i in unitSplitData) {
            for (let j in unitSplitData[i]) {
                const findDepartment = departmentArray.find((findItem) => findItem === j)
                if (findDepartment) {
                    splitArrayUnit[i] = {
                        ...splitArrayUnit[i],
                        [j]: unitSplitData[i][j]
                    }
                }
            }
        }
        if (Object.entries(splitArrayUnit).length > 0) {
            setUnitSplitData(splitArrayUnit);
        }
    }

    const changeEmployerUnit = (event) => {
        if (event.length > 10){
            ShowToast('Please select only 10 Employer unit.', TYPE_INFO, INTERVAL, null, "");
        }else{
            setEmployerUnit(event);
            let unitArray = [];
            if(event.length > 0){
                event.forEach((item) => {
                    unitArray.push(item.label);
                })
                unitArray.sort((a,b) => (a > b) ? 1 : -1);
            }
            setUnitSplitArray(unitArray);
            updateSplitDataForUnit(unitArray)
        }
    }

    const changeCsrEmployerUnit = (event) => {
        if (event.length > 10) {
            ShowToast('Please select only 10 Employer unit.', TYPE_INFO, INTERVAL, null, "");
        }
        else {
            setCsrEmployerUnit(event);
        }
    }

    const updateSplitDataForUnit = (unitArray) =>{
        if(Object.entries(unitSplitData).length > 0 && departmentSplitArray.length > 0){
            let splitDataUnit = {
                ...unitSplitData
            }
            unitArray.forEach((unitItem) => {
                let findObject = false;
                for(let i in unitSplitData){
                    if(findObject === false && unitItem === i){
                        findObject = true;
                    }
                }
                if(findObject === false){
                    splitDataUnit={
                        ...unitSplitData,
                        [unitItem]:{}
                    }
                    departmentSplitArray.forEach((item)=>{
                        splitDataUnit[unitItem][item]= 0
                    })
                }
            })
            setUnitSplitData(splitDataUnit)
        }
    }

    useEffect(() => {
        const unitSplitData = {};
        if(departmentSplitArray.length > 0 && unitSplitArray.length > 0 && !props.location.state){
            unitSplitArray.forEach(unit => {
                unitSplitData[unit] = {};
                departmentSplitArray.forEach(department => {
                    unitSplitData[unit][department] = 0;
                });
            });
            setUnitSplitData(unitSplitData)
        }
        const partnerSplitData = {};
        if(departmentSplitArray.length > 0 && partnerSplitArray.length > 0 && !props.location.state){
            partnerSplitArray.forEach(partner => {
                partnerSplitData[partner] = {};
                departmentSplitArray.forEach(department => {
                    partnerSplitData[partner][department] = 0;
                });
            });
            setPartnerSplitData(partnerSplitData)
        }
    }, [departmentSplitArray,unitSplitArray,props,partnerSplitArray]);

    const updateSplitData = (unit, department, value) => {
        const newData = { ...unitSplitData };
        newData[unit][department] = value;
        setUnitSplitData(newData);
    };

    const updatePartnerSplitData = (partner, department, value) => {
        const newData = { ...partnerSplitData };
        newData[partner][department] = value;
        setPartnerSplitData(newData);
    };

    const setStepTitleHandler = (value, index) => {
        const stepDataArray = [...selectedStep];
        stepDataArray[index].title = value.label;
        setSelectedStep(stepDataArray);
    }

    useEffect(() => {
        if (!projectType || projectType.value !== "csr") {
            if (selectedPartner && selectedPartner.label) {
                if (selectedPartner.label.toLowerCase().trim() === "thsc-rtd" && !props.location.state) {
                    setPredifinedSteps(metaData[0].meta_data.workflow_steps_THSC);
                }
                else if (selectedPartner.label.toLowerCase().trim() === "times-pro" && !props.location.state) {
                    setPredifinedSteps(metaData[0].meta_data.workflow_steps_TimesPro);
                }
                else if (selectedPartner.label.toLowerCase().trim() === "thsc-placement" && !props.location.state) {
                    setPredifinedSteps(metaData[0].meta_data.workflow_steps_THSC_placement);
                }
                else if (!props.location.state) {
                    setSelectedStep([{ title: "" }]);
                }
            }
        }
    }, [selectedPartner, metaData, (props.location.state), projectType]);

    const setPredifinedSteps = async(stepData) =>{
        let stepDataArray = [];
        let data = [];
        stepData.sort((a, b) => a.step_no - b.step_no);
        stepData.forEach((item) => {
            data.push(item.stepTitle)
        })
        data.map((item) => {
            stepDataArray.push({ "title": item })
            return true;
        });
        setSelectedStep(stepDataArray);
    }

    useEffect(() => {
        const result = stepList.filter(item1 => !selectedStep.some(item2 => item2.title === item1.step_title));
        setComponentStepList(result);
    }, [selectedStep,stepFetched,stepList]);

    const addField = () => {
        if (validate()) {
            const newFormData = [...selectedStep];
            newFormData.push({ title: "" });
            setSelectedStep(newFormData);
        }
        else {
            ShowToast('Some of the fields are not validated. Please check and then add new field.', TYPE_FAIL, INTERVAL, null, "/create-program-page");
        }
    }

    const removeField = (deleteIndex) => {
        if (deleteIndex === 0 && selectedStep.length === 1) {
            alert("You Cannot Delete Single Element");
        }
        else {
            if (window.confirm("Are you sure you want delete this field?")) {
                const newFormData = [...selectedStep];
                let deletedItem = null;
                selectedStep.forEach((item, index) => {
                    if (deleteIndex === index) {
                        deletedItem = item;
                    }
                })
                tempStepList.forEach((item) => {
                    if (item.label === deletedItem.title) {
                        deletedItem = item;
                    }
                });
                let tempListComponent = [...stepListComponent];
                if (deletedItem) {
                    tempListComponent.push(deletedItem);
                    setStepListComponent(tempListComponent);
                }
                let arrayData = [];
                newFormData.map((item, index) => {
                    if (index !== deleteIndex) {
                        arrayData.push(item);
                    }
                    return false;
                });
                setSelectedStep(arrayData);
            }
        }
    }

    const arrowDown = (index) => {
        const stepDataArray = [...selectedStep];
        if (stepDataArray.length > 1 && ((index + 1) < stepDataArray.length)) {
            let nextField = stepDataArray[index + 1];
            let prevField = stepDataArray[index];
            stepDataArray[index + 1] = prevField;
            stepDataArray[index] = nextField;
            setSelectedStep(stepDataArray);
        }
    }

    const arrowUp = (index) => {
        const stepDataArray = [...selectedStep];
        if (stepDataArray.length > 1 && index !== 0) {
            let prevField = stepDataArray[index - 1];
            let nextField = stepDataArray[index];
            stepDataArray[index - 1] = nextField;
            stepDataArray[index] = prevField;
            setSelectedStep(stepDataArray);
        }
    }

    const dragStart = (e, position) => {
        setDragItem(position);
    };

    const dragEnter = (e, position) => {
        setDragOverItem(position);
    };

    const drop = (e) => {
        const stepDataArray = [...selectedStep];
        const dragItemContent = stepDataArray[dragItem];
        stepDataArray.splice(dragItem, 1);
        stepDataArray.splice(dragOverItem, 0, dragItemContent);
        setSelectedStep(stepDataArray);
        setDragItem(null);
        setDragOverItem(null);
    };

    const validateProgram = () => {
        const validateFlag = validate();
        if(validateFlag){
            createProgram();
        }
        else{
            ShowToast('Please enter required data', TYPE_FAIL, INTERVAL, null, "");
        }
    }

    const unitArraySplit = () =>{
        let splitUnitArray =[];
        for (let i in unitSplitData) {
            const foundObject = employerUnit.find((findItem) => findItem.label === i);
            if(foundObject){
                for (let j in unitSplitData[i]){
                    let pushObject = {
                        unit_name: i,
                        candidate_needed:parseInt(unitSplitData[i][j]),
                        department: j,
                        unit_id: foundObject.value
                    };
                    splitUnitArray.push(pushObject);
                }
            }
        }
        return splitUnitArray;
    }

    const partnerArraySplit = () =>{
        let partnerUnitArray =[];
        for (let i in partnerSplitData) {
            if (selectedTrainingPartner && selectedTrainingPartner.length > 0) {
                const foundObject = selectedTrainingPartner.find((findItem) => findItem.label === i);
                if(foundObject){
                    for (let j in partnerSplitData[i]){
                        let pushObject = {
                            training_partner: i,
                            candidate_needed:parseInt(partnerSplitData[i][j]),
                            department: j,
                            partner_id: foundObject.value
                        };
                        partnerUnitArray.push(pushObject);
                    }
                }
            }
        }
        return partnerUnitArray;
    }

    const createProgram = async () => {
        setCreateProgramBtnLoading(true);
        const finalUnitSplit = unitArraySplit();
        const finalPartnerSplit = partnerArraySplit();
        let candidateArray = [];
        candidateList.forEach((item) => {
            if (item.selected === true) {
                candidateArray.push(item._id)
            }
        })
        let stepSelected = [];
        let tempStepList = [...selectedStep];
        tempStepList.forEach((item, index) => {
            item.step_no = index + 1;
        });
        stepList.forEach((stepItem) => {
            tempStepList.forEach((item) => {
                if (stepItem.step_title === item.title) {
                    stepSelected.push({ stepTitle: stepItem.step_title, step_id: stepItem._id, step_no: item.step_no, step_phase: stepItem.phase });
                }
            })
        });
        const uniqueArray = Array.from(
            stepSelected.reduce((map, obj) => map.set(obj.stepTitle, obj), new Map()).values()
        );
        let partnerIdArray = [];
        if (selectedTrainingPartner) {
            partnerIdArray = selectedTrainingPartner.map((item) => {
                return item.value
            })
        }
        let unitIdArray = [];
        if (employerUnit && employerUnit.length > 0) {
            unitIdArray = employerUnit.map((item) => {
                return item.value
            })
        }
        let programStatus = "Ongoing";
        if(props.location.state && props.location.state.programStatus){
            programStatus = props.location.state.programStatus;
        }
        const departmentList = selectedDepartment.map((item) =>{
            return item.label;
        })

        let csrEmployerUnitList = [];
        if (csrEmployerUnit && csrEmployerUnit.length > 0) {
            csrEmployerUnitList = csrEmployerUnit.map((item) => {
                return item.value;
            })
        }
        const programParams = {
            programName: selectedProgram,
            companyId: (selectedEmployer && selectedEmployer.__isNew__) ? selectedEmployer : selectedEmployer.value,
            employerId: unitIdArray,
            partnerId: partnerIdArray,
            candidateId: candidateArray,
            workFlowSteps: uniqueArray,
            programStatus: programStatus,
            noOfCandidate: noOfCandidate,
            supportingNotes: supportingNotes,
            startDateOfProgram: startDateOfProgram,
            departmentList: departmentList,
            unitSplitArray:finalUnitSplit,
            partnerSplitArray:finalPartnerSplit,
            uncheckedCandidates: uncheckedCandidates,
            projectType: projectType.value,
            csrEmployerUnit: csrEmployerUnitList
        };

        let codeFlag = false;
        if (props.location.state) {
            if (props.location.state.programCode) {
                codeFlag = true;
            }
        }
        let programCode = "";
        if (codeFlag === false) {
            programCode = selectedProgram.substring(0, 2);
            const partnerCode = selectedPartner.label.substring(0, 2);
            const employerCode = selectedEmployer.label.substring(0, 2);
            let timeStamp = "";
            timeStamp = `${new Date().getTime()}`;
            timeStamp = timeStamp.substr(-4)
            programCode = `${programCode}${employerCode}${partnerCode}${timeStamp}`;
            programCode = programCode.toUpperCase();
            programParams.programCode = programCode;
        }

        try {
            if (programId) {
                await updateProgramCall({},programParams, programId);
            }
            else {
                await createProgramCall({},programParams);
            }
            if (selectedEmployer && selectedEmployer.__isNew__) {
                const getMetaData = await getMetaDataCall({}, {});
                if (getMetaData && getMetaData.data && getMetaData.data.result) {
                    localStorage.setItem("metadata", null);
                    localStorage.setItem("metadata", JSON.stringify(getMetaData.data.result));
                }
            }
            setCreateProgramBtnLoading(false);
            ShowToast(`Program ${programId ? "Updated" : "Created"} successfully`, TYPE_SUCCESS, INTERVAL, props, "/project-list");
        }
        catch (error) {
            console.log(`Error occurred while ${programId ? "updating" :"creating"} program`, error);
            setCreateProgramBtnLoading(false);
        }

    }

    const validate = () => {
        let isValid = true;
        let error = {};
        const finalUnitSplit = unitArraySplit();
        if (selectedEmployer === null) {
            isValid = false;
            error.selectedEmployer = "Please select Employer";
        }
        if (selectedPartner === null) {
            isValid = false;
            error.selectedPartner = "Please select the Project";
        }
        if (selectedStep) {
            if (selectedStep.length === 1) {
                if (selectedStep[0].title === "") {
                    isValid = false;
                    error.selectedStep = "Please select the Step data";
                }
            }
        }
        if (selectedProgram === "") {
            isValid = false;
            error.selectedProgram = "Please select the project name";
        }
        if (!noOfCandidate) {
            isValid = false;
            error.noOfCandidate = "Please Enter the no. of candidate required";
        }
        if (supportingNotes === "") {
            isValid = false;
            error.supportingNotes = "Please enter the supporting notes";
        }
        if (!startDateOfProgram) {
            isValid = false;
            error.startDateOfProgram = "Please select the project start date"
        }
        if (employerUnit === null && (!projectType || projectType.value !== "csr")) {
            isValid = false;
            error.employerUnit = "Please select the Employer unit";
        }
        else if ((employerUnit && employerUnit.length === 0) && (!projectType || projectType.value !== "csr")) {
            isValid = false;
            error.employerUnit = "Please select the Employer unit";
        }
        if(selectedTrainingPartner === null && disableTrainingPartner === false){
            isValid = false;
            error.selectedTrainingPartner = "Please select the training partner";
        }
        else if(selectedTrainingPartner && selectedTrainingPartner.length === 0 && disableTrainingPartner === false){
            isValid = false;
            error.selectedTrainingPartner = "Please select the training partner";
        }

        if(!selectedDepartment){
            isValid = false;
            error.selectedDepartment ="Please select Department";
        }

        if (!projectType) {
            isValid = false;
            error.projectType = "Please select Project Type";
        }

        if(finalUnitSplit && finalUnitSplit.length > 0){
            let totalCount = 0;
            finalUnitSplit.forEach((item) =>{
                totalCount = totalCount + parseInt(item.candidate_needed);
            })
            const requiredCandidate = parseInt(noOfCandidate);
            if(requiredCandidate !== totalCount){
                isValid = false;
                error.unitArraySplit = `Required No of Candidate is ${requiredCandidate} and total candidate entered is ${totalCount}`;
            }
        }
        setError(error);
        return isValid;
    }

    const handleCheckBox = (event, rowData) => {
        const updatedData = candidateList.map(item => {
            if (item._id === rowData.id) {
                return { ...item, selected: !item.selected };
            }
            return item;
        });
        const candidateSelected = updatedData.filter((item) => item.selected === true);
        const candidateUnchecked = updatedData
        .filter((item) => item.selected === false && item.program_id !== "")
        .map((item) => item._id);
        assignUncheckedcandidates(candidateUnchecked);
        setCandidateSelectedList(candidateSelected);
        setCandidateList(updatedData);
    }

    const columns = [
        {
            title: 'Select',
            render: rowData => (
                <input type="checkbox" checked={rowData.selected} onChange={event => handleCheckBox(event, rowData)} />
            ),
            cellStyle: { width: '9%', color: '#808080', fontSize: 14 }
        },
        { title: "First Name", field: "firstName", cellStyle: { width: '12% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14 }, sorting: false },
        { title: "Last Name", field: "lastName", cellStyle: { width: '12%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Fathers Name", field: "fatherName", cellStyle: { width: '12%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Department Selected", field: "departmentSelected", cellStyle: { width: '9%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Training Partner", field: "trainingPartner", cellStyle: { width: '9%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Employer Unit", field: "employerUnit", cellStyle: { width: '9%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "DOB", field: "disaplayDobDate", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Age", field: "ageNumber", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Contact No", field: "mobileNumber", cellStyle: { width: '10%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Aadhar No.", field: "adharNo", cellStyle: { width: '10%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Email Id", field: "email", cellStyle: { width: '9%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Qualification", field: "qualification", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Gender", field: "gender", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Candidate State", field: "state", cellStyle: { width: '9%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Candidate City", field: "city", cellStyle: { width: '9%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Creation Date", field: "creationDate", cellStyle: { width: '9%', color: '#808080', fontSize: 14 }, sorting: false }
    ];

    const handleCandidateExcelUpload = async(event) => {
        setImportLoading(true);
        try{
            if(event && event.target && event.target.files[0]){
                const requestData = await importCandidate(event, partnerList, employerList, programId, [], false, false);
                if(requestData && requestData.length > 0){
                    const validateFlag = validateCandidateData(requestData);
                    let importData = null;
                    try {
                        if (validateFlag) {
                            importData = await registerBulkCandidate(requestData);
                        }
                        else {
                            setIsErrorModelOpen(true);
                            setCandidateImportData(requestData);
                        }
                        if(importData){
                            await fetchCandidateList(importData);
                        }
                        setImportLoading(false);
                    }
                    catch(error){
                        console.log("Error Occurred while importing candidate",error);
                        setImportLoading(false);
                    }
                }
                else{
                    ShowToast("No Candidate in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
                    setImportLoading(false);
                }
            }
            else{
                ShowToast("Please select candidate excel for Importing", TYPE_INFO, INTERVAL, "", "");
                setImportLoading(false);
            }
        }
        catch(error){
            console.log("Error Occurred while importing",error);
            setImportLoading(false);
        }
    }

    const importCandidateExcel = async () => {
        fileInputRef.current.click();
    }

    const cancelHandle = () =>{ 
        if(isModelOpen === true){
            setIsModelOpen(false);
        }
        if(isErrorModelOpen === true){
            setIsErrorModelOpen(false);
            window.location.reload();
        }
    }

    return (
        <>
            <div className="main-content">
                <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                    <div className="col-md-10 sm_device"><i className={`${PROGRAM_ICON}`}></i> Project Creation</div>
                    <div className="col-md-2 sm_device" style={{ textAlign: 'right' }}><i className={``}></i> {moment().format("DD MMM YYYY")}</div>
                </div>
                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
                <div className="create-program-outer-div">
                    <div className="height-100">
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Project Name</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner">
                                    <input
                                        className="form-input"
                                        placeholder={`Enter Project Name`}
                                        value={selectedProgram}
                                        onChange={changeProgram}
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.selectedProgram}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Project Type</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner">
                                    <Select
                                        placeholder={`Select Project Type`}
                                        value={projectType}
                                        onChange={changeProjectType}
                                        options={projectTypeComponent}
                                        isSearchable={true}
                                        isMulti={false}
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.projectType}
                            </div>
                        </div>
                    </div>
                    <div className="height-100">
                    <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">{projectType && projectType.value ==="csr" ? "CSR" : "Select"} Company</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner">
                                    {
                                        projectType && projectType.value === "csr" ? (
                                            <CreatableSelect
                                                placeholder={`Select CSR Company`}
                                                value={selectedEmployer}
                                                onChange={changeEmployer}
                                                options={employerListComponent}
                                                isSearchable={true}
                                                isMulti={false}
                                            />
                                        ) : (
                                            <Select
                                                placeholder={`Select Company`}
                                                value={selectedEmployer}
                                                onChange={changeEmployer}
                                                options={employerListComponent}
                                                isSearchable={true}
                                                isMulti={false}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.selectedEmployer}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Select Employer Unit</label>{(!projectType || projectType.value !== "csr") ? <span className="text-danger"> *</span>:""}
                                <div className="form-wrap-inner">
                                    {
                                        !projectType || projectType.value !== "csr" ? (
                                            <Select
                                                placeholder={`Select Employer Unit`}
                                                value={employerUnit}
                                                onChange={changeEmployerUnit}
                                                options={employerUnitComponent}
                                                isSearchable={true}
                                                isMulti={true}
                                                isDisabled={employerDisabled}
                                            />
                                        ) : (
                                            <CreatableSelect
                                                placeholder={`Select Employer Unit`}
                                                value={csrEmployerUnit}
                                                onChange={changeCsrEmployerUnit}
                                                options={employerUnitComponent}
                                                isSearchable={true}
                                                isMulti={true}
                                                isDisabled={employerDisabled}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.employerUnit}
                            </div>
                        </div>
                    </div>
                    <div className="height-100">
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Select Program</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner">
                                    <Select
                                        placeholder={`Select Program`}
                                        value={selectedPartner}
                                        onChange={changeParnter}
                                        options={partnerListComponent}
                                        isSearchable={true}
                                        isMulti={false}
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.selectedPartner}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Select Training Partner</label><span className="text-danger">{selectedPartner && selectedPartner.label && selectedPartner.label === "TIMES-PRO" ? "" :" *"}</span>
                                <div className="form-wrap-inner">
                                    <Select
                                        placeholder={`Select  Training Partner`}
                                        value={selectedTrainingPartner}
                                        onChange={changeTrainingParnter}
                                        options={trainingPartnerComponent}
                                        isSearchable={true}
                                        isMulti={true}
                                        isDisabled={disableTrainingPartner}
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.selectedTrainingPartner}
                            </div>
                        </div>
                    </div>
                    <div className="height-100">
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Total No of Required Candidate</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner">
                                    <input
                                        className="form-input"
                                        placeholder={`Enter No of Required Candidate`}
                                        value={noOfCandidate}
                                        onChange={changeNoOfCandidate}
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.noOfCandidate}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Select Department</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner">
                                    <Select
                                        placeholder={`Select Department`}
                                        value={selectedDepartment}
                                        onChange={changeDepartment}
                                        options={departmentListComponent}
                                        isSearchable={true}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.selectedDepartment}
                            </div>
                        </div>
                    </div>
                    {
                        departmentSplitArray.length > 0 && unitSplitArray.length > 0 && (Object.keys(unitSplitData).length > 0) && (<>
                            <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                                <div className="col-md-10 sm_device"><i className={`${USERS_ICON}`}></i> Candidate Split</div>
                            </div>
                            <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
                            <div className="program-modal-inner-div candidate-management-inner">
                                <p className="department-employer-table-heading">Department to Employer Unit Split</p>
                                <table className="split-table">
                                    <thead>
                                        <tr className="program-modal-main-row-header">
                                            <th></th>
                                            {unitSplitArray.map((unit, index) => (
                                                <th key={index}>{unit}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            departmentSplitArray.map((department, index) => {
                                                let rowClass = "";
                                                if (index % 2) {
                                                    rowClass = "program-modal-row-background-color"
                                                }
                                                return (
                                                    <tr key={index} className={rowClass}>
                                                        <td className="padding-right-10 padding-left-5">{department}</td>
                                                        {
                                                            unitSplitArray.map((unit, subIndex) => {
                                                                return (
                                                                    <td key={subIndex}>
                                                                        <input
                                                                            type="number"
                                                                            value={unitSplitData[unit][department]}
                                                                            onChange={(e) => updateSplitData(unit, department, e.target.value)}
                                                                        />
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="text-danger error-msg">
                                    {error.unitArraySplit}
                                </div>
                            </div>
                        </>)
                    }
                    {/* -------------------Department Trainging Partner Table------------------------------------ */}
                    <div className="program-modal-inner-div candidate-management-inner">
                        {
                            departmentSplitArray.length > 0 && partnerSplitArray.length > 0 && (Object.keys(partnerSplitData).length > 0) && (<>
                                
                                <p className="department-employer-table-heading">Department to Training Partner Split</p>
                                <table className="split-table">
                                    <thead>
                                        <tr className="program-modal-main-row-header">
                                            <th></th>
                                            {partnerSplitArray.map((partner, index) => (
                                                <th key={index}>{partner}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            departmentSplitArray.map((department, index) => {
                                                let rowClass = "";
                                                if (index % 2) {
                                                    rowClass = "program-modal-row-background-color"
                                                }
                                                return (
                                                    <tr key={index} className={rowClass}>
                                                        <td className="padding-right-10 padding-left-5">{department}</td>
                                                        {
                                                            partnerSplitArray.map((partner, subIndex) => {
                                                                return (
                                                                    <td key={subIndex}>
                                                                        <input
                                                                            type="number"
                                                                            value={partnerSplitData[partner][department]}
                                                                            onChange={(e) => updatePartnerSplitData(partner, department, e.target.value)}
                                                                        />
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>)
                        }
                    </div>
                    <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
                    <div className="height-100">
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Supporting Notes</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner">
                                    <input
                                        className="form-input"
                                        placeholder={`Enter Supporting Notes`}
                                        value={supportingNotes}
                                        onChange={changeSupportingNotes}
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.supportingNotes}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="form-label-outside" htmlFor="general-information-skills">Select Project Start date</label><span className="text-danger"> *</span>
                                <div className="form-wrap-inner program-start-date-div">
                                    <DatePicker
                                        className="form-control brand-font p-3 placeholderColor add-meter-input"
                                        selected={startDateOfProgram}
                                        onChange={changeStartDateOfProgram}
                                        placeholderText="Select Project Start Date"
                                    />
                                </div>
                            </div>
                            <div className="text-danger error-msg">
                                {error.startDateOfProgram}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-margin-20 width-100">
                        <div className="col-md-12">
                            <div className="form-wrap program-candidate-material-table-div">
                                <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                                    <div className="col-md-10 sm_device"><i className={`${USERS_ICON}`}></i> Candidate Section</div>
                                </div>
                                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleCandidateExcelUpload}
                                    onClick = {(e) =>{ e.target.files[0] && handleCandidateExcelUpload(e)}}
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                />
                                <div className="form-button-div">
                                    {
                                        importLoading === true ? (
                                            <div className="ml-5">
                                                <div className="spinner-loader">
                                                </div>
                                            </div>
                                        ) : (<div className="import-candidate-button-div">
                                            <button className="button-secondary width-auto ml-5" onClick={importCandidateExcel}>Import Candidate&nbsp;&nbsp;&nbsp;<i className={EXPORT_ICON} /></button>
                                            <p className="selected-count-text">Selected Count: {candidateSelectedList.length}</p>
                                        </div>)
                                    }
                                </div>
                                <ThemeProvider theme={mytheme}>
                                    <MaterialTable
                                        columns={columns}
                                        data={candidateList.map((item) => ({
                                            firstName: item.first_name,
                                            lastName: item.last_name,
                                            fatherName: item.fathers_name,
                                            disaplayDobDate: new Date(item.date_of_birth).toLocaleDateString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }),
                                            dobDate: item.date_of_birth,
                                            ageNumber: item.age,
                                            mobileNumber: item.mobile_no,
                                            adharNo: item.adhaar_no,
                                            email: item.email,
                                            qualification: item.qualification,
                                            city: item.city,
                                            state: item.state,
                                            selected: item.selected,
                                            id: item._id,
                                            departmentSelected: item.department_selected,
                                            trainingPartner: item.training_partner,
                                            employerUnit: item.employer_unit,
                                            gender: item.gender,
                                            creationDate: new Date(item.created_date_time).toLocaleDateString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }),
                                        }))}

                                        options={{
                                            actionsColumnIndex: -1,
                                            headerStyle: { color: "#999998", fontWeight: "bolder", fontSize: 15 },
                                            sorting: true,
                                            columnsButton: true,
                                            exportButton: true,
                                            exportFileName: "Project Candidate",
                                        }}

                                        components={{
                                            Toolbar: (props) => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    {
                                                        candidateList && candidateList.length > 0 && candidateLoadingComplete === true && (
                                                            <ExcelExportComponent {...props} createProjectFlag={true} unitSplitData={unitSplitData} partnerSplitData={partnerSplitData} />
                                                        )
                                                    }
                                                </div>
                                            ),
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                            <div className="text-danger error-msg">
                                {error.selectedCandidate}
                            </div>
                        </div>
                    </div>
                    <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                        <div className="col-md-10 sm_device"><i className={`${STEPS_ICON}`}></i> Step Creation</div>
                    </div>
                    <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 0 }} />
                    <div className="step-add-div">
                        <div className="row">
                            <div className="col-md-5 col-xs-12" style={{ textAlign: "center" }}><label><b>Step List</b> </label></div>
                            <div className="col-md-3 col-xs-12" style={{ textAlign: "center" }}><label><b>Actions</b> </label></div>
                        </div>
                        {
                            selectedStep.map((stepItem, index) => {
                                let option = [];
                                if (stepItem.title.value) {
                                    option = { value: stepItem.title, label: stepItem.title };
                                }
                                else {
                                    option = { value: stepItem.title, label: stepItem.title };
                                }
                                return (
                                    <div key={`seletedStep-${index}`}>
                                        <div className="row mt-0" style={{ cursor: "pointer", paddingTop: "10px" }}
                                            title="Drag Step"
                                            onDragStart={(e) => dragStart(e, index)}
                                            onDragEnter={(e) => dragEnter(e, index)}
                                            onDragEnd={drop} key={index} draggable
                                        >
                                            <label className="margin-top-5">Step {index + 1}</label>
                                            <div className="col-md-5">
                                                <Select placeholder="select Step name" style={{ border: '1px solid lightgrey' }} value={option} options={stepListComponent} isSearchable={true} onChange={(e) => setStepTitleHandler(e, index)} />
                                            </div><span className="text-danger"> *</span>
                                            <div className="col-md-1">
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 20, cursor: "pointer" }} className={`${ARROW_DOWN_ICON} icon-style`} onClick={() => arrowDown(index)} title="Move step down" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 50, cursor: "pointer" }} className={`${ARROW_UP_ICON} icon-style`} onClick={() => arrowUp(index)} title="Move step up" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 80, cursor: "pointer" }} className={`${PLUS_ICON} icon-style`} onClick={() => addField()} title="Add new step" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 110, cursor: "pointer" }} className={`${TRASH_ICON} icon-style`} onClick={() => removeField(index)} title="Remove step" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 140, cursor: "pointer" }} className={`${ALIGNJUSTIFY_ICON} icon-style`} title="Drag step" aria-hidden="true"></i><br /><br />
                                            </div>
                                        </div>
                                        {
                                            stepItem.step_title === '' && (
                                                <div className="text-danger error-msg">
                                                    {this.state.error.title}
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                        <div className="text-danger error-msg">
                            {error.selectedStep}
                        </div>
                    </div>
                    <div className="form-button-div padding-top20">
                        {
                            createProgramBtnLoading === true ? (
                                <i className={`${LOADING_SPIN} brand-color`} />
                            ) : (
                                <button className="brand-button ml-5" onClick={validateProgram}>{programId ? "Update" : "Create"} </button>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* <ModalPopupProgram modelOpen={isModelOpen} closeModel={cancelHandle} candidateList = {candidateList} createProgram ={createProgram}/> */}
            <ImportErrorPopup modelOpen={isErrorModelOpen} closeModel={cancelHandle} candidateImportData = {candidateImportData}/>
        </>
    );
}
export default CreateProgramPage;