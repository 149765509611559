import { createUseStyles } from 'react-jss'
import config from "../../config.json";

export const styles = createUseStyles({
    outerLoginBg: {
        backgroundImage: `url(${config.BRAND_LOGIN_PAGE_BG})`
    },
    brandButton: {
        height: "100%",
        width: "100%",
        outline: "none",
        borderRadius: "5px",
        fontSize: "17px",
        transition: "all 0.3s ease",

        '& button': {
            paddingLeft: 0,
            background: config.BRAND_COLOR,
            color: "#fff",
            border: "none",
            fontSize: "20px",
            fontWeight: 500,
            cursor: "pointer",
            transition: "all 0.3s linear",
            textTransform: "uppercase",

            "&:hover": {
                background: "#4267B2"
            }
        }
    }
});