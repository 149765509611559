import React, { Component } from 'react';
import { INTERVAL, TYPE_FAIL, TYPE_SUCCESS, FIELD_TYPES, WIDGET_TYPES } from "assets/constants/Constants";
import {CALENDER_ICON,FILE_ICON} from 'assets/constants/Icons';
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import moment from 'moment';
import Select from 'react-select';
import {getFieldData , createFieldData, updateFieldData} from "lib/axiosUtils";

export class CreateFieldSchema extends Component {
    constructor(props) {
        super(props)

       const editData = props.location.state ? props.location.state : null;
        this.state = {
            isLoading: true,
            fieldId:editData === null ? 0 :editData.fieldId,
            fieldTitle: editData === null ? null :editData.fieldTitle,
            fieldArray: editData === null ? {} :(editData.fieldArray === null ? editData.customArray :editData.fieldArray),
            error: {},
            editData:editData,
            fieldArrayKey:editData === null ? ['name', 'title', 'type', 'help', 'options', 'required', 'minimum', 'maximum', 'readonly', 'inline','widget', 'format'] :[],
            fieldArrayValue:editData === null ? [null, null, null, null, null, false, null, null, false, false, null, null] :[],
            fieldData: [],
            editFieldName: true
        }
    }

    async componentDidMount() {
        let fieldData = await getFieldData({},{});
        let tempFieldData = [];
        fieldData.data.result.forEach((item) =>{
            if(item.field_array){
                tempFieldData.push({ fieldName: item.field_array.name, fieldId: item._id })
            }
        })
        this.setState({
            fieldData:tempFieldData
        });
        let mgrFields =[];
        let customFields = [];
        if(this.state.editData !== null){
            let fieldArrayKey = this.state.editData.fieldArray !== null ? Object.keys(this.state.editData.fieldArray) :Object.keys(this.state.editData.customArray);
            let fieldArrayValue = this.state.editData.fieldArray !== null ? Object.values(this.state.editData.fieldArray) :Object.values(this.state.editData.customArray);
            if(!fieldArrayKey.find((item) => { return item === 'format' })){
                fieldArrayKey.push('format');
                fieldArrayValue.push(null);
            }
            this.setState({
                fieldArrayKey :fieldArrayKey,
                fieldArrayValue: fieldArrayValue,
            });
        }
        // walnutSchema.fieldArray.map((item) => {
        //     mgrFields.push(item.name);
        //     return mgrFields;
        // });
        // customField.data.fieldArray.map((item) => {
        //     customFields.push(item.customArray.name);
        //     return customFields;
        // });
        if (this.state.fieldId !== null && this.state.fieldId !== 0){
            this.setState({editFieldName : false});
        }
        this.setState({
            isLoading: false,
            mgrFieldList:mgrFields,
            customFieldList: customFields
        });
    }

    validate = () => {
        let isValid = true;
        let error = {};
        this.state.fieldArrayKey.map((item, index) => {
            if (item === 'name' || item === 'type' || item ==='title') {
                if(this.state.fieldArrayValue[index] === undefined || this.state.fieldArrayValue[index] === '' || this.state.fieldArrayValue[index] === null){
                    isValid = false;
                }
            }
            return isValid;
        });

        this.setState({
            error: error
        });

        return isValid;
    }

    addFieldSchemaHandler = async (fieldId, fieldArray) => {
        if (this.validate()) {
            if(window.confirm("Are you sure you want to submit field schema")){
                let requestParameter = {};
                if (this.state.editData !== null) {
                    if (this.state.editData.fieldArray !== null) {
                        requestParameter = {
                            fieldID: fieldId,
                            fieldTitle: fieldArray.title,
                            fieldArray: fieldArray
                        }
                    }
                    else {
                        requestParameter = {
                            fieldID: fieldId,
                            fieldTitle: fieldArray.title,
                        }
                    }
                }
                else {
                    requestParameter = {
                        fieldID: 0,
                        fieldTitle: fieldArray.title,
                        fieldArray: fieldArray
                    }
                }
                if (requestParameter.fieldID === 0) {
                    let res = await createFieldData({},requestParameter);
                    console.log("res", res);
                    ShowToast('Field Schema created successfully', TYPE_SUCCESS, INTERVAL, this.props, "/field-schema-page");
                }
                else {
                    let res = await updateFieldData({},requestParameter,fieldId);
                    console.log("res", res);
                    ShowToast('Field Schema updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "/field-schema-page");
                }
            }
        }
        else {
            ShowToast('Please enter mandatory fields.', TYPE_FAIL, INTERVAL, this.props, "");
        }
    }

    submitData = async () => {
        var array1 = this.state.fieldArrayKey;
        var array2 = this.state.fieldArrayValue;
        var result = {};
        for (var i = 0; i < array1.length; i++){
            if(array2[i] !== null){
                result[array1[i]] = array2[i];
            }
        }
        // if(result['type'] === 'file' && (result['file_format']=== undefined || result['file_format']=== '')){
        //     result['file_format']='.pdf, .png, .jpg';
        // }
        await this.addFieldSchemaHandler(this.state.fieldId, result);
    }

    cancelHandler = (event) => {
        this.props.history.push("/field-schema-page");
    }

    valueChangeHandler = (e,index)=>{
        const formDataArray = [...this.state.fieldArrayValue];
        formDataArray[index] = e.target ? e.target.value : e.value;
        this.setState({
            fieldArrayValue: formDataArray
        });
    }

    enumArrayCreation = (e, index) => {
        let str = e.target.value;
        let array = str.split(",");
        let enumArray =[];
        for(let i=0;i<array.length; i++){
            enumArray.push(array[i].trim());
        }
        const formDataArray = [...this.state.fieldArrayValue];
        formDataArray[index] = enumArray;
        this.setState({
            fieldArrayValue: formDataArray
        });
    }

    isValidField = (e, index) => {
        if (this.state.fieldArrayKey[index] === 'name') {
            const trimValue = e.target.value.trim();
            let fieldPresent = this.state.fieldData.find((findItem) => findItem.fieldName === trimValue && findItem.fieldId !== this.state.fieldId);
            if (fieldPresent) {
                ShowToast("Field '" + trimValue+ "' is already present, Please enter different field", TYPE_FAIL, INTERVAL, this.props, "");
                let fieldArrayValue = this.state.fieldArrayValue;
                fieldArrayValue[index] = '';
                this.setState({
                    fieldArrayValue: fieldArrayValue
                })
            }
        }
    }
    
    widgetChangeHandler = (e,index)=> {
        const formDataArray = [...this.state.fieldArrayValue];
        formDataArray[index] = e ? e.value : '';
        this.setState({
            fieldArrayValue: formDataArray,
        });
    }

    handleClassChange = (event, index) => {
        let state= this.state.fieldArrayValue[index];
        if(state === undefined){
            state =false;
        }
        state = !state;
        const formDataArray = [...this.state.fieldArrayValue];
        formDataArray[index] = state;
        this.setState({
            fieldArrayValue: formDataArray
        });
    }

    fieldTypeOption = () => {
        let options = [];
        options = [
            {
                label: "Field Type",
                options: FIELD_TYPES
            }
        ];
        return options;
    }

    widgetTypeOption = (fieldOption) => {
        let options = [];
        WIDGET_TYPES.map((item) => {
            if(fieldOption.value === 'string'){
                if(item.value === 'textarea'){
                    options.push(item);
                }
            }
            if(fieldOption.value === 'integer'){
                if(item.value === 'updown' || item.value === 'range'){
                    options.push(item);
                }
            }
            if(fieldOption.value === 'boolean'){
                if(item.value === 'radio' || item.value === 'select'){
                    options.push(item);
                }
            }
            if(fieldOption.value === 'dropdown'){
                if(item.value === 'radio' || item.value === 'select' || item.value === 'checkboxes'){
                    options.push(item);
                }
            }
            return false;
        })
        // options = [
        //     {
        //         label: "Widget Type",
        //         options: WIDGET_TYPES
        //     }
        // ];
        return options;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            let option = [];
            let widget_option =[];
            return (
                <div className="main-content">
                    <div className="pagetitle row" style={{color:'black', paddingTop:15}}>
                        <div className ="col-md-10 sm_device"><i className={`${FILE_ICON}`}></i> Create New Field</div>
                        <div className ="col-md-2 sm_device" style={{textAlign:'right'}}><i className={`${CALENDER_ICON}`}></i> {moment().format("DD MMM YYYY")}</div>
                    </div>
                    <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop:15 }} />
                    <div className="list-wrapper">
                        <div className="mt-4 border list-table-div" style={{ marginBottom: 50, paddingTop:20 }}>
                            <div className="App">
                                <div>
                                    <h4 className="pagetitle"><b> {this.state.editData === null ? 'Create New Field' : 'Edit Field'}</b></h4><br />
                                    {
                                        this.state.fieldArrayKey.map((item, index) => {
                                            if(item === 'type' && this.state.fieldArrayValue[index]){
                                                option = FIELD_TYPES.find((element) => { return element.value === this.state.fieldArrayValue[index] });
                                            }
                                            if (item === 'widget' && this.state.fieldArrayValue[index]) {
                                                widget_option = WIDGET_TYPES.find((element) => { return element.value === this.state.fieldArrayValue[index] });
                                            }
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-3">
                                                        {
                                                            item ==='name' || item ==='type' || item ==='title' ? (
                                                                <label className="form-attribute">{item} <span className="text-danger">*</span></label>
                                                            ):(
                                                                item === 'widget' ? (
                                                                    <>
                                                                    <label className="form-attribute">{item}</label>
                                                                    <p style={{ fontWeight: 'normal', fontSize:10, fontStyle: 'italic', color: 'grey' }}>(Please select widget according to Field type, eg.1] Number : Range/Updown 2] Yes/No : Radio/Select 3] String: Textarea 4] Dropdown: Radio/Select/checkboxes)</p>
                                                                    </>
                                                                ):(
                                                                    item === 'options' ? (
                                                                        <>
                                                                        <label className="form-attribute">{item}</label>
                                                                        <p style={{ fontWeight: 'normal', fontSize:10, fontStyle: 'italic', color: 'grey' }}>(Please enter comma separated options)</p>
                                                                        </>
                                                                    ):(
                                                                        item === 'inline' ? (
                                                                            <>
                                                                            <label className="form-attribute">{item}</label>
                                                                            <p style={{ fontWeight: 'normal', fontSize:10, fontStyle: 'italic', color: 'grey' }}>(If you want to display all option on one line then please select this option)</p>
                                                                            </>
                                                                        ):(
                                                                            item === 'file_format' ? (
                                                                                <>
                                                                                <label className="form-attribute">File Format</label>
                                                                                <p style={{ fontWeight: 'normal', fontSize:10, fontStyle: 'italic', color: 'grey' }}>(Please enter comma separated file format eg .pdf, .jpg, .png)</p>
                                                                                </>
                                                                            ):(
                                                                                item === 'help' ? (
                                                                                    <>
                                                                                    <label className="form-attribute">Help Text</label>
                                                                                    <p style={{ fontWeight: 'normal', fontSize:10, fontStyle: 'italic', color: 'grey' }}>(Please enter help text for your field)</p>
                                                                                    </>
                                                                                ):(
                                                                                    item === 'format' ? (
                                                                                        <>
                                                                                        <label className="form-attribute">{item}</label>
                                                                                        <p style={{ fontWeight: 'normal', fontSize:10, fontStyle: 'italic', color: 'grey', textTransform:'none' }}>(Please select format according to Field type, eg. 1] String : date/time/date-time)</p>
                                                                                        </>
                                                                                    ):(
                                                                                        <label className="form-attribute">{item}</label>
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-md-4">
                                                        {
                                                            item === 'required' || item === 'readonly' || item === 'inline' ? (
                                                                <label className="switch">
                                                                    <input type="checkbox" id="isReplicationIdeaBtn" checked={this.state.fieldArrayValue[index]} value={this.state.fieldArrayValue[index] ? this.state.fieldArrayValue[index] : ""  } onChange={(e) => this.handleClassChange(e,index)} />
                                                                    <span className="slider round" />
                                                                </label>
                                                            ):(
                                                                item !== 'name' && item !== 'type' && item !== 'widget' && item !== 'options' ? (
                                                                    <input className ="col-md-12 form-input" value={this.state.fieldArrayValue[index] === true ? 'True' : (this.state.fieldArrayValue[index] === false ? 'false': this.state.fieldArrayValue[index] ? this.state.fieldArrayValue[index] : "")} onChange={ (e) => this.valueChangeHandler(e,index) } placeholder={item}/>
                                                                ):(
                                                                    item === 'type' ? (
                                                                        <Select placeholder={<div style={{textTransform:'none', fontStyle:'italic', color:'darkgray'}}>type</div>} value={option} isSearchable={true} options={this.fieldTypeOption()} onChange={(e) => this.valueChangeHandler(e, index)}/>
                                                                        // <input className ="col-md-12 form-input" value={this.state.fieldArrayValue[index] === true ? 'True' : (this.state.fieldArrayValue[index] === false ? 'false': this.state.fieldArrayValue[index])} onChange={ (e) => this.valueChangeHandler(e,index) } placeholder={item}/>
                                                                    ):(
                                                                        item === 'name' ? (
                                                                            <input className="col-md-12 form-input" value={this.state.fieldArrayValue[index] === true ? 'True' : (this.state.fieldArrayValue[index] === false ? 'false' : this.state.fieldArrayValue[index] ? this.state.fieldArrayValue[index] : "")} onChange={(e) => this.valueChangeHandler(e, index)} placeholder={item} onBlur={(e) => this.isValidField(e, index)} disabled={this.state.editFieldName ? false : true} />
                                                                        ):(
                                                                            item === 'widget' ? (
                                                                                <Select isClearable placeholder={<div style={{ textTransform: 'none', fontStyle: 'italic', color: 'darkgray' }}>widget</div>} value={widget_option} isSearchable={true} options={this.widgetTypeOption(option)} onChange={(e) => this.widgetChangeHandler(e, index)} />
                                                                            ):(
                                                                                item === 'options' ? (
                                                                                    <textarea rows={4} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey', padding:'2px' }} value={this.state.fieldArrayValue[index] === true ? 'True' : (this.state.fieldArrayValue[index] === false ? 'false': this.state.fieldArrayValue[index] ? this.state.fieldArrayValue[index]  : "")} onChange={ (e) => this.valueChangeHandler(e,index) } placeholder={item} onBlur={(e) => this.enumArrayCreation(e,index)} />
                                                                                    // <input className ="col-md-12 form-input" value={this.state.fieldArrayValue[index] === true ? 'True' : (this.state.fieldArrayValue[index] === false ? 'false': this.state.fieldArrayValue[index] ? this.state.fieldArrayValue[index]  : "")} onChange={ (e) => this.valueChangeHandler(e,index) } placeholder={item} onBlur={(e) => this.enumArrayCreation(e,index)}/>
                                                                                ):(
                                                                                    // <Select placeholder={<div className="form-input">type</div>} style={{ border: '1px solid lightgrey' }} value={option} isSearchable={true} options={this.fieldTypeOption()} onChange={(e) => this.valueChangeHandler(e, index)}/>
                                                                                    <input className ="col-md-12 form-input disableLabel" value={this.state.fieldArrayValue[index] === true ? 'True' : (this.state.fieldArrayValue[index] === false ? 'false': this.state.fieldArrayValue[index])}/>
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick={this.submitData}>Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CreateFieldSchema
