import React, { useEffect, useState } from "react";
import { METER_ICON } from "../../assets/constants/Icons";
import Spinner from 'components/Spinner';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from 'moment';
import { getPartnerTotalCall } from "lib/axiosUtils";

const PartnerDashboardPage = (props) => {
    const [totalPartnerData, setTotalPartnerData] = useState({});
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const userDetail = JSON.parse(localStorage.getItem("userData"));
    const [partnerId, setPartnerId] = useState(userDetail && userDetail.partner_id ? userDetail.partner_id : props && props.partnerId ? props.partnerId : props && props.location && props.location.state ? props.location.state : "");

    useEffect(() => {
        const fetchPartnerTotal = async () => {
            try {
                const data = await getPartnerTotalCall({},{},partnerId);
                setTotalPartnerData(data.data.result);
                setLoadingComplete(true);
            }
            catch (error) {
                console.log("Error while fetching data", error);
                setLoadingComplete(true);
            }
        }
        fetchPartnerTotal();
    }, [partnerId])

    useEffect(() => {
        if (props && props.partnerId) {
            setPartnerId(props.partnerId);
            setLoadingComplete(false);
        }
    }, [props])

    const exportToExcel = async () => {
        setDownloadLoading(true);
        const object = {
            ...totalPartnerData
        }
        const data = [object]
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        const columns = [
            { header: "Name of TP Company", key: "partnerName" },
            { header: "Name of TP Unit", key: "trainingPartner" },
            { header: "City", key: "city" },
        ];

        data.forEach((row) => {
            const dataRow = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    dataRow.push("Partner Details");
                }
                else {
                    dataRow.push("");
                }
            });
            worksheet.addRow(dataRow).font = { bold: true };
        })

        const mainHeaderRow = worksheet.addRow(columns.map((column) => column.header));
        mainHeaderRow.font = { bold: true };
        mainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };

        data.forEach((row) => {
            const dataRow = [];
            columns.forEach((column) => {
                dataRow.push(row[column.key]);
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };
        })
        worksheet.addRow("");
        worksheet.addRow("");
        data.forEach((row) => {
            const dataRow = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    dataRow.push("TOTALS");
                }
                else {
                    dataRow.push("");
                }
            });
            worksheet.addRow(dataRow).font = { bold: true };
        })

        const thsccolumns = [
            { header: "Total Project", key: "totalProject" },
            { header: "Total Active Project", key: "activeProject" },
            { header: "Total Project Complete", key: "completeProject" },
            { header: "Total Candidate Expected", key: "candidateExpected" },
            { header: "Total Candidate Assigned", key: "totalCandidateAssigned" },
            { header: "Total Candidate Delivered", key: "candidatesDelivered" },
            { header: "Total Candidate Dropout", key: "candidateDropout" },
        ]
        const thscmainHeaderRow = worksheet.addRow(thsccolumns.map((column) => column.header));
        thscmainHeaderRow.font = { bold: true };
        thscmainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };
        data.forEach((row) => {
            const dataRow = [];
            thsccolumns.forEach((column) => {
                dataRow.push(row[column.key]);
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };;
        })
        worksheet.addRow("");
        worksheet.addRow("");
        const headerCell = worksheet.getCell(1, 1);
        headerCell.alignment = { vertical: "middle", horizontal: "center" };
        headerCell.font = { bold: true };
        headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff285c60" }
        };
        const headerCellTHSC = worksheet.getCell(6, 1);
        headerCellTHSC.alignment = { vertical: "middle", horizontal: "center" };
        headerCellTHSC.font = { bold: true };
        headerCellTHSC.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };

        worksheet.columns.forEach(column => {
            column.eachCell(cell => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const todayDate = moment(Date.now()).format("DD_MM_YYYY-HH_mm");
        let fileName = `${totalPartnerData.trainingPartner} ${todayDate}.xlsx`;
        saveAs(new Blob([buffer]), fileName);
        setDownloadLoading(false);
    }
    return (<>
        {
            loadingComplete === false ? (
                <Spinner />
            ) : (
                <div className="admin-dashboard-outer-wrapper employer-dashboard-outer-wrapper">
                    <div className="admin-dasboard-icon">
                        <h4><i className={`${METER_ICON} list-table-icon`}></i>{totalPartnerData.trainingPartner} Dashboard</h4>
                        {
                            downloadLoading === true ? (
                                <div className="spinner-loader"></div>
                            ) : (
                                <button className="brand-button" onClick={exportToExcel}>Download</button>
                            )
                        }
                    </div>
                    <div className="admin-dashboard-outer-div employer-dashboard-outer-div">
                        <div className="admin-dashboad-total-div employer-dashboad-total-div">
                            <div className="total-title-div total-div-background-color">
                                <div className="total-title-header-div">
                                    <p>Partner Details</p>
                                </div>
                            </div>
                            <div className="total-value-outer-div employer-total-value-outer-div">
                                <div className="employer-total-inner-div">
                                    <p className="employer-total-inner-header">Name of TP Company : </p>
                                    <p className="employer-total-value">{totalPartnerData.partnerName}</p>
                                </div>
                                <div className="employer-total-inner-div">
                                    <p className="employer-total-inner-header">Name of TP Unit : </p>
                                    <p className="employer-total-value">{totalPartnerData.trainingPartner}</p>
                                </div>
                                <div className="employer-total-inner-div">
                                    <p className="employer-total-inner-header">City : </p>
                                    <p className="employer-total-value">{totalPartnerData.city}</p>
                                </div>
                            </div>
                        </div>
                        <div className="seprator-line"></div>
                        <div className="admin-dashboad-total-div employer-dashboad-total-div">
                            <div className="total-title-div">
                                <div className="total-title-header-div">
                                    <p>Total</p>
                                </div>
                            </div>
                            <div className="total-value-outer-div">
                                <div className="total-value-inner-div">
                                    <div className="total-value-div">
                                        <p>Total Projects</p>
                                        <div className="total-value-box">
                                            {totalPartnerData.totalProject}
                                        </div>
                                    </div>
                                    <div className="total-value-div">
                                        <p>Candidates Expected</p>
                                        <div className="total-value-box">
                                            {totalPartnerData.candidateExpected}
                                        </div>
                                    </div>
                                    <div className="total-value-div">
                                        <p>Candidates Dropout</p>
                                        <div className="total-value-box">
                                            {totalPartnerData.candidateDropout}
                                        </div>
                                    </div>
                                </div>
                                <div className="total-value-inner-div">
                                    <div className="total-value-div">
                                        <p>Active Projects</p>
                                        <div className="total-value-box">
                                            {totalPartnerData.activeProject}
                                        </div>
                                    </div>
                                    <div className="total-value-div">
                                        <p>Candidates Assigned</p>
                                        <div className="total-value-box">
                                            {totalPartnerData.totalCandidateAssigned}
                                        </div>
                                    </div>
                                    <div className="total-value-div"></div>
                                </div>
                                <div className="total-value-inner-div">
                                    <div className="total-value-div">
                                        <p>Complete Projects</p>
                                        <div className="total-value-box">
                                            {totalPartnerData.completeProject}
                                        </div>
                                    </div>
                                    <div className="total-value-div">
                                        <p>Candidates Delivered</p>
                                        <div className="total-value-box">
                                            {totalPartnerData.candidatesDelivered}
                                        </div>
                                    </div>
                                    <div className="total-value-div"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    </>);
}
export default PartnerDashboardPage;