import Toast from 'light-toast';
import { TYPE_SUCCESS, TYPE_FAIL, TYPE_INFO} from 'assets/constants/Constants';

const ShowToast = (message, type, interval, props = null, pathname="") => {
    if(type === TYPE_SUCCESS){
        return (
            Toast.success(message, interval, () => {
                if(props){
                    props.history.push({
                        pathname: pathname
                    });
                }
            })
        );
    }
    else if(type === TYPE_FAIL){
        return (
            Toast.fail(message, interval, () => {
                if(props){
                    props.history.push({
                        pathname: pathname
                    });
                }
            })
        );
    }
    else if(type === TYPE_INFO){
        return (
            Toast.info(message, interval, () => {
                if(props){
                    props.history.push({
                        pathname: pathname
                    });
                }
            })
        );
    }
};

export default ShowToast;