import React, { Component } from 'react'
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { EDIT_ICON, TRASH_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import { hasPermission } from 'components/Permission';
import { getRoleListCall, createRoleCall, updateRoleCall, deleteRole } from "lib/axiosUtils";

export class CreateRole extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            role: "",
            roleId: null,
            buttonText: "Add Role",
            roleList: [],
            error: {}
        }
    }

    async componentDidMount() {
        const roleList = await getRoleListCall({},{});
        this.setState({
            isLoading: false,
            roleList: roleList.data.result
        });
    }

    roleChange = (event) => {
        this.setState({
            role: event.target.value
        });
    }

    isFormValid = () => {
        let isValid = true;
        let error = {}
        if (this.state.role === "") {
            isValid = false;
            error.role = "Please add role";
        }

        this.setState({
            error
        });

        return isValid;
    }

    addRoleHandler = async (event) => {
        if (this.isFormValid()) {
            this.setState({ isLoading: true });
            const requestData = {
                roleName: this.state.role
            }

            let roleResponse = null;
            if (this.state.roleId === null) {
                roleResponse = await createRoleCall({},requestData);
            }
            else {
                roleResponse = await updateRoleCall({},requestData, this.state.roleId);
            }
            if (roleResponse.statusCode === 200) {
                const roleList = await getRoleListCall({},{});
                const tempRoleId = this.state.roleId;
                this.setState({
                    role: "",
                    roleId: null,
                    roleList: roleList.data.result,
                    buttonText: "Add Role",
                    isLoading: false
                }, () => {
                    if (tempRoleId === null) {
                        ShowToast('Role created Successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                    }
                    else {
                        ShowToast('Role updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                    }
                });
            }
        }
    }

    handleEdit = (item) => {
        this.setState({
            roleId: item._id,
            role: item.user_role,
            buttonText: "Update Role"
        });
    }

    handleDelete = async (item) => {
        if (window.confirm("Are you sure you want to delete this role?")) {
            this.setState({ isLoading: true });
            let deleteResponse = await deleteRole({},{},item._id);
            if (deleteResponse.statusCode === 200) {
                const roleList = await getRoleListCall({},{});
                this.setState({
                    role: "",
                    roleId: null,
                    roleList: roleList.data.result,
                    buttonText: "Add Role",
                    isLoading: false
                })
                ShowToast('Role deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
            }
            else {
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
            }
        }
    }

    render() {
        return (
            <div className="main-content">
                <div className="back">
                    <ul className="breadcrumb1">
                        <li>Roles</li>
                    </ul>
                </div>
                <div className="role-wrapper">
                    {
                        hasPermission("role", "create") && (
                            <div className="input-div">
                                <div>
                                    <input className="form-control brand-font p-3 placeholderColor add-role-input" value={this.state.role} onChange={this.roleChange} placeholder="Enter Role" />
                                    <div className="text-danger error-msg">
                                        {this.state.error.role}
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                                <button className="brand-button ml-5 role-button" onClick={this.addRoleHandler}>{this.state.buttonText}</button>
                            </div>
                        )
                    }

                    {
                        this.state.isLoading ? (
                            <Spinner />
                        ) : (
                            <div className="mt-5 mb-5 border user-table-div">
                                {
                                    this.state.roleList.length > 0 ? (
                                        <table className="table list-table role-table">
                                            <thead>
                                                <tr>
                                                    <th className="pl1">Role</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="role-list-table">
                                                {
                                                    this.state.roleList.map((roleItem, roleIndex) => {
                                                        return (
                                                            <tr key={roleItem._id}>
                                                                <td className="role-td pl1">
                                                                    {roleItem.user_role}
                                                                </td>
                                                                <td className={"action-td center"}>
                                                                    <div className="action-div">
                                                                    {
                                                                        hasPermission("role", "update") && (
                                                                            <span onClick={() => this.handleEdit(roleItem)}><i className={`${EDIT_ICON}`} title="Edit Role"></i></span>
                                                                        )
                                                                    }
                                                                        <span onClick={() => this.handleDelete(roleItem)}><i className={`${TRASH_ICON}`} title="Delete Role"></i></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="text-center pt-2">
                                            <label>Role records not found!</label>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default CreateRole