export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const WEB_APP_URL = process.env.REACT_APP_WEB_URL;
export const SECRET_KEY = "ROCK-N-ROLL";
export const PAGE_SIZE = 25;
export const TYPE_SUCCESS = "success";
export const TYPE_INFO = "info";
export const TYPE_FAIL = "fail";
export const READER = "reader";
export const ADMIN = "admin";
export const SUPER_ADMIN = "super admin";

export const INTERVAL = 3000;

export const emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
export const PIN_CODE_PATTERN = new RegExp(
    /^[1-9][0-9]{5}$/
);
export const PHONE_NUMBER_PATTERN = new RegExp(
    /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/
);
export const passwordMatchPattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
export const FIELD_TYPES =  [ { value: 'string', label: 'String' }, { value: 'boolean', label: 'Yes/No' }, { value: 'integer', label: 'Number' }, { value:'dropdown', label:'Dropdown'}, { value:'file', label:'File'} ];
export const WIDGET_TYPES =  [ { value: 'radio', label: 'Radio' }, { value: 'select', label: 'Select' }, {value:'checkboxes', label:'Checkboxes'}, { value: 'textarea', label: 'Textarea' }, { value: 'updown', label: 'Updown' }, { value: 'range', label: 'Range' } ];
export const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}$/;
export const DATE_FORMAT_IMPORT = /^\d{2}\/\d{2}\/\d{4}$/; // Regular expression for "DD/MM/YYYY" format
export const CIN_NUMBER_PATTERN = new RegExp(
    /^([a-zA-Z]){1}([0-9]){5}([a-zA-Z]){2}([0-9]){4}([a-zA-Z]){3}([0-9]){6}$/
    );
    export const GSTIN_NUMBER_PATTERN = new RegExp(
    /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([a-zA-Z0-9]){1}$/
    );
    export const PAN_NUMBER_PATTERN = new RegExp(
        /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    );

export const DOCUMENTATION_STEP_ID = "64abbba78d35939530446e7d";
export const SAMPLE_TRACKING_EXCEL = "https://test-skills-project.s3.amazonaws.com/access-public-files/Sample-Project-tracking.xlsx";
export const SAMPLE_CANDIDATE_EXCEL = "https://test-skills-project.s3.amazonaws.com/access-public-files/Sample_candidate_import.xlsx";
export const TIME_FORMAT_TWENTYFOUR = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
export const TIME_FORMAT_AMPM = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
export const REFRESH_TOKEN_THRESHOLD = 3600; // Refresh if there are less than 1 hr remaining in sec
export const TJP_URL = process.env.REACT_APP_TJPLOGIN_URL;
export const INTERVIEWS_STEPS = ["Pre-Employer Interview", "Employer Interview 1 - HR", "Employer Interview 2 - Ops"];
export const INTERVIEWS_FINALPLACEMENT_STEP_ID = ["64aba3c3cad087f1334654c9", "64aba5efcad087f1334654ef", "64aba886cad087f133465522", "64abed6f8d359395304470fe"];
export const FINANCE_MONTHS = [
    { value: "all", label: "All Months" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" }
];