import Form from "@rjsf/material-ui";
import React, { Component } from 'react';
import { INTERVAL, TYPE_SUCCESS } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';
import validator from "@rjsf/validator-ajv6";
import { PLUS_ICON, ARROW_DOWN_ICON, ARROW_UP_ICON, TRASH_ICON, FILE_ICON, ALIGNJUSTIFY_ICON } from "assets/constants/Icons";
import Select from 'react-select';
import Toast from 'light-toast';
import ShowToast from 'components/ShowToast';
import moment from 'moment';
import { getFieldData, createStep, updateStep } from "lib/axiosUtils";

let fieldSchema = [];
export class CreateStepPage extends Component {
    constructor(props) {
        super(props)

        const editData = props.location.state ? props.location.state : null;
        this.state = {
            isLoading: true,
            stepId: editData === null ? null : editData.stepId,
            stepTitle: editData === null ? null : editData.stepTitle,
            shortDescription: editData === null ? null : editData.shortDescription,
            formData: [{ title: "" }],
            error: {},
            flag: false,
            saveFlag: false,
            formSchema: {},
            uiSchema: {},
            editData: editData,
            formSchemaOldFields: editData === null ? null : editData.formSchema.properties,
            dragItem: null,
            dragOverItem: null,
            fieldArray: [],
            phasesNumbers: [
                { label: "Phase 1", value: "Phase 1"},
                { label: "Phase 2", value: "Phase 2"},
                { label: "Phase 3", value: "Phase 3"},
                { label: "Phase 4", value: "Phase 4"},
                { label: "Phase 5", value: "Phase 5"},
                { label: "Phase 6", value: "Phase 6"}
            ],
            selectedPhase: editData === null ? null : {label: editData.phase, value: editData.phase },
            selectedPhaseValue: editData === null ? null : editData.phase,
            fieldOptionsComponent: [],
            sendNotification: editData=== null ? null : editData.sendNotification,
            sendNotificationEmailTo : editData === null ? "" :editData.sendNotificationEmailTo
        }
    }

    async componentDidMount() {
        const stepField = await getFieldData({},{});
        if (this.state.formSchemaOldFields !== null) {
            let formDataArray = [];
            let data = Object.keys(this.state.formSchemaOldFields);
            data.map((item) => {
                formDataArray.push({ "title": item })
                return true;
            });
            this.setState({
                formData: formDataArray,
            });
        }
        this.setState({
            classList: [],
            stepFieldList: stepField.data.result,
            isLoading: false
        }, () => {
            this.setState({
                fieldOptionsComponent:this.customOptions()
            })
        });
    }

    setStepTitle = (value) => {
        this.setState({
            stepTitle: value
        })
    }

    setShortDescription = (value) => {
        this.setState({
            shortDescription: value
        })
    }

    setStepTitleHandler = (value, index) => {
        const formDataArray = [...this.state.formData];
        formDataArray[index].title = value.value;
        this.setState({
            formData: formDataArray
        },() =>{
           this.setFieldOptionsComponent();
        });
    }

    addField = () => {
        if (this.validate()) {
            const newFormData = [...this.state.formData];
            newFormData.push({ title: "" });
            this.setState({
                formData: newFormData
            });
        }
        else {
            Toast.fail('Some of the fields are not validated. Please check and then add new field.', 3000, () => { });
        }
    }

    removeField = (deleteIndex) => {
        if (deleteIndex === 0 && this.state.formData.length === 1) {
            window.alert("Minimum one field is required")
        }
        else {
            (window.confirm("Are you sure you want delete this field?"))
            const newFormData = [...this.state.formData];
            let arrayData = [];
            newFormData.map((item, index) => {
                if (index !== deleteIndex) {
                    arrayData.push(item);
                }
                return false;
            });
            this.setState({
                formData: arrayData
            },() =>{
                this.setFieldOptionsComponent();
            });

        }
    }

    setFieldOptionsComponent = () => {
        let result = this.state.stepFieldList.filter(item1 => !this.state.formData.some(item2 => item2.title === item1.field_array.name));
        result = result.map((item) => {
            const retunObject = {
                value: item.field_array.name,
                label: item.field_array.title
            }
            return retunObject
        })
        this.setState({
            fieldOptionsComponent: result
        })
    }

    validate = () => {
        let isValid = true;
        let error = {};

        if (this.state.stepTitle === null) {
            isValid = false;
            error.stepTitle = "Please enter form title";
        }

        if (this.state.shortDescription === null) {
            isValid = false;
            error.shortDescription = "Please enter form description";
        }

        this.state.formData.map((item) => {
            if (item.title === '') {
                isValid = false;
                error.title = "Please fill all fields";
            }

            return isValid;
        });

        if (this.state.selectedPhaseValue === null) {
            isValid = false;
            error.phaseError = "Please select phase";
        }

        if (this.state.sendNotification) {
            if (!this.state.sendNotificationEmailTo) {
                isValid = false;
                error.sendNotificationEmailTo = "Please Enter the Notification Email"
            }
        }

        this.setState({
            error: error
        });

        return isValid;
    }

    //fetch custom field from field schema page DB
    customOptions = () => {
        let customOptions = [];
        fieldSchema = [];

        this.state.stepFieldList.map((item) => {
            customOptions.push({
                value: item.field_array.name,
                label: item.field_array.title
            });
            fieldSchema.push({
                name: item.field_array.name,
                title: item.field_array.title,
                type: item.field_array.type,
                required: item.field_array.required,
                minimum: item.field_array.minimum,
                maximum: item.field_array.maximum,
                readonly: item.field_array.readonly,
                widget: item.field_array.widget,
                format: item.field_array.format,
                enum: item.field_array.options,
                inline: item.field_array.inline,
                accept: item.field_array.file_format,
                help: item.field_array.help
            });
            return customOptions;
        });

        return customOptions;
    }

    fieldTitleOption = () => {
        let options = [];
        options = this.customOptions();
        return options;
    }

    addFormHandler = async (stepId, stepTitle, formSchema, uiSchema) => {
        if (window.confirm("Are you sure you want submit data?")) {
            const requestParameter = {
                stepId: stepId,
                stepTitle: stepTitle,
                shortDescription: this.state.shortDescription,
                formSchema: formSchema,
                uiSchema: uiSchema,
                phase: this.state.selectedPhaseValue,
                sendNotification: this.state.sendNotification,
                sendNotificationEmailTo : this.state.sendNotificationEmailTo
            }
            try {
                if (requestParameter.stepId === null) {
                    await createStep({}, requestParameter);
                    ShowToast('Step created successfully', TYPE_SUCCESS, INTERVAL, this.props, "/step-list-page");
                }
                else {
                    await updateStep({}, requestParameter)
                    ShowToast('Step updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "/step-list-page");
                }
            }
            catch (error) {
                console.log(`Error occurred while ${requestParameter.stepId ? "Updating" : "Creating"} the Step`, error);
            }
        }
    }

    getDivision = (division) => {
        let options = [];
        division.map((divItem) => {
            options.push({
                "const": divItem.DivisionID,
                "title": divItem.DivisionName
            });
            return options;
        })
        return options;
    }

    fieldHandler = (item, schemaItem, index, createSchema, uiSchema, obj, obj1) => {
        if (schemaItem.name === item.title.value || schemaItem.name === item.title) {
            if (schemaItem.oneOf) {
                obj = {
                    title: schemaItem.title,
                    type: schemaItem.type,
                    maxLength: schemaItem.maxLength,
                    minLength: schemaItem.minLength,
                    format: schemaItem.format,
                    minimum: schemaItem.minimum,
                    maximum: schemaItem.maximum,
                    enum: schemaItem.enum,
                    oneOf: schemaItem.oneOf
                }
            }
            else if (schemaItem.allOf) {
                obj = {
                    title: schemaItem.title,
                    enum: schemaItem.enum
                }
            }
            else if (schemaItem.enum) {
                if (schemaItem.type === 'dropdown' && (schemaItem.widget === 'radio' || schemaItem.widget === 'select')) {
                    obj = {
                        title: schemaItem.title,
                        type: 'string',
                        format: schemaItem.format,
                        enum: schemaItem.enum
                    }
                }
                else if (schemaItem.type === 'dropdown' && schemaItem.widget === 'checkboxes') {
                    obj = {
                        title: schemaItem.title,
                        type: "array",
                        format: schemaItem.format,
                        items: { type: "string", enum: schemaItem.enum },
                        uniqueItems: true
                    }
                }
                else {
                    obj = {
                        title: schemaItem.title,
                        type: 'string',
                        format: schemaItem.format,
                        enum: schemaItem.enum,
                        table: schemaItem.table,
                        column: schemaItem.column,
                        pkColumn: schemaItem.pkColumn,
                        fkFieldName: schemaItem.fkFieldName,
                    }
                }
            }
            else {
                obj = {
                    title: schemaItem.title,
                    type: schemaItem.type === 'file' ? 'string' : schemaItem.type,
                    maxLength: schemaItem.maxLength,
                    minLength: schemaItem.minLength,
                    format: schemaItem.type === 'file' ? 'data-url' : schemaItem.format,
                    minimum: schemaItem.minimum,
                    maximum: schemaItem.maximum,
                    enum: schemaItem.enum
                }
            }
            if (schemaItem.inline || schemaItem.accept || schemaItem.widget || schemaItem.readonly || schemaItem.help) {
                obj1 = {
                    "ui:options": {
                        "inline": schemaItem.inline,
                        "accept": schemaItem.accept,
                        "readonly": schemaItem.readonly
                    },
                    "ui:widget": schemaItem.widget,
                    "ui:help": schemaItem.help
                }
                uiSchema[schemaItem.name] = obj1;
            }
            if (schemaItem.required) {
                createSchema.required.push(schemaItem.name);
            }
            createSchema.properties[schemaItem.name] = obj;

            if (schemaItem.enum !== undefined) {
                schemaItem.enum.map((item) => {
                    if (item === 'other' || item === 'Other') {
                        let dependencies = {
                            [schemaItem.name]: {
                                "oneOf": [
                                    {
                                        "properties": {
                                            [schemaItem.name]: {
                                                "enum": [
                                                    "Other"
                                                ]
                                            },
                                            ["Other: " + schemaItem.name]: {
                                                "type": "string",
                                                "title": "Other: " + schemaItem.name
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                        Object.assign(createSchema.dependencies, dependencies);
                    }
                    return false;
                })
            }

            if (schemaItem.allOf) {
                createSchema.allOf = schemaItem.allOf;
            }
            if (schemaItem.dependencies) {
                createSchema.dependencies = schemaItem.dependencies;
            }
        }
    }

    saveData = () => {
        if (this.validate()) {
            this.setState({
                saveFlag: true
            }, () => {
                this.previewData();
            })
        }
    }
    previewData = async () => {
        let formSchema = {};
        if (this.validate()) {
            const createSchema = {
                "title": this.state.stepTitle,
                "description": this.state.shortDescription,
                "type": "object",
                "required": [],
                "properties": {},
                "dependencies": {}
            };
            const uiSchema = {
                "ui:submitButtonOptions": {
                    "submitText": "Submit",
                    "norender": false,
                    "props": {
                        "disabled": false,
                        "className": "btn btn-info"
                    }
                }
            };

            let obj = {};
            let obj1 = {};

            this.state.formData.forEach((item) => {
                fieldSchema.forEach((schemaItem, index) => {
                    if (schemaItem.name === item.title.value || schemaItem.name === item.title) {
                        this.fieldHandler(item, schemaItem, index, createSchema, uiSchema, obj, obj1);
                    }
                });
            })
            const requiredArray = [...createSchema.required];
            const uniqueArray = Array.from(
                requiredArray.reduce((map, obj) => map.set(obj, obj), new Map()).values()
            );
            createSchema.required = [...uniqueArray];

            if (this.state.saveFlag) {
                await this.addFormHandler(this.state.stepId, createSchema.title, createSchema, uiSchema);
            }

            formSchema.schema = createSchema;
            if (!this.state.saveFlag) {
                this.setState({
                    formSchema: formSchema,
                    uiSchema: uiSchema,
                    flag: true
                });
            }
        }
    }

    cancelHandler = (event) => {
        this.props.history.push("/step-list-page");
    }

    arrowDown = (index) => {
        const formDataArray = [...this.state.formData];
        if (formDataArray.length > 1 && ((index + 1) < formDataArray.length)) {
            let nextField = formDataArray[index + 1];
            let prevField = formDataArray[index];
            formDataArray[index + 1] = prevField;
            formDataArray[index] = nextField;
            this.setState({
                formData: formDataArray
            });
        }
    }

    arrowUp = (index) => {
        const formDataArray = [...this.state.formData];
        if (formDataArray.length > 1 && index !== 0) {
            let prevField = formDataArray[index - 1];
            let nextField = formDataArray[index];
            formDataArray[index - 1] = nextField;
            formDataArray[index] = prevField;
            this.setState({
                formData: formDataArray
            });
        }
    }

    dragStart = (e, position) => {
        console.log("start", position);
        this.setState({
            dragItem: position
        });
    };

    dragEnter = (e, position) => {
        console.log("enter", position);
        this.setState({
            dragOverItem: position
        });
    };

    drop = (e) => {
        const formDataArray = [...this.state.formData];
        const dragItemContent = formDataArray[this.state.dragItem];
        formDataArray.splice(this.state.dragItem, 1);
        formDataArray.splice(this.state.dragOverItem, 0, dragItemContent);
        this.setState({
            formData: formDataArray,
            dragItem: null,
            dragOverItem: null
        });
    };

    otpAuthHandler = () => {
        let flag = !this.state.isOTPRequired;
        this.setState({
            isOTPRequired: flag
        })
    }

    phaseChange = (data) => {
        this.setState({
            selectedPhase: data,
            selectedPhaseValue: data.value
        })
    }

    onChangeRadioValue = (event) => {
        if (event.target.value === "YES") {
            this.setState({
                sendNotification: true
            });
        }
        else if (event.target.value === "NO") {
            this.setState({
                sendNotification: false
            }, () => {
                if (this.state.sendNotificationEmailTo) {
                    this.setState({
                        sendNotificationEmailTo: ""
                    })
                }
            })
        }
    }

    handleEmailChange = (event) => {
        this.setState({
            sendNotificationEmailTo: event.target.value
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="pagetitle row" style={{ color: 'black', paddingTop: 15 }}>
                        <div className="col-md-10 sm_device"><i className={`${FILE_ICON}`}></i> Step Creation</div>
                        <div className="col-md-2 sm_device" style={{ textAlign: 'right' }}><i className={``}></i> {moment().format("DD MMM YYYY")}</div>
                    </div>
                    <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop: 15 }} />
                    <div className="list-wrapper">
                        <div className="mt-4 border list-table-div" style={{ marginBottom: 50, paddingTop: 20 }}>
                            <div className="App">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h4 className="pagetitle"><b> {this.state.editData === null ? 'Create New Step' : 'Edit Step'}</b></h4><br />
                                        <label>Step Title : </label><br />
                                        <textarea rows={2} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey', padding: '2px' }} value={this.state.stepTitle ? this.state.stepTitle: ""} onChange={(e) => this.setStepTitle(e.target.value)} placeholder="Enter step title" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.stepTitle}
                                        </div>
                                        <label>Step Description :</label><br />
                                        <textarea rows={4} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey', padding: '2px' }} value={this.state.shortDescription ? this.state.shortDescription : ""} onChange={(e) => this.setShortDescription(e.target.value)} placeholder="Enter step description" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.shortDescription}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                            <label>Select Step Phase :</label>
                                                <Select
                                                    options={this.state.phasesNumbers}
                                                    onChange={this.phaseChange}
                                                    value={this.state.selectedPhase}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.phaseError}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <label>Send Notification Email :</label>
                                                <div onChange={this.onChangeRadioValue}>
                                                    {
                                                        this.state.stepId && this.state.sendNotification !== null ? (<>
                                                            <input type="radio" value="YES" checked={this.state.sendNotification} name="gender" /> YES
                                                            <input type="radio" className="ml-2" value="NO" checked={this.state.sendNotification !== null && this.state.sendNotification === false ? true : false} name="gender" /> NO
                                                        </>) : (<>
                                                            <input type="radio" value="YES" name="gender" /> YES
                                                            <input type="radio" className="ml-2" value="NO" name="gender" /> NO
                                                        </>)
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.sendNotification && (<>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <label>Enter Email Id <span className="text-danger">*</span> :</label> <br />
                                                        <div className="width-100">
                                                            <input className="form-control brand-font p-3 placeholderColor send-notification-email-input" value={this.state.sendNotificationEmailTo} onChange={this.handleEmailChange} placeholder="Enter comma separated email ids" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-danger error-msg">
                                                    {this.state.error.sendNotificationEmailTo}
                                                </div>
                                            </>)
                                        }
                                        <div className="row">
                                            <div className="col-md-5 col-xs-12" style={{ textAlign: "center" }}><label><b>Field </b> </label></div>
                                            <div className="col-md-3 col-xs-12" style={{ textAlign: "center" }}><label><b>Action </b> </label></div>
                                        </div>
                                        {
                                            this.state.formData.map((formItem, index) => {
                                                let option = [];
                                                if (formItem.title.value) {
                                                    option = { value: formItem.title.value, label: formItem.title.value };
                                                }
                                                else {
                                                    option = { value: formItem.title, label: formItem.title };
                                                }
                                                return (
                                                    <div key={`formItem-${index}`}>
                                                        <div className="row" style={{ cursor: "pointer" }}
                                                            title="Drag field"
                                                            onDragStart={(e) => this.dragStart(e, index)}
                                                            onDragEnter={(e) => this.dragEnter(e, index)}
                                                            onDragEnd={this.drop} key={index} draggable >
                                                            <div className="col-md-5">
                                                                <Select placeholder="select field name" style={{ border: '1px solid lightgrey' }} value={option} isSearchable={true} options={this.state.fieldOptionsComponent} onChange={(e) => this.setStepTitleHandler(e, index)} />
                                                            </div>
                                                            <div className="col-md-1">
                                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 20, cursor: "pointer" }} className={`${ARROW_DOWN_ICON} icon-style`} onClick={() => this.arrowDown(index)} title="Move field down" aria-hidden="true"></i>
                                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 50, cursor: "pointer" }} className={`${ARROW_UP_ICON} icon-style`} onClick={() => this.arrowUp(index)} title="Move field up" aria-hidden="true"></i>
                                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 80, cursor: "pointer" }} className={`${PLUS_ICON} icon-style`} onClick={() => this.addField()} title="Add new field" aria-hidden="true"></i>
                                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 110, cursor: "pointer" }} className={`${TRASH_ICON} icon-style`} onClick={() => this.removeField(index)} title="Remove field" aria-hidden="true"></i>
                                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 140, cursor: "pointer" }} className={`${ALIGNJUSTIFY_ICON} icon-style`} title="Drag Fields" aria-hidden="true"></i><br /><br />
                                                            </div>
                                                        </div>
                                                        {
                                                            formItem.title === '' && (
                                                                <div className="text-danger error-msg">
                                                                    {this.state.error.title}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-8">
                                        <button className="brand-button" onClick={this.previewData}>Preview </button>
                                        <button className="brand-button ml-5" onClick={this.saveData}>Save </button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingBottom: 20 }}>
                                    {
                                        this.state.flag && (
                                            <div className="custom_modal">
                                                <div className="modal_body">
                                                    <button type="button" style={{ fontSize: 20 }} className="close text-right" onClick={(e) => this.setState({ flag: false })}>&times;</button>
                                                    <Form schema={this.state.formSchema.schema} uiSchema={this.state.uiSchema} validator={validator} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CreateStepPage
