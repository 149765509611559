import * as XLSX from 'xlsx';
import { emailPattern, PAN_NUMBER_PATTERN, CIN_NUMBER_PATTERN, GSTIN_NUMBER_PATTERN } from "assets/constants/Constants";

export function importEmployer(event, allEmployerList = []) {
    return new Promise(async (resolve, reject) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0]; // First sheet
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, rawNumbers: true });
            let employerList = [];
            let columns = [];
            if (jsonData.length > 0) {
                columns = jsonData[0];
                columns = columns.map((item) => {
                    let itemElement = item.replaceAll(" ", "");
                    itemElement = itemElement.replaceAll(".", "");
                    itemElement = itemElement.trim();
                    itemElement = itemElement.toLowerCase();
                    return itemElement;
                });
                for (let i = 1; i < jsonData.length; i++) {
                    const keys = columns;
                    const values = jsonData[i].map((item, index) => {
                        if (item && typeof item === "string") {
                            return item.trim();
                        }
                        else if (item) {
                            return item;
                        }
                        else {
                            return "";
                        }
                    });
                    if (values && values.length > 0) {
                        const result = keys.map((key, index) => ({ [key]: values[index] }))
                            .reduce((obj, item) => ({ ...obj, ...item }), {});
                        employerList.push(result);
                    }
                }
            }

            let employerParamsList = [];
            const employerListProcess = employerList.map(async (employerItem) => {
                let param = {
                    employer_name: "",
                    unit_name: "",
                    employer_location: "",
                    unit_status: "",
                    representative_name: "",
                    representative_phone_no: "",
                    representative_email_id: "",
                    state: "",
                    city: "",
                    company_Website_Url: "",
                    pin_code: "",
                    social_media_url: "",
                    pan_card_number: "",
                    billing_entity_name: "",
                    cin_number: "",
                    gstin_number: "",
                    unit_bank_name: "",
                    unit_bank_address: "",
                    unit_account_number: "",
                    validEmployer: true,
                    errorMessage: [],
                }

                if (employerItem.address) {
                    param.employer_location = employerItem.address;
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter address");
                }

                if (employerItem.billingentityname) {
                    param.billing_entity_name = employerItem.billingentityname;
                }

                if (employerItem.cinnumber) {
                    if (!CIN_NUMBER_PATTERN.test(employerItem.cinnumber)) {
                        param.validEmployer = false;
                        param.errorMessage.push("Please enter valid CIN number");
                    } else {
                        param.cin_number = employerItem.cinnumber;
                    }
                }

                if (employerItem.city) {
                    param.city = employerItem.city;
                }

                if (employerItem.companyname) {
                    param.employer_name = employerItem.companyname;
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter company name");
                }

                if (employerItem.pancardnumber) {
                    if (!PAN_NUMBER_PATTERN.test(employerItem.pancardnumber)) {
                        param.validEmployer = false;
                        param.errorMessage.push("Please enter valid PAN number");
                    } else {
                        param.pan_card_number = employerItem.pancardnumber;
                    }
                }

                if (employerItem.pincode) {
                    if (employerItem.pincode.toString().length !== 6) {
                        param.validEmployer = false;
                        param.errorMessage.push("Please enter valid pin number");
                    } else {
                        param.pin_code = employerItem.pincode;
                    }
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter pin number");
                }

                if (employerItem.state) {
                    param.state = employerItem.state;
                }

                if (employerItem.unitacccountnumber) {
                    param.unit_account_number = employerItem.unitacccountnumber;
                }

                if (employerItem.unitbankaddress) {
                    param.unit_bank_address = employerItem.unitbankaddress;
                }

                if (employerItem.unitbankname) {
                    param.unit_bank_name = employerItem.unitbankname;
                }

                if (employerItem.unitgstinnumber) {
                    if (!GSTIN_NUMBER_PATTERN.test(employerItem.unitgstinnumber)) {
                        param.validEmployer = false;
                        param.errorMessage.push("Please enter valid GST number");
                    } else {
                        param.gstin_number = employerItem.unitgstinnumber;
                    }
                }

                if (employerItem.unitname) {
                    param.unit_name = employerItem.unitname;
                    if (allEmployerList && allEmployerList.length > 0) {
                        const duplicateEmployerFound = allEmployerList.find((findItem) => findItem.unit_name === employerItem.unitname && employerItem.companyname === findItem.employer_name);
                        if (duplicateEmployerFound) {
                            param.validEmployer = false;
                            param.errorMessage.push("This employer unit is already present in the system. Please enter different employer unit");
                        }
                    }
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter unit name");
                }

                if (employerItem.unitrepresentativeemail) {
                    if (!emailPattern.test(employerItem.unitrepresentativeemail)) {
                        param.validEmployer = false;
                        param.errorMessage.push("Please enter valid representative email");
                    } else {
                        param.representative_email_id = employerItem.unitrepresentativeemail;
                    }
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter unit representative email");
                }

                if (employerItem.unitrepresentativename) {
                    param.representative_name = employerItem.unitrepresentativename;
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter unit representative name");
                }

                if (employerItem.unitrepresentativenumber) {
                    param.representative_phone_no = employerItem.unitrepresentativenumber;
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter unit representative number");
                }

                if (employerItem.unitsocialmedia) {
                    param.social_media_url = employerItem.unitsocialmedia;
                }

                if (employerItem.unitstatus) {
                    param.unit_status = employerItem.unitstatus;
                } else {
                    param.validEmployer = false;
                    param.errorMessage.push("Please enter unit status");
                }

                if (employerItem.unitwebsitelink) {
                    param.company_Website_Url = employerItem.unitwebsitelink;
                }

                employerParamsList.push(param);
            })

            await Promise.all(employerListProcess);
            resolve(employerParamsList);
        }
        reader.onerror = (event) => {
            console.error("File could not be read:", event.target.error);
            resolve();
        };

        reader.readAsArrayBuffer(file);
    })
}