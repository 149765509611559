import React, { Component } from 'react'
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL, ADMIN, SUPER_ADMIN } from 'assets/constants/Constants';
import { EDIT_ICON, TRASH_ICON, KEY_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import MaterialTable from "material-table";
import { hasPermission } from 'components/Permission';
import { getUserList, getRoleListCall, deleteUserCall } from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import ResetPasswordComponent from 'components/ResetPasswordComponent';
import { UpdateUserCall } from "lib/axiosUtils";
import "./user-style.css";
import AuditReport from "components/ProjectTracking/AuditReport";

export class UserPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isUsernamePresent: false,
            input: {
                userFirstName: "",
                userLastName: "",
                userEmailId: "",
                userPassword: "",
                userRole: "",
                openResetPasswordModal: false
            },
            buttonText: "Add User",
            userList: [],
            roleList: [],
            mytheme : createTheme({}),
            switchChecked: false
        }
    }

    async componentDidMount() {
        const userData = await getUserList({},{});
        const roleData = await getRoleListCall({},{});
        this.setState({
            userList: userData.data.result,
            totalCount: userData.data.totalCount,
            roleList: roleData.data.result,
            isLoading: false
        })
    }

    resetPasswordHandler = (rowData) => {
        this.setState({
            userData: rowData
        }, () => {
            this.setState({
                openResetPasswordModal: true,
            })
        })
    }

    addEditUserHandler = (item) => {
        if (item) {
            const rowItem = {
                _id: item.userId,
                first_name: item.firstName,
                last_name: item.lastName,
                email_id: item.emailId,
                phone_number: item.phoneNumber,
                username: item.username,
                password: item.password,
                role: item.roleId,
                select_profile: item.selectProfile,
                company_id: item.selectedEmployer,
                employer_id: item.employerUnit,
                project_id: item.selectedPartner,
                partner_id: item.selectedTrainingPartner,
            }
            if ((item.userRole === ADMIN) || (item.userRole === SUPER_ADMIN)) {
                this.props.history.push({
                    pathname: "/create-user-page",
                    state: { ...rowItem, isFromProfile: false }
                })

            }
            else {
                this.props.history.push({
                    pathname: "/create-user-page",
                    state: { ...rowItem, isFromProfile: false }
                });
            }
        }
        else {
            this.props.history.push({
                pathname: "/create-user-page",
                state: null
            });
        }
    }

    handleDeleteUser = async (item) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            this.setState({ isLoading: true });
            let deleteResponse = await deleteUserCall({},{},item);
            if (deleteResponse.statusCode === 200) {
                let fetchResponse = await getUserList({},{});
                this.setState({
                    userList: fetchResponse.data.result,
                    isLoading: false
                });
                ShowToast('User deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
            }
            else {
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
            }
        }
    }

    handleDelete = async (item) => {
        if ((item.userRole === ADMIN) || (item.userRole === SUPER_ADMIN)) {
            // hasPermission("user","admin_profile") ? (
            this.handleDeleteUser(item.userId)
            // ):(
            //     ShowToast('You dont have permission.', TYPE_FAIL, INTERVAL, this.props, "")
            // )
        }
        else {
            this.handleDeleteUser(item.userId)
        }
    }

    cancelHandle = () => {
        if (this.state.openResetPasswordModal === true) {
            this.setState({
                openResetPasswordModal: false
            })
        }
    }

    renderRole = (item) => {
        let roleNameArray = [];
        this.state.roleList.forEach((roleItem) => {
            if (roleItem._id === item) {
                roleNameArray.push(roleItem.user_role);
            }
        });
        return roleNameArray.join(", ");
    }

    renderUserRole = (item) => {
        let roleNameArray = [];
        this.state.roleList.forEach((roleItem) => {
            if (roleItem.user_role === item) {
                roleNameArray.push(roleItem._id);
            }
        });
        return roleNameArray.join(", ");
    }

    handleToggle = async (userId, currentFlag) => {
        if (window.confirm(`Are you sure you want to ${currentFlag ? "Disable" : "Enable"} Audit for this user?`)) {
            this.setState({ isLoading: true });

            const requestData = {
                auditflag: !currentFlag
            }

            const userResponse = await UpdateUserCall({}, requestData, userId);
            if (userResponse.statusCode === 200) {
                let fetchResponse = await getUserList({}, {});
                this.setState({
                    userList: fetchResponse.data.result,
                    isLoading: false
                });
                ShowToast(`Audit ${currentFlag ? "Disabled" : "Enabled"}`, TYPE_SUCCESS, INTERVAL, this.props, "");
            } else {
                ShowToast("Something went wrong, please try again", TYPE_FAIL, INTERVAL, this.props, "")
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
            <>
                <div className="main-content user-page-outer-wrapper">
                    <div className="list-wrapper">
                        <div className="back">
                            <ul className="breadcrumb1">
                                <li>User Master</li>
                            </ul>
                        </div>
                        {
                            hasPermission("user", "create") && (
                                <div className="user-button-view">
                                    {
                                        <>
                                            <button className="brand-button" onClick={() => this.addEditUserHandler(null)}>{this.state.buttonText}</button>
                                        </>
                                    }
                                </div>
                            )
                        }
                        
                        <div className="mt-4 border list-table-div site-table" style={{ marginBottom: 50 }}>
                            {
                                this.state.userList.length > 0 ? (
                                    <ThemeProvider theme={this.state.mytheme}>
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: "Username", field: "username",
                                                    cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14, textTransform: 'none' }
                                                },
                                                {
                                                    title: "User", field: "user",
                                                    cellStyle: { cellWidth: '18%', fontWeight: 'bolder', color: '#000000', fontSize: 14 }
                                                },
                                                {
                                                    title: "Email Id", field: "emailId",
                                                    cellStyle: { cellWidth: '20%', color: '#808080', fontSize: 14, textTransform: 'none' },
                                                    sorting: false
                                                },
                                                {
                                                    title: "Phone Number", field: "phoneNumber",
                                                    cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 },
                                                    sorting: false
                                                },
                                                {
                                                    title: "Role", field: "userRole",
                                                    cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14 }
                                                },
                                                hasPermission("user", "audit") && (
                                                    {
                                                        title: "Audit",
                                                        field: "audit",
                                                        sorting: false,
                                                        filtering: false,
                                                        render: rowData => (
                                                            <>
                                                                <label class="toggle-switch">
                                                                    <input type="checkbox" checked={rowData.isAudit} onChange={() => this.handleToggle(rowData.userId, rowData.isAudit)} />
                                                                    <span class="slider"></span>
                                                                </label>
                                                                <AuditReport userId={rowData.userId} isFromTracking={false} />
                                                            </>
                                                        ),
                                                    }
                                                )
                                            ]}
                                            data={
                                                this.state.userList.map((item, index) => {
                                                    return (
                                                        {
                                                            user: `${item.first_name} ${item.last_name}`,
                                                            firstName: item.first_name,
                                                            lastName: item.last_name,
                                                            emailId: item.email_id,
                                                            phoneNumber: item.phone_number,
                                                            username: item.username,
                                                            userRole: this.renderRole(item.role),
                                                            password: item.password,
                                                            confirmPassword: item.password,
                                                            userId: item._id,
                                                            roleId: item.role,
                                                            selectProfile: item.select_profile,
                                                            selectedEmployer: item.company_id,
                                                            employerUnit: item.employer_id,
                                                            selectedPartner: item.project_id,
                                                            selectedTrainingPartner: item.partner_id,
                                                            isAudit: item.audit_enable
                                                        }
                                                    )
                                                })
                                            }

                                            actions={[
                                                hasPermission("user", "update") && (
                                                    {
                                                        icon: () => <i className={`${KEY_ICON}`} title="Reset Password"></i>,
                                                        onClick: (event, rowData) =>
                                                            this.resetPasswordHandler(rowData)
                                                    }
                                                ),
                                                hasPermission("user", "update") && (
                                                    {
                                                        icon: () => <i className={`${EDIT_ICON}`} title="Edit User"></i>,
                                                        onClick: (event, rowData) =>
                                                            this.addEditUserHandler(rowData)
                                                    }
                                                ),
                                                {
                                                    icon: () => <i className={`${TRASH_ICON}`} title="Delete User"></i>,
                                                    onClick: (event, rowData) =>
                                                        this.handleDelete(rowData)
                                                }
                                            ]}
                                            options={{
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    color: '#999998',
                                                    fontWeight: 'bolder',
                                                    fontSize: 15
                                                },
                                                filtering: true,
                                                sorting: true,
                                                columnsButton: true,
                                                exportButton: true,
                                                exportFileName: "User Data"
                                            }}
                                        />
                                    </ThemeProvider>
                                ) : (
                                    <div className="text-center pt-2">
                                        <label>User records not found!</label>
                                    </div>
                                )

                            }
                        </div>
                    </div>
                    </div>
                    {
                        this.state.openResetPasswordModal && (
                            <ResetPasswordComponent modelOpen={this.state.openResetPasswordModal} closeModel={this.cancelHandle} userData={this.state.userData} />
                        )
                    }
                </>)
            // }
        }
    }
}

export default UserPage