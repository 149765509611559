import React, { Component } from 'react';
import MaterialTable from "material-table";
import { EYE_ICON, TRASH_ICON, EDIT_ICON} from 'assets/constants/Icons';
import { INTERVAL, TYPE_FAIL, TYPE_SUCCESS} from "assets/constants/Constants";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import moment from 'moment';
import {getFieldData, deleteFieldData } from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

export class FieldSchemaPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            viewField:false,
            buttonText: "Create Field",
            fieldList:[],
            fieldArrayKey:[],
            fieldArrayValue:[],
            mytheme:createTheme({})
        }
    }

    async componentDidMount () {
        const fieldData = await getFieldData({},{});
        this.setState({
            fieldList: fieldData.data.result,
            isLoading: false
        },() => {
            let mgr_schema=[];
            // walnutSchema.fieldArray.map((item) => {
            //     mgr_schema.push({"fieldTitle":item.title,"fieldArray":item, "customArray":null});
            //     return false
            // })
            this.setState({
                fieldList:this.state.fieldList.concat(mgr_schema)
            });
        });
    }

    fieldSchemaHandler = (data) => {
        if(data){
            this.props.history.push({
                pathname:"/create-field-schema-page",
                state:data
            })
        }
        else{
            this.props.history.push({
                pathname: "/create-field-schema-page",
                state:null
            })
        }
    }

    viewHandler = (data) => {
        let fieldArrayKey = data.fieldArray !== null ? Object.keys(data.fieldArray) :Object.keys(data.fieldArray);
        let fieldArrayValue = data.fieldArray !== null ? Object.values(data.fieldArray) :Object.values(data.fieldArray);
        this.setState({
            viewField:true, 
            schemaData : data,
            fieldArrayKey :fieldArrayKey,
            fieldArrayValue: fieldArrayValue
        });
    }

    handleDelete = async (data) => {
        if (window.confirm("Are you sure you want to delete this field?")) {
            this.setState({ isLoading: true });
            let deleteResponse = await deleteFieldData({},{},data.fieldId);
            if (deleteResponse.statusCode === 200) {
                let fetchResponse = await getFieldData({},{})
                this.setState({
                    fieldList: fetchResponse.data.result,
                    isLoading: false
                });
                ShowToast('Field deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
            }
            else {
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
            }
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="pagetitle row" style={{color:'black', paddingTop:15}}>
                        <div className ="col-md-10 sm_device"><i className={``}></i> Field List </div>
                        <div className ="col-md-2 sm_device" style={{textAlign:'right'}}><i className={``}></i> {moment().format("DD MMM YYYY")}</div>
                    </div>
                    <div style={{ borderBottom: '1px solid lightgray', marginBottom: 15, marginTop:15 }} />
                    <div className="list-wrapper">
                        <div className="form-button-div">
                            <button className="brand-button" onClick={ () => this.fieldSchemaHandler(null) }>{ this.state.buttonText }</button>
                        </div>
                        <div  className="mt-4 border list-table-div" style={{marginBottom:50}}>
                        {
                            this.state.fieldList.length > 0 ? (
                                <ThemeProvider theme={this.state.mytheme}>
                                    <MaterialTable
                                        title=""
                                        columns={[
                                            {
                                                title: "Sr No", field: "SerialNo",
                                                cellStyle: { width: '5%', fontWeight: 'bolder', color: '#000000', fontSize: 14 }
                                            },
                                            {
                                                title: "Field Name", field: "fieldTitle",
                                                cellStyle: { cellWidth: '15%', color: '#808080', fontSize: 14, textTransform: 'none' }
                                            },
                                            {
                                                title: "Display Name", field: "displayName",
                                                cellStyle: { cellWidth: '15%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Type", field: "type",
                                                cellStyle: { cellWidth: '15%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Required", field: "required",
                                                cellStyle: { cellWidth: '15%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Read Only", field: "readOnly",
                                                cellStyle: { cellWidth: '15%', color: '#808080', fontSize: 14 }
                                            }
                                        ]}
                                        data={
                                            this.state.fieldList.map((item, index) => {
                                                return (
                                                    {
                                                        SerialNo: (index + 1),
                                                        fieldId: item._id,
                                                        displayName: item.field_array !== null ? item.field_array.title : item.customArray.title,
                                                        fieldTitle: item.field_array !== null ? item.field_array.name : item.customArray.name,
                                                        type: item.field_array !== null ? item.field_array.type : item.customArray.type,
                                                        required: item.field_array !== null ? item.field_array.required : item.customArray.required,
                                                        readOnly: item.field_array !== null ? item.field_array.readonly : item.customArray.readonly,
                                                        mgrCustom: item.field_array !== null ? "MGR" : "Custom",
                                                        fieldArray: item.field_array,
                                                        customArray: item.customArray
                                                    }
                                                )
                                            })
                                        }
                                        actions={[
                                            {
                                                icon: () => <i className={`${EYE_ICON}`} title="View Schema"></i>,
                                                onClick: (event, rowData) => this.viewHandler(rowData)
                                            },
                                            (rowData) => {
                                                return {
                                                    icon: () => <i className={`${EDIT_ICON}`} title='Edit Schema'></i>,
                                                    disabled: rowData.customArray === null,
                                                    onClick: (event, rowData) => this.fieldSchemaHandler(rowData)
                                                };
                                            },
                                            (rowData) => {
                                                return {
                                                    icon: () => <i className={`${TRASH_ICON}`} title='Delete Schema'></i>,
                                                    disabled: rowData.customArray === null,
                                                    onClick: (event, rowData) => this.handleDelete(rowData)
                                                };
                                            }
                                        ]}
                                        options={{
                                            pageSize: 25,
                                            pageSizeOptions: [25, 50, 100],
                                            actionsColumnIndex: -1,
                                            headerStyle: {
                                                color: '#999998',
                                                fontWeight: 'bolder',
                                                fontSize: 15
                                            },
                                            filtering: true,
                                            sorting: true,
                                            columnsButton: true,
                                            exportButton: true,
                                            exportFileName: "fb_field_schema",
                                        }}
                                    />
                                </ThemeProvider>
                            ): (
                                <div className="text-center pt-2">
                                    <label>Records not found!</label>
                                </div>
                            )
                        }
                        {
                            this.state.viewField && (
                                <>
                                    <div className="custom_modal">
                                        <div className="modal_body" style ={{width:'50%', margin:'auto', height:'auto'}}>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h3 className="modal_title pagetitle">View Field schema</h3>
                                                </div>
                                                <div className="col-md-4">
                                                    <button type="button" className="close text-right" onClick={(e) => this.setState({ viewField: false })}>&times;</button>
                                                </div>
                                            </div>
                                                {
                                                    this.state.fieldArrayKey.map((item, index) => {
                                                        return (
                                                            <div className="row" key={`fieldArray-${index}`}>
                                                                <div className="col-md-6 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>{item}</label>
                                                                </div>
                                                                <div className="col-md-6 text-center" style={{ border: "1px solid lightgrey"}}>
                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>{this.state.fieldArrayValue[index] === true ? 'True' : (this.state.fieldArrayValue[index] === false ? 'false': this.state.fieldArrayValue[index])}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default FieldSchemaPage
