import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { EYE_ICON, EYE_SLASH_ICON } from 'assets/constants/Icons';
import { TYPE_SUCCESS, INTERVAL, TYPE_FAIL, passwordMatchPattern, TYPE_INFO, SECRET_KEY } from "assets/constants/Constants";
import ShowToast from 'components/ShowToast';
import CryptoJS from "crypto-js";
import md5 from 'md5';
import { resetPasswordByAdminCall } from "lib/axiosUtils";

Modal.setAppElement('#root')
function ResetPasswordComponent(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState({});
    const [resetButtonLoading, setResetButtonLoading] = useState(false);

    useEffect(() => {
        setModalOpen(props.modelOpen);
    }, [props]);

    const closeModal = () => {
        setModalOpen(false);
        props.cancelHandle();
    }

    const cancelHandle = (event) => {
        setModalOpen(false);
        props.closeModel();
    }

    const changeNewPasswordhandler = (event) => {
        setNewPassword(event.target.value)
    }

    const changeConfirmPasswordhandler = (event) => {
        setConfirmPassword(event.target.value)
    }

    const passwordViewHandler = (event) => {
        setShowPassword(!showPassword);
    }

    const confirmPasswordViewHandler = (event) => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const resetPasswordHandler = async (event) => {
        event.preventDefault();
        try {
            if (isFormValid()) {
                setResetButtonLoading(true);
                const resetParams = {
                    password: md5(newPassword),
                    displayPassword: CryptoJS.AES.encrypt(newPassword, SECRET_KEY).toString(),
                    userId: props && props.userData ? props.userData.userId : null
                }
                if (resetParams.userId) {
                    const resetPasswordResult = await resetPasswordByAdminCall({}, resetParams);
                    if (resetPasswordResult && resetPasswordResult.data && Object.entries(resetPasswordResult.data.result).length !== 0) {
                        ShowToast('Password changed successfully', TYPE_SUCCESS, INTERVAL, null, "");
                        window.location.reload();
                    }
                    else {
                        ShowToast('Error Occurred !', TYPE_FAIL, INTERVAL, null, "");
                    }
                }
                setResetButtonLoading(false);
                setModalOpen(false);
                props.cancelHandle();
            }
        }
        catch (error) {
            console.log("Error occurred while reseting the password", error);
            setResetButtonLoading(false);
        }
    }

    const isFormValid = () => {
        let isValid = true;
        let error = {};
        if (!newPassword) {
            isValid = false;
            error.newPassword = "Please enter new password";
        }
        if (!confirmPassword) {
            isValid = false;
            error.confirmPassword = "Please enter confirm password";
        }
        if (newPassword && confirmPassword) {
            if (newPassword !== confirmPassword) {
                isValid = false;
                error.confirmPassword = "Confirm password and password do not match";
            }
        }
        if (newPassword) {
            if (!passwordMatchPattern.test(newPassword)) {
                isValid = false;
                error.newPassword = "Please enter valid new password";
                ShowToast('Password must be minimum eight characters including one uppercase letter,\n one special character and alphanumeric characters', TYPE_INFO, INTERVAL, null, "");
            }
        }
        setError(error)
        return isValid;
    }

    return (
        <>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="program-modal" overlayClassName="program-modal-overlay">
                <div className="program-modal-container job-disabled-width partner-modal-div program-modal-div display-flex-column-div padding-20px" role="document">
                    <form action="" className="display-flex-column-div">
                        <h4 className="import-error-heading text-color-black text-align-center">RESET USER PASSWORD</h4>
                        {<div className="display-flex-column-div">
                            <label className="form-label">User Name : {props ? props.userData.username : ""}</label>
                            <label className="form-label">New Password <span className="text-danger">*</span></label>
                            <div className="input_box reset-password-input-box display-flex-column-div">
                                <input type={showPassword ? "text" : "password"} name="newPassword" className="reset-password-modal-input" value={newPassword} placeholder="Please enter new password" onChange={changeNewPasswordhandler} />
                                <div className="icon"><i title={showPassword === false ? "Show Password" : "Hide Password"} className={`${showPassword === false ? EYE_ICON : EYE_SLASH_ICON} icon reset-password-modal-eye-icon`} onClick={passwordViewHandler}></i></div>
                                <div className="text-danger error-msg">
                                    {error.newPassword}
                                </div>
                            </div>
                            <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                            <div className="input_box reset-password-input-box display-flex-column-div">
                                <input type={showConfirmPassword ? "text" : "password"} name="confirmPassword" className="reset-password-modal-input" value={confirmPassword} placeholder="Please enter confirm password" onChange={changeConfirmPasswordhandler} />
                                <div className="icon"><i className={`${showConfirmPassword === false ? EYE_ICON : EYE_SLASH_ICON} icon reset-password-modal-eye-icon`} onClick={confirmPasswordViewHandler}></i></div>
                                <div className="text-danger error-msg">
                                    {error.confirmPassword}
                                </div>
                            </div>
                        </div>}
                        <br />
                    </form>
                    <div className="form-button-div mt-5">
                        {
                            resetButtonLoading === true ? (<>
                                <div className="spinner-loader">
                                </div>
                            </>) : (
                                <button className="brand-button" onClick={resetPasswordHandler}>Reset</button>
                            )
                        }
                        <button className="button-secondary ml-3" onClick={cancelHandle}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default ResetPasswordComponent;