import React, { useEffect, useState } from "react";
import { METER_ICON, FILTER_ICON, CALENDAR_ICON } from "../../assets/constants/Icons";
import Spinner from 'components/Spinner';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from 'moment';
import AdminEmployerDashboard from "../../components/Admin/AdminEmployerDashboard";
import { getProjectTotalForAdmin, getCandidateTotalForAdmin, getYtdProjectTotalForAdmin, getYtdCandidateTotalForAdmin } from "lib/axiosUtils";
import Select from "react-select";
import { fetchMetadata, generateFinanceYearOptions } from "lib/utils";
import { FINANCE_MONTHS } from "../../assets/constants/Constants";

const AdminDashboardPage = () => {
    const [totalProjectData, setTotalProjectData] = useState({});
    const [totalCandidateData, setTotalCandidateData] = useState({});
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [dashboardTab, setDashboardTab] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Admin");
    const [showOtherDashboard, setShowOtherDashboard] = useState(false);
    const [projectTypeComponent, setProjectTypeComponent] = useState("<option> Yes/No </option>");
    const [projectType, setProjectType] = useState({ label: "Total", value: "total" });
    const [metaData, setMetaData] = useState(JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : []);
    const [projectTotalLoadingComplete, setProjectTotalLoadingComplete] = useState(false);
    const [selectedFinanceYear, setFinanceYear] = useState({ label: "All Years", value: "all" });
    const [selectedMonth, setMonth] = useState({ label: "All Months", value: "all" });
    const [ytdProjectData, setYtdProjectData] = useState({});
    const [ytdCandidateData, setYtdCandidateData] = useState({});

    useEffect(() => {
        const fetchProjectTotalForAdmin = async () => {
            try {
                const data = await getProjectTotalForAdmin({}, {}, projectType.value, selectedFinanceYear.value, selectedMonth.value);
                if (data && data.data && data.data.result) {
                    setTotalProjectData(data.data.result);
                }
                setLoadingComplete(true);
                setProjectTotalLoadingComplete(true);
            }
            catch (error) {
                console.log("error while fetching data", error);
                setLoadingComplete(true);
                setProjectTotalLoadingComplete(true);
            }
        }
        fetchProjectTotalForAdmin();
        if (selectedMonth && selectedMonth.value !== "all") {
            const fetchYTDProjectTotalForAdmin = async () => {
                try {
                    const data = await getYtdProjectTotalForAdmin({}, {}, projectType.value, selectedFinanceYear.value, selectedMonth.value);
                    if (data && data.data && data.data.result) {
                        setYtdProjectData(data.data.result);
                    }
                }
                catch (error) {
                    console.log("error while fetching data", error);
                }
            }
            fetchYTDProjectTotalForAdmin()
        }
    }, [projectType, selectedFinanceYear, selectedMonth]);

    useEffect(() => {
        const fetchCandidateTotalForAdmin = async () => {
            try {
                const data = await getCandidateTotalForAdmin({}, {}, selectedFinanceYear.value, selectedMonth.value);
                if (data && data.data && data.data.result) {
                    setTotalCandidateData(data.data.result);
                }
            }
            catch (error) {
                console.log("Error Occurred while fetching candidate total for admin", error);
            }
        }
        fetchCandidateTotalForAdmin();

        const tabDashboard = [
            "Admin",
            "Employer",
            "Partner"
        ]
        setDashboardTab(tabDashboard);
        if (selectedMonth && selectedMonth.value !== "all") {
            const fetchYTDCandidateTotalForAdmin = async () => {
                try {
                    const data = await getYtdCandidateTotalForAdmin({}, {}, selectedFinanceYear.value, selectedMonth.value);
                    if (data && data.data && data.data.result) {
                        setYtdCandidateData(data.data.result);
                    }
                }
                catch (error) {
                    console.log("error while fetching data", error);
                }
            }
            fetchYTDCandidateTotalForAdmin()
        }
    }, [selectedFinanceYear, selectedMonth]);

    const exportToExcel = async () => {
        setDownloadLoading(true);
        let monthsFlag = false;
        if (selectedMonth && selectedMonth.value !== "all") {
            monthsFlag = true;
        }
        const object = {
            ...totalCandidateData,
            ...totalProjectData,
            totalCandidateDropout: totalProjectData.totalCandidateDropout,
            totalTHSCCandidateDropout: totalProjectData.totalCandidateThscDropout,
            totalTimeProCandidateDropout: totalProjectData.totalCandidateTimesDropout,
            totalsCandidateAssigned: totalProjectData.totalAssignedCandidate,
            totalsAssignedCandidate: totalCandidateData.totalAssignedCandidate
        }
        const data = [object];
        if (selectedMonth && selectedMonth.value !== "all") {
            data.push({
                ...ytdCandidateData,
                ...ytdProjectData,
                totalCandidateDropout: ytdProjectData.totalCandidateDropout,
                totalTHSCCandidateDropout: ytdProjectData.totalCandidateThscDropout,
                totalTimeProCandidateDropout: ytdProjectData.totalCandidateTimesDropout,
                totalsCandidateAssigned: ytdProjectData.totalAssignedCandidate,
                totalsAssignedCandidate: ytdCandidateData.totalAssignedCandidate
            })
        }
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        let columns = [];
        const selectedColumn = [
            { header: "Total Project Count", key: "totalProjectCount" },
            { header: "Total Active Project", key: "totalActiveProject" },
            { header: "Total Project Complete", key: "totalProjectCompletedCount" },
            { header: "Total Candidate Expected", key: "totalCandidateExpected" },
            { header: "Total Candidate Assigned", key: "totalsCandidateAssigned" },
            { header: "Total Candidate Delivered", key: "totalCandidateDelivered" },
            { header: "Total Candidate Dropout", key: "totalCandidateDropout" },
            { header: "Total Male Candidate", key: "totalMaleCount" },
            { header: "Total Female Candidate", key: "totalFemaleCount" },
            { header: "Total Units", key: "totalUnitRegistered" }
        ];
        if (selectedMonth && selectedMonth.value !== "all") {
            columns = [{}, ...selectedColumn];
        }
        else {
            columns = [...selectedColumn];
        }

        data.forEach((row, dataIndex) => {
            const dataRow = [];
            columns.forEach((column, index) => {
                let columnIndex = 0;
                if (monthsFlag) {
                    columnIndex = 1;
                }
                if (index === columnIndex && dataIndex === 0) {
                    dataRow.push("TOTALS");
                }
                else {
                    if (dataIndex === 0) {
                        dataRow.push("");
                    }
                }
            });
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })

        const mainHeaderRow = worksheet.addRow(columns.map((column) => column.header));
        mainHeaderRow.font = { bold: true };
        mainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };

        data.forEach((row, dataIndex) => {
            const dataRow = [];
            columns.forEach((column) => {
                if (Object.entries(column).length === 0) {
                    if (dataIndex === 0) {
                        dataRow.push(`Monthly (${selectedMonth.label})`);
                    }
                    else {
                        dataRow.push("YTD");
                    }
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };
        })
        worksheet.addRow("");
        worksheet.addRow("");
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            columns.forEach((column, index) => {
                let columnIndex = 0;
                if (monthsFlag) {
                    columnIndex = 1;
                }
                if (index === columnIndex && dataIndex === 0) {
                    dataRow.push("THSC RTD");
                }
                else {
                    if (dataIndex === 0) {
                        dataRow.push("");
                    }
                }
            });
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })

        let thsccolumns = [
            { header: "Total THSC Project", key: "totalTHSCProjectCount" },
            { header: "Total THSC Active Project", key: "totalTHSCActiveProject" },
            { header: "Total THSC Project Complete", key: "totalTHSCProjectComplete" },
            { header: "Total THSC Candidate Expected", key: "totalTHSCCandidateExpected" },
            { header: "Total THSC Candidate Assigned", key: "totalTHSCAssignedCandidate" },
            { header: "Total THSC Candidate Delivered", key: "totalTHSCCandidateDelivered" },
            { header: "Total THSC Candidate Dropout", key: "totalTHSCCandidateDropout" },
            { header: "Total THSC Male Candidate", key: "totalTHSCMaleCount" },
            { header: "Total THSC Female Candidate", key: "totalTHSCFemaleCount" },
        ]
        if (selectedMonth && selectedMonth.value !== "all") {
            thsccolumns = [{}, ...thsccolumns];
        }
        const thscmainHeaderRow = worksheet.addRow(thsccolumns.map((column) => column.header));
        thscmainHeaderRow.font = { bold: true };
        thscmainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            thsccolumns.forEach((column) => {
                if (Object.entries(column).length === 0) {
                    if (dataIndex === 0) {
                        dataRow.push(`Monthly (${selectedMonth.label})`);
                    }
                    else {
                        dataRow.push("YTD");
                    }
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };;
        })
        worksheet.addRow("");
        worksheet.addRow("");
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            columns.forEach((column, index) => {
                let columnIndex = 0;
                if (monthsFlag) {
                    columnIndex = 1;
                }
                if (index === columnIndex && dataIndex === 0) {
                    dataRow.push("THSC Placement");
                }
                else {
                    if (dataIndex === 0) {
                        dataRow.push("");
                    }
                }
            });
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        let thscPlacementColumns = [
            { header: "Total THSC Placement Project", key: "totalTHSCPlacementProjectCount" },
            { header: "Total THSC Placement Active Project", key: "totalTHSCPlacementActiveProject" },
            { header: "Total THSC Placement Project Complete", key: "totalTHSCPlacementProjectComplete" },
            { header: "Total THSC Placement Candidate Expected", key: "totalTHSCPlacementCandidateExpected" },
            { header: "Total THSC Placement Candidate Assigned", key: "totalTHSCPlacementAssignedCandidate" },
            { header: "Total THSC Placement Candidate Delivered", key: "totalTHSCPlacementCandidateDelivered" },
            { header: "Total THSC Placement Candidate Dropout", key: "totalCandidateTHSCPlacementDropout" },
            { header: "Total THSC Placement Male Candidate", key: "totalTHSCPlacementMaleCount" },
            { header: "Total THSC Placement Female Candidate", key: "totalTHSCPlacementFemaleCount" },
        ]
        if (selectedMonth && selectedMonth.value !== "all") {
            thscPlacementColumns = [{}, ...thscPlacementColumns];
        }
        const thscPlacementMainHeaderRow = worksheet.addRow(thscPlacementColumns.map((column) => column.header));
        thscPlacementMainHeaderRow.font = { bold: true };
        thscPlacementMainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            thscPlacementColumns.forEach((column) => {
                if (Object.entries(column).length === 0) {
                    if (dataIndex === 0) {
                        dataRow.push(`Monthly (${selectedMonth.label})`);
                    }
                    else {
                        dataRow.push("YTD");
                    }
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };;
        })
        worksheet.addRow("");
        worksheet.addRow("");

        data.forEach((row, dataIndex) => {
            const dataRow = [];
            thsccolumns.forEach((column, index) => {
                let columnIndex = 0;
                if (monthsFlag) {
                    columnIndex = 1;
                }
                if (index === columnIndex && dataIndex === 0) {
                    dataRow.push("TIMES PRO");
                }
                else {
                    if (dataIndex === 0) {
                        dataRow.push("");
                    }
                }
            });
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        let timesProcolumns = [
            { header: "Total TimesPro Project", key: "totalTimesProProjectCount" },
            { header: "Total TimesPro Active Project", key: "totalTimesProActiveProject" },
            { header: "Total TimesPro Project Complete", key: "totalTimesProProjectComplete" },
            { header: "Total TimesPro Candidate Expected", key: "totalTimesProCandidateExpected" },
            { header: "Total TimesPro Candidate Assigned", key: "totalTimesProAssignedCandidate" },
            { header: "Total TimesPro Candidate Delivered", key: "totalTimesProCandidateDelivered" },
            { header: "Total TimesPro Candidate Dropout", key: "totalTimeProCandidateDropout" },
            { header: "Total TimesPro Male Candidate", key: "totalTimesProMaleCount" },
            { header: "Total TimesPro Female Candidate", key: "totalTimesProFemaleCount" },
        ]
        if (selectedMonth && selectedMonth.value !== "all") {
            timesProcolumns = [{}, ...timesProcolumns];
        }
        const timsePromainHeaderRow = worksheet.addRow(timesProcolumns.map((column) => column.header));
        timsePromainHeaderRow.font = { bold: true };
        timsePromainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            timesProcolumns.forEach((column) => {
                if (Object.entries(column).length === 0) {
                    if (dataIndex === 0) {
                        dataRow.push(`Monthly (${selectedMonth.label})`);
                    }
                    else {
                        dataRow.push("YTD");
                    }
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };;
        })
        worksheet.addRow("");
        worksheet.addRow("");
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            thsccolumns.forEach((column, index) => {
                let columnIndex = 0;
                if (monthsFlag) {
                    columnIndex = 1;
                }
                if (index === columnIndex && dataIndex === 0) {
                    dataRow.push("CSR Project");
                }
                else {
                    if (dataIndex === 0) {
                        dataRow.push("");
                    }
                }
            });
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        let csrProjectcolumns = [
            { header: "Total CSR Project", key: "csrProjectCount" },
            { header: "Total CSR Active Project", key: "csrActiveProject" },
            { header: "Total CSR Project Complete", key: "csrProjectCompletedCount" },
            { header: "Total CSR Candidate Expected", key: "csrCandidateExpected" },
            { header: "Total CSR Candidate Assigned", key: "csrAssignedCandidate" },
            { header: "Total CSR Candidate Delivered", key: "csrCandidateDelivered" },
            { header: "Total CSR Candidate Dropout", key: "csrCandidateDropout" },
            { header: "Total CSR Male Candidate", key: "csrMaleCount" },
            { header: "Total CSR Female Candidate", key: "csrFemaleCount" },
        ]
        if (selectedMonth && selectedMonth.value !== "all") {
            csrProjectcolumns = [{}, ...csrProjectcolumns];
        }
        const csrmainHeaderRow = worksheet.addRow(csrProjectcolumns.map((column) => column.header));
        csrmainHeaderRow.font = { bold: true };
        csrmainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            csrProjectcolumns.forEach((column) => {
                if (Object.entries(column).length === 0) {
                    if (dataIndex === 0) {
                        dataRow.push(`Monthly (${selectedMonth.label})`);
                    }
                    else {
                        dataRow.push("YTD");
                    }
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };;
        })
        worksheet.addRow("");
        worksheet.addRow("");
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            thsccolumns.forEach((column, index) => {
                let columnIndex = 0;
                if (monthsFlag) {
                    columnIndex = 1;
                }
                if (index === columnIndex && dataIndex === 0) {
                    dataRow.push("Facilities Management");
                }
                else {
                    if (dataIndex === 0) {
                        dataRow.push("");
                    }
                }
            });
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        let facilityManagmentcolumns = [
            { header: "Total Facilities Management Project", key: "totalFacilitiesManagementProjectCount" },
            { header: "Total Facilities Management Active Project", key: "totalFacilitiesManagementActiveProject" },
            { header: "Total Facilities Management Project Complete", key: "totalFacilitiesManagementProjectComplete" },
            { header: "Total Facilities Management Candidate Expected", key: "totalFacilitiesManagementCandidateExpected" },
            { header: "Total Facilities Management Candidate Assigned", key: "totalFacilitiesManagementAssignedCandidate" },
            { header: "Total Facilities Management Candidate Delivered", key: "totalFacilitiesManagementCandidateDelivered" },
            { header: "Total Facilities Management Candidate Dropout", key: "totalCandidateFacilitiesManagementDropout" },
            { header: "Total Facilities Management Male Candidate", key: "totalFacilitiesManagementMaleCount" },
            { header: "Total Facilities Management Female Candidate", key: "totalFacilitiesManagementFemaleCount" },
        ]
        if (selectedMonth && selectedMonth.value !== "all") {
            facilityManagmentcolumns = [{}, ...facilityManagmentcolumns];
        }
        const facilityManagementMainHeaderRow = worksheet.addRow(facilityManagmentcolumns.map((column) => column.header));
        facilityManagementMainHeaderRow.font = { bold: true };
        facilityManagementMainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            facilityManagmentcolumns.forEach((column) => {
                if (Object.entries(column).length === 0) {
                    if (dataIndex === 0) {
                        dataRow.push(`Monthly (${selectedMonth.label})`);
                    }
                    else {
                        dataRow.push("YTD");
                    }
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };;
        })
        worksheet.addRow("");
        worksheet.addRow("");
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            csrProjectcolumns.forEach((column, index) => {
                let columnIndex = 0;
                if (monthsFlag) {
                    columnIndex = 1;
                }
                if (index === columnIndex && dataIndex === 0) {
                    dataRow.push("TOTAL CANDIDATES");
                }
                else {
                    if (dataIndex === 0) {
                        dataRow.push("");
                    }
                }
            });
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        let colorCellIndex = 1;
        let headerCellIndex = [1, 6, 11, 16, 21, 26, 31];
        if (selectedMonth && selectedMonth.value !== "all") {
            colorCellIndex = 2;
            headerCellIndex = [1, 7, 13, 19, 25, 31 ,37];
        }
        const headerCell = worksheet.getCell(headerCellIndex[0], colorCellIndex);
        headerCell.alignment = { vertical: "middle", horizontal: "center" };
        headerCell.font = { bold: true };
        headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff285c60" }
        };
        const headerCellTHSC = worksheet.getCell(headerCellIndex[1], colorCellIndex);
        headerCellTHSC.alignment = { vertical: "middle", horizontal: "center" };
        headerCellTHSC.font = { bold: true };
        headerCellTHSC.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };
        const headerCellTHSCPlacement = worksheet.getCell(headerCellIndex[2], colorCellIndex);
        headerCellTHSCPlacement.alignment = { vertical: "middle", horizontal: "center" };
        headerCellTHSCPlacement.font = { bold: true };
        headerCellTHSCPlacement.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };
        const headerCellTimsePro = worksheet.getCell(headerCellIndex[3], colorCellIndex);
        headerCellTimsePro.alignment = { vertical: "middle", horizontal: "center" };
        headerCellTimsePro.font = { bold: true };
        headerCellTimsePro.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };
        const headerCsr = worksheet.getCell(headerCellIndex[4], colorCellIndex);
        headerCsr.alignment = { vertical: "middle", horizontal: "center" };
        headerCsr.font = { bold: true };
        headerCsr.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };
        const headerFacilityManagement = worksheet.getCell(headerCellIndex[5], colorCellIndex);
        headerFacilityManagement.alignment = { vertical: "middle", horizontal: "center" };
        headerFacilityManagement.font = { bold: true };
        headerFacilityManagement.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };
        const headerCellCandidates = worksheet.getCell(headerCellIndex[6], colorCellIndex);
        headerCellCandidates.alignment = { vertical: "middle", horizontal: "center" };
        headerCellCandidates.font = { bold: true };
        headerCellCandidates.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff285c60" }
        };


        let candidatecolumns = [
            { header: "Total No of Candidate", key: "totalNoOfCandidate" },
            { header: "Total Candidate Placed", key: "totalPlacedCandidate" },
            { header: "Total Candidate Dropout", key: "totalDropoutCandidate" },
            { header: "Total Candidate Assigned", key: "totalsAssignedCandidate" },
            { header: "Total Male Candidate", key: "maleCount" },
            { header: "Total Female Candidate", key: "femaleCount" }
        ]
        if (selectedMonth && selectedMonth.value !== "all") {
            candidatecolumns = [{}, ...candidatecolumns];
        }
        const candidateHeaderRow = worksheet.addRow(candidatecolumns.map((column) => column.header));
        candidateHeaderRow.font = { bold: true };
        candidateHeaderRow.alignment = { vertical: "middle", horizontal: "center" };
        data.forEach((row, dataIndex) => {
            const dataRow = [];
            candidatecolumns.forEach((column) => {
                if (Object.entries(column).length === 0) {
                    if (dataIndex === 0) {
                        dataRow.push(`Monthly (${selectedMonth.label})`);
                    }
                    else {
                        dataRow.push("YTD");
                    }
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };;
        })

        worksheet.columns.forEach(column => {
            column.eachCell(cell => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const todayDate = moment(Date.now()).format("DD_MM_YYYY-HH_mm");
        let fileName = `AdminDashboard-${selectedFinanceYear.label}-${selectedMonth.label}-${todayDate}.xlsx`;
        saveAs(new Blob([buffer]), fileName);
        setDownloadLoading(false);
    }

    const handletabChange = (tab) => {
        setSelectedTab(tab);
        setProjectType({ label: "Total", value: "total" });
        setFinanceYear({ label: "All Years", value: "all" });
        setMonth({ label: "All Months", value: "all" });
        setProjectTotalLoadingComplete(false);
        if (tab === "Employer" || tab === "Partner") {
            setShowOtherDashboard(true);
        }
        else {
            setShowOtherDashboard(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (metaData && metaData.length === 0) {
                const metaResult = await fetchMetadata();
                setMetaData(metaResult);
            }
        }
        const projectTypeComponentList = metaData[0].meta_data.project_type.map((item) => {
            if (projectType && item.toLowerCase() === projectType) {
                setProjectType({ label: item, value: item.toLowerCase() });
            }
            return ({ label: item, value: item.toLowerCase() });
        })
        projectTypeComponentList.unshift({ label: "Total", value: "total" });
        setProjectTypeComponent(projectTypeComponentList);
        fetchData()
    }, [metaData, projectType]);

    const changeProjectType = (event) => {
        setProjectTotalLoadingComplete(false);
        setProjectType(event);
    }

    const changeFinanceYear = (event) => {
        if (event.value === "all") {
            setMonth({ label: "All Months", value: "all" });
        }
        setLoadingComplete(false);
        setFinanceYear(event);
    }

    const changeEmployerYear = (event) => {
        if (event.value === "all") {
            setMonth({ label: "All Months", value: "all" });
        }
        setProjectTotalLoadingComplete(false);
        setFinanceYear(event);
    }

    const changeFinanceMonth = (event) => {
        if (selectedMonth !== event) {
            setLoadingComplete(false);
        }
        setMonth(event);
    }

    return (<>
        {
            loadingComplete === false ? (
                <Spinner />
            ) : (
                <div className={`admin-dashboard-outer-wrapper ${showOtherDashboard ? "admin-employer-dashboard-wrapper" : ""}`}>
                    <div className="admin-dasboard-icon admin-tab-icon">
                        <div>
                            <i className={`${METER_ICON} list-table-icon`}></i>
                            {
                                dashboardTab.map((tab) => (
                                    <button
                                        key={tab}
                                        onClick={() => handletabChange(tab)}
                                        className={`tab-button ${selectedTab === tab ? "selected" : ""}`}
                                    >
                                        {tab}
                                    </button>
                                ))
                            }
                        </div>
                        {
                            downloadLoading === true ? (
                                <div className="spinner-loader"></div>
                            ) : (
                                selectedTab === "Admin" && (
                                    <button className="brand-button" onClick={exportToExcel}>Download</button>
                                )
                            )
                        }
                    </div>
                    <div className={`admin-dashboard-outer-div ${showOtherDashboard ? "admin-employer-dashboard-outer-div" : ""}`}>
                        {
                            showOtherDashboard === true ? (<>
                                <div className="admin-dashboard-filter-dropdown-outer-div">
                                    <div className="form-wrap">
                                        <div className="form-wrap-inner">
                                            <Select
                                                placeholder={`Select Project Type`}
                                                value={projectType}
                                                onChange={changeProjectType}
                                                options={projectTypeComponent}
                                                isSearchable={true}
                                                isMulti={false}
                                                maxMenuHeight={200}
                                            />
                                        </div>
                                    </div>
                                    <i className={`${FILTER_ICON} list-table-icon`}></i>
                                    <label className="form-label-outside" htmlFor="general-information-skills">Project</label>
                                    <div className="form-wrap ml-4">
                                        <div className="form-wrap-inner">
                                            <Select
                                                placeholder={"Select Finance year"}
                                                value={selectedFinanceYear}
                                                onChange={changeEmployerYear}
                                                options={generateFinanceYearOptions()}
                                                isSearchable={false}
                                                isMulti={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-wrap month-div-dropdown">
                                        <div className="form-wrap-inner">
                                            <Select
                                                placeholder={`Select Finance Month`}
                                                value={selectedFinanceYear.value !== "all" ? selectedMonth : { label: "All Months", value: "all" }}
                                                onChange={changeFinanceMonth}
                                                options={FINANCE_MONTHS}
                                                isSearchable={true}
                                                isMulti={false}
                                                isDisabled={selectedFinanceYear.value !== "all" ? false : true}
                                            />
                                        </div>
                                    </div>
                                    <i className={`${CALENDAR_ICON} list-table-icon`}></i>
                                    <label className="form-label-outside" htmlFor="general-information-skills">FY</label>
                                </div>
                                {
                                    selectedTab === "Employer" ? (
                                        <AdminEmployerDashboard totalProjectData={totalProjectData} employerTab={true} projectType={projectType.value} projectTotalLoadingComplete={projectTotalLoadingComplete} selectedYear={selectedFinanceYear.value} selectedMonth={selectedMonth.value} />
                                    ) : (
                                        <AdminEmployerDashboard totalProjectData={totalProjectData} employerTab={false} projectType={projectType.value} projectTotalLoadingComplete={projectTotalLoadingComplete} selectedYear={selectedFinanceYear.value} selectedMonth={selectedMonth.value} />
                                    )
                                }
                            </>) : (<>
                                <div className="admin-dashboard-filter-dropdown-finance-div">
                                    <div className="form-wrap">
                                        <div className="form-wrap-inner">
                                            <Select
                                                placeholder={`Select Finance Year`}
                                                value={selectedFinanceYear}
                                                onChange={changeFinanceYear}
                                                options={generateFinanceYearOptions()}
                                                isSearchable={false}
                                                isMulti={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-wrap">
                                        <div className="form-wrap-inner">
                                            <Select
                                                placeholder={`Select Finance Month`}
                                                value={selectedFinanceYear.value !== "all" ? selectedMonth : { label: "All Months", value: "all" }}
                                                onChange={changeFinanceMonth}
                                                options={FINANCE_MONTHS}
                                                isSearchable={true}
                                                isMulti={false}
                                                isDisabled={selectedFinanceYear.value !== "all" ? false : true}
                                            />
                                        </div>
                                    </div>
                                    <i className={`${CALENDAR_ICON} list-table-icon`}></i>
                                    <label className="form-label-outside" htmlFor="general-information-skills">FY</label>
                                </div>
                                {
                                    selectedMonth && selectedMonth.value !== "all" && (
                                        <div className="monthly-ytd-label-div">
                                            <span>Monthly / YTD</span>
                                            <span className="second-span-monthly-ytd">Monthly / YTD</span>
                                            <span className="third-span-monthly-ytd">Monthly / YTD</span>
                                        </div>
                                    )
                                }
                                <div className="admin-dashboad-total-div">
                                    <div className="total-title-div">
                                        <div className="total-title-header-div">
                                            <p>Total</p>
                                        </div>
                                    </div>
                                    <div className="total-value-outer-div">
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Total Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalProjectCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalProjectCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Expected</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalCandidateExpected}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalCandidateExpected}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Dropout</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalCandidateDropout}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalCandidateDropout}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Unit</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalUnitRegistered}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalUnitRegistered}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Active Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalActiveProject}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalActiveProject}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Assigned</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalAssignedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalAssignedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Male</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalMaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalMaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Complete Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalProjectCompletedCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalProjectCompletedCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Delivered</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalCandidateDelivered}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalCandidateDelivered}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Female</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFemaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFemaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seprator-line"></div>
                                <div className="admin-dashboad-total-div">
                                    <div className="total-title-div total-div-background-color">
                                        <div className="total-title-header-div">
                                            <p>THSC RTD</p>
                                        </div>
                                    </div>
                                    <div className="total-value-outer-div">
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Total Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCProjectCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCProjectCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Expected</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCCandidateExpected}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCCandidateExpected}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Dropout</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalCandidateThscDropout}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalCandidateThscDropout}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Active Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCActiveProject}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCActiveProject}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Assigned</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCAssignedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCAssignedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Male</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCMaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCMaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Complete Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCProjectComplete}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCProjectComplete}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Delivered</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCCandidateDelivered}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCCandidateDelivered}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Female</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCFemaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCFemaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seprator-line"></div>
                                <div className="admin-dashboad-total-div">
                                    <div className="total-title-div total-div-background-color">
                                        <div className="total-title-header-div">
                                            <p>THSC Placement</p>
                                        </div>
                                    </div>
                                    <div className="total-value-outer-div">
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Total Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementProjectCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementProjectCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Expected</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementCandidateExpected}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementCandidateExpected}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Dropout</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalCandidateTHSCPlacementDropout}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalCandidateTHSCPlacementDropout}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Active Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementActiveProject}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementActiveProject}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Assigned</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementAssignedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementAssignedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Male</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementMaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementMaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Complete Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementProjectComplete}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementProjectComplete}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Delivered</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementCandidateDelivered}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementCandidateDelivered}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Female</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTHSCPlacementFemaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTHSCPlacementFemaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seprator-line"></div>
                                <div className="admin-dashboad-total-div">
                                    <div className="total-title-div total-div-background-color">
                                        <div className="total-title-header-div">
                                            <p>Times Pro</p>
                                        </div>
                                    </div>
                                    <div className="total-value-outer-div">
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Total Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProProjectCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProProjectCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Expected</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProCandidateExpected}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProCandidateExpected}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Dropout</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalCandidateTimesDropout}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalCandidateTimesDropout}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Active Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProActiveProject}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProActiveProject}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Assigned</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProAssignedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProAssignedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Male</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProMaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProMaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Complete Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProProjectComplete}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProProjectComplete}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Delivered</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProCandidateDelivered}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProCandidateDelivered}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Female</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalTimesProFemaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalTimesProFemaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seprator-line"></div>
                                <div className="admin-dashboad-total-div">
                                    <div className="total-title-div total-div-background-color">
                                        <div className="total-title-header-div">
                                            <p>CSR Project</p>
                                        </div>
                                    </div>
                                    <div className="total-value-outer-div">
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Total Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrProjectCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrProjectCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Expected</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrCandidateExpected}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrCandidateExpected}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Dropout</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrCandidateDropout}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrCandidateDropout}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Active Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrActiveProject}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrActiveProject}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Assigned</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrAssignedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrAssignedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Male</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrMaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrMaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Complete Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrProjectCompletedCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrProjectCompletedCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Delivered</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrCandidateDelivered}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrCandidateDelivered}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Female</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.csrFemaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.csrFemaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seprator-line"></div>
                                <div className="admin-dashboad-total-div">
                                    <div className="total-title-div total-div-background-color">
                                        <div className="total-title-header-div">
                                            <p className="dashboard-facility-management-p">Facilities Management</p>
                                        </div>
                                    </div>
                                    <div className="total-value-outer-div">
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Total Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementProjectCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementProjectCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Expected</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementCandidateExpected}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementCandidateExpected}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidates Dropout</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalCandidateFacilitiesManagementDropout}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalCandidateFacilitiesManagementDropout}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Active Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementActiveProject}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementActiveProject}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Assigned</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementAssignedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementAssignedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Male</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementMaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementMaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div total-value-margin">
                                            <div className="total-value-div">
                                                <p>Complete Projects</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementProjectComplete}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementProjectComplete}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Delivered</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementCandidateDelivered}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementCandidateDelivered}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Female</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalProjectData.totalFacilitiesManagementFemaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdProjectData.totalFacilitiesManagementFemaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seprator-line"></div>
                                <div className="admin-dashboad-total-div">
                                    <div className="total-title-div">
                                        <div className="total-title-header-div">
                                            <p>Total Candidates</p>
                                        </div>
                                    </div>
                                    <div className="total-value-outer-div">
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Total Candidates</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalCandidateData.totalNoOfCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdCandidateData.totalNoOfCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Candidate Assigned</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalCandidateData.totalAssignedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdCandidateData.totalAssignedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Placed</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalCandidateData.totalPlacedCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdCandidateData.totalPlacedCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Male</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalCandidateData.maleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdCandidateData.maleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-value-inner-div">
                                            <div className="total-value-div">
                                                <p>Dropout</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalCandidateData.totalDropoutCandidate}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdCandidateData.totalDropoutCandidate}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="total-value-div">
                                                <p>Total Female</p>
                                                <div className="ytd-box-outer-div">
                                                    <div className="total-value-box margin-right-7">
                                                        {totalCandidateData.femaleCount}
                                                    </div>
                                                    {
                                                        selectedMonth && selectedMonth.value !== "all" && (
                                                            <div className="total-value-box">
                                                                {ytdCandidateData.femaleCount}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        }
                    </div>
                </div>
            )
        }
    </>);
}
export default AdminDashboardPage;