import * as XLSX from 'xlsx';
import { emailPattern, PAN_NUMBER_PATTERN, CIN_NUMBER_PATTERN, GSTIN_NUMBER_PATTERN } from "assets/constants/Constants";

export function importPartner(event, trainingPartnerList = []) {
    return new Promise(async (resolve, reject) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0]; // First sheet
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, rawNumbers: true });
            let partnerList = [];
            let columns = [];
            if (jsonData.length > 0) {
                columns = jsonData[0];
                columns = columns.map((item) => {
                    let itemElement = item.replaceAll(" ", "");
                    itemElement = itemElement.replaceAll(".", "");
                    itemElement = itemElement.trim();
                    itemElement = itemElement.toLowerCase();
                    return itemElement;
                });
                for (let i = 1; i < jsonData.length; i++) {
                    const keys = columns;
                    const values = jsonData[i].map((item, index) => {
                        if (item && typeof item === "string") {
                            return item.trim();
                        }
                        else if (item) {
                            return item;
                        }
                        else {
                            return "";
                        }
                    });
                    if (values && values.length > 0) {
                        const result = keys.map((key, index) => ({ [key]: values[index] }))
                            .reduce((obj, item) => ({ ...obj, ...item }), {});
                        partnerList.push(result);
                    }
                }
            }

            let employerParamsList = [];
            const employerListProcess = partnerList.map(async (partnerItem) => {
                const param = {
                    partner_name: "",
                    training_partner: "",
                    representative_name: "",
                    representative_phone_no: "",
                    representative_email_id: "",
                    address_line_one: "",
                    address_line_two: "",
                    pin_code: "",
                    state: "",
                    city: "",
                    pan_card_number: "",
                    invoice_name: "",
                    cin_number: "",
                    gstin_number: "",
                    partner_bank_name: "",
                    partner_bank_address: "",
                    partner_account_number: "",
                    validPartner: true,
                    errorMessage: [],
                };

                if (partnerItem.addressline1) {
                    param.address_line_one = partnerItem.addressline1;
                } else {
                    param.validPartner = false;
                    param.errorMessage.push("Please enter address");
                }

                if (partnerItem.addressline2) {
                    param.address_line_two = partnerItem.addressline2;
                }

                if (partnerItem.cinnumber) {
                    if (!CIN_NUMBER_PATTERN.test(partnerItem.cinnumber)) {
                        param.validPartner = false;
                        param.errorMessage.push("Please enter valid CIN number");
                    } else {
                        param.cin_number = partnerItem.cinnumber;
                    }
                }

                if (partnerItem.city) {
                    param.city = partnerItem.city;
                }

                if (partnerItem.partnername) {
                    param.partner_name = partnerItem.partnername;
                } else {
                    param.validPartner = false;
                    param.errorMessage.push("Please enter program name");
                }

                if (partnerItem.pancardnumber) {
                    if (!PAN_NUMBER_PATTERN.test(partnerItem.pancardnumber)) {
                        param.validPartner = false;
                        param.errorMessage.push("Please enter valid PAN number");
                    } else {
                        param.pan_card_number = partnerItem.pancardnumber;
                    }
                }

                if (partnerItem.pincode) {
                    if (partnerItem.pincode.toString().length !== 6) {
                        param.validPartner = false;
                        param.errorMessage.push("Please enter valid pin number");
                    } else {
                        param.pin_code = partnerItem.pincode;
                    }
                } else {
                    param.validPartner = false;
                    param.errorMessage.push("Please enter pin number");
                }

                if (partnerItem.state) {
                    param.state = partnerItem.state;
                }

                if (partnerItem.partneraccountnumber) {
                    param.partner_account_number = partnerItem.partneraccountnumber;
                }

                if (partnerItem.partnerbankaddress) {
                    param.partner_bank_address = partnerItem.partnerbankaddress;
                }

                if (partnerItem.partnerbankname) {
                    param.partner_bank_name = partnerItem.partnerbankname;
                }

                if (partnerItem.gstinnumber) {
                    if (!GSTIN_NUMBER_PATTERN.test(partnerItem.gstinnumber)) {
                        param.validPartner = false;
                        param.errorMessage.push("Please enter valid GST number");
                    } else {
                        param.gstin_number = partnerItem.gstinnumber;
                    }
                }

                if (partnerItem.trainingpartner) {
                    param.training_partner = partnerItem.trainingpartner;
                    if (trainingPartnerList && trainingPartnerList.length > 0) {
                        const duplicatePartnerFound = trainingPartnerList.find((findItem) => findItem.training_partner === partnerItem.trainingpartner);
                        if (duplicatePartnerFound) {
                            param.validPartner = false;
                            param.errorMessage.push("This training partner is already present in the system. Please enter different training partner");
                        }
                    }
                } else {
                    param.validPartner = false;
                    param.errorMessage.push("Please enter training partner");
                }

                if (partnerItem.representativeemailid) {
                    if (!emailPattern.test(partnerItem.representativeemailid)) {
                        param.validPartner = false;
                        param.errorMessage.push("Please enter valid representative email");
                    } else {
                        param.representative_email_id = partnerItem.representativeemailid;
                    }
                } else {
                    param.validPartner = false;
                    param.errorMessage.push("Please enter representative email");
                }

                if (partnerItem.representativename) {
                    param.representative_name = partnerItem.representativename;
                } else {
                    param.validPartner = false;
                    param.errorMessage.push("Please enter representative name");
                }

                if (partnerItem.representativemobileno) {
                    param.representative_phone_no = partnerItem.representativemobileno;
                } else {
                    param.validPartner = false;
                    param.errorMessage.push("Please enter representative mobile number");
                }

                if (partnerItem.billingentityname) {
                    param.invoice_name = partnerItem.billingentityname;
                }

                employerParamsList.push(param);
            })

            await Promise.all(employerListProcess);
            resolve(employerParamsList);
        }
        reader.onerror = (event) => {
            console.error("File could not be read:", event.target.error);
            resolve();
        };

        reader.readAsArrayBuffer(file);
    })
}