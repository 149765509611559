import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import SideNavigationBar from "components/SidebarNav/Sidebar";
import TopNavigationBar from "components/navbars/NavigationBar";
import Footer from "components/footer";
import { ProtectedRoute } from "components/ProtectedRoute";
import AdminRoutes  from "components/RouteComponent/AdminRoutes";
import EmployerRoutes from "components/RouteComponent/EmployerRoutes";
import PartnerRoutes from "components/RouteComponent/PartnerRoutes";
// pages
import LoginPage from "views/login/LoginPage";
import UserPage from "views/users/UserPage";
import CreateUserPage from "views/users/CreateUserPage";
import RolesPage from "views/role/RolePage";
import ForgotPassword from "views/login/ForgotPassword";
import Dashboard from "views/EmployerDashboard/Dashboard";
import AddEditEmployer from "views/EmployerDashboard/CreateEmployer"
import CandidatePage from "views/Candidate/CadidatePage";
import AddEditCandidate from "views/Candidate/CreateCandidate";
import TraningPartner from "views/Partner/PartnerPage";
import AddEditPartner from "views/Partner/CreatePartner";
import CreateFieldSchema from "views/Data/CreateFieldSchema";
import FieldSchemaPage from "views/Data/FieldSchemaPage";
import CreateStepPage from "views/Data/CreateStepPage";
import StepListPage from "views/Data/StepListPage";
import ProgramTracker from "views/ProgramTracker/ProgramTracking";
import CreateProgramPage from "views/Program/CreateProgramPage";
import ProgramListPage from "views/Program/ProgramListPage";
import CandidateManagementPage from "views/Program/CandidateManagementPage";
import AdminDashboardPage from "views/Admin/AdminDashboardPage";
import EmployerDashboardPage from "views/EmployerDashboard/EmployerDashboardPage";
import PartnerDashboardPage from "views/Partner/PartnerDashboardPage";
import CandidateAcceptance from "views/Candidate/AcceptanceCandidate";
import StepCompletePage from "views/Data/StepCompletePage";

const ProgramListPageWrapper = props => {
    return <ProgramListPage {...props} />;
};

const CreateUserPageWrapper = props => {
    return <CreateUserPage {...props} />;
};

const CreateFieldSchemaWrapper = props => {
    return <CreateFieldSchema {...props} />;
};

const FieldSchemaPageWrapper = props => {
    return <FieldSchemaPage {...props} />;
};

const CreateStepPageWrapper = props => {
    return <CreateStepPage {...props} />;
};

const StepListPageWrapper = props => {
    return <StepListPage {...props} />;
};

const ProgramTrackerWrapper = props => {
    return <ProgramTracker {...props} />;
};

const CreateProgramPageWrapper = props => {
    return <CreateProgramPage {...props} />;
};

const CandidateManagementPageWrapper = props => {
    return <CandidateManagementPage {...props} />;
};

const AdminDashboardPageWrapper = props => {
    return <AdminDashboardPage {...props} />;
};

const EmployerDashboardPageWrapper = props => {
    return <EmployerDashboardPage {...props} />;
};

const PartnerDashboardPageWrapper = props => {
    return <PartnerDashboardPage {...props} />;
};

const StepCompletePageWrapper = props => {
    return <StepCompletePage {...props} />;
};

export const RouterComponent = withRouter(({ location, history }) => {
    return (
        <div className="app-container">
            <div className="header-container top-navigation-bar-container">
                {
                    // (location.pathname === "/login-page" || location.pathname.includes("/login/") || location.pathname === "/" || location.pathname === '/forget-password') ? "" : <TopNavigationBar history={history} />
                    <TopNavigationBar history={history} />
                }
            </div>
            <div className="side-navigation-bar-container">
                {
                    // (location.pathname === "/login-page" || location.pathname.includes("/login/") || location.pathname === "/" || location.pathname === '/forget-password') ? "" : <SideNavigationBar history={history} />
                    <SideNavigationBar history={history} />
                }
            </div>
            <div className="main-container">
                <Switch>
                    <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
                    <Route path="/forget-password" render={(props) => <ForgotPassword {...props} />} />
                    <Route path="/profile/jobseeker/:candidateEmail" render={(props) => <CandidateAcceptance {...props} />} />

                    <AdminRoutes path="/employer-page" component={Dashboard} />
                    <AdminRoutes path="/add-employer-page" component={AddEditEmployer} />
                    <AdminRoutes path="/user-page" component={UserPage} />
                    <AdminRoutes path="/create-user-page" component={CreateUserPageWrapper} />
                    <AdminRoutes path="/roles-page" component={RolesPage} />
                    <AdminRoutes path="/candidate-page" component={CandidatePage} />
                    <AdminRoutes path="/add-candidate-page" component={AddEditCandidate} />
                    <AdminRoutes path="/partner-page" component={TraningPartner} />
                    <AdminRoutes path="/add-partner-page" component={AddEditPartner} />
                    <AdminRoutes exact path="/create-field-schema-page" component={CreateFieldSchemaWrapper} />
                    <AdminRoutes exact path="/field-schema-page" component={FieldSchemaPageWrapper} />
                    <AdminRoutes exact path="/create-step-page" component={CreateStepPageWrapper} />
                    <AdminRoutes exact path="/step-list-page" component={StepListPageWrapper} />
                    <ProtectedRoute exact path="/program-tracking/:id" component={ProgramTrackerWrapper} />
                    <AdminRoutes exact path="/create-program-page" component={CreateProgramPageWrapper} />
                    <ProtectedRoute exact path="/project-list" component={ProgramListPageWrapper} />
                    <ProtectedRoute exact path="/project-candidate-managment" component={CandidateManagementPageWrapper} />
                    <AdminRoutes exact path="/admin-dashboard-page" component={AdminDashboardPageWrapper} />
                    <EmployerRoutes exact path="/employer-dashboard-page" component={EmployerDashboardPageWrapper} />
                    <PartnerRoutes exact path="/partner-dashboard-page" component={PartnerDashboardPageWrapper} />
                    <ProtectedRoute exact path="/step-complete/:stepId/:projectId/:candidateId" component={StepCompletePageWrapper} />
                    <Redirect
                        to={{
                            pathname: "/login-page",
                            state: {
                                from: location
                            }
                        }}
                    />
                </Switch>
            </div>
            <div className="footer-container">
                {
                    // (location.pathname === "/login-page" || location.pathname.includes("/login/") || location.pathname === "/" || location.pathname === "/forget-password") ? "" : <Footer />
                    <Footer />
                }
            </div>
        </div>
    );
});
