import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from 'context/AuthProvider';
import { hasRoutePermission } from "components/Permission";

const AdminRoutes = ({ component: Component, ...rest }) => {
    const authContext = useContext(AuthContext);
    const userData = JSON.parse(localStorage.getItem("userData"));
    let permissionFlag = false;
    let roleName = "";
    if (userData) {
        roleName = userData.user_role.replaceAll(" ", "");
        if (roleName === "superadmin" || roleName === "admin") {
            permissionFlag = hasRoutePermission(rest.path);
        }
    }
    return (
        <Route
            {...rest}
            render={props => {
                if (userData && authContext.isAuthenticated()) {
                    if (roleName === "superadmin" || roleName === "admin") {
                        if (permissionFlag) {
                            return (<Component {...props} />);
                        }
                        else {
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/project-list",
                                        state: {
                                            from: props.location
                                        }
                                    }}
                                />
                            )
                        }
                    }
                    else if (roleName === "employer" || roleName === "trainingpartner") {
                        let pathName = "/partner-dashboard-page";
                        if (roleName === "employer") {
                            pathName = "/employer-dashboard-page";
                        }
                        return (
                            <Redirect
                                to={{
                                    pathname: pathName,
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        )
                    }
                    else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/project-list",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        )
                    }
                }
                else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login-page",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    )
                }
            }
            }
        />
    )
}
export default AdminRoutes;
