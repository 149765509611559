import React, { Component } from 'react'
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';
import AuthContext from 'context/AuthProvider';
import ShowToast from 'components/ShowToast';
import { USER_ICON, LOCK_ICON } from 'assets/constants/Icons';
import { getRoleCall, getMetaDataCall } from "lib/axiosUtils";

export class LoginPage extends Component {
    constructor(props) {
        super(props)
        let isSiteDropdownVisible = false;
        let showWarningMsg = false;
        localStorage.setItem("loginURL", window.location.href);
        this.state = {
            isLoading: true,
            username: "",
            password: "",
            siteList: [],
            input: {},
            errors: {},
            brandLogo: "",
            selectedSiteId: "",
            isSiteDropdownVisible,
            showWarningMsg,
            currentUrl: window.location.href
        }
    }

    componentDidMount = async () => {
        if (this.context.isAuthenticated()) {
            window.location.href = "/project-list";
        }

        this.setState({
            brandLogo: this.context.BRAND_LOGO,
            isLoading: false
        }, () => {
            this.setSessionForSite();
        });
    }

    setSessionForSite = () => {
        if (this.props.match.params.siteId) {
            localStorage.setItem("siteId", this.props.match.params.siteId);
            this.setState({
                selectedSiteId: this.props.match.params.siteId
            });
        }
    }

    handleInputChange = (event) => {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({
            input,
        });
    }

    formValidate = () => {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["userName"]) {
            isValid = false;
            errors["userName"] = "Please enter your username.";
        }

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }

        this.setState({
            errors: errors,
        });

        return isValid;
    }

    handleSignIn = async (event) => {
        event.preventDefault();
        try {
            if (this.formValidate()) {
                this.setState({
                    isLoading: true
                });
                const responseData = await this.context.signIn(this.state.input.userName, this.state.input.password);
                if (responseData.statusCode === 200) {
                    if (responseData !== null) {
                        if (responseData.data.result.success) {
                            const userRole = await getRoleCall({},{},responseData.data.result.result.role);
                            const getMetaData =  await getMetaDataCall({},{});
                            localStorage.setItem("metadata", JSON.stringify(getMetaData.data.result));
                            if(userRole && userRole.data && userRole.data.success === true){
                                ShowToast('Login successful!', TYPE_SUCCESS, INTERVAL, this.props, "");
                            }
                      
                            if (userRole && userRole.data && userRole.data.result) {
                                if (userRole.data.result.user_role === "employer") {
                                    this.props.history.push({
                                        pathname: "/employer-dashboard-page",
                                    });
                                }
                                else if (userRole.data.result.user_role === "super admin") {
                                    this.props.history.push({
                                        pathname: "/admin-dashboard-page",
                                    });
                                }
                                else if (userRole.data.result.user_role === "training partner") {
                                    this.props.history.push({
                                        pathname: "/partner-dashboard-page",
                                    });
                                }
                                else {
                                    this.props.history.push({
                                        pathname: "/project-list",
                                    });
                                }
                            }
                        }
                        else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                ShowToast(responseData.data.message, TYPE_FAIL, INTERVAL, this.props);
                            });
                        }
                    }
                    else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            ShowToast('Something went wrong. Please try after some time or contact site administrator', TYPE_FAIL, INTERVAL, this.props);
                        });

                    }
                }
                else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        ShowToast('Something went wrong. Please try after some time or contact site administrator', TYPE_FAIL, INTERVAL, this.props);
                    });
                }
            }
        } catch (error) {
            this.setState({
                isLoading: false
            }, () => {
                ShowToast('Authentication failed! Invalid username or password.', TYPE_FAIL, INTERVAL, this.props);
            });
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className={`outer_login_div`}>
                    {
                        <div className="main_div">
                            <div className="brand_logo_view">
                                <img alt="Brand Logo" className="img-no-padding img-responsive" src="/images/new_brand_logo.png" />
                            </div>
                            <div className="title">Login</div>
                            <form action="!#">
                                <div className="input_box">
                                    <input type="text" name="userName" placeholder="Enter Your Username" onChange={this.handleInputChange} />
                                    <div className="icon"><i className={`${USER_ICON} icon`}></i></div>
                                </div>
                                <div className="text-danger ml-2 error_msg">
                                    {this.state.errors.userName}
                                </div>
                                <div className="input_box">
                                    <input type="password" name="password" placeholder="Enter Your Password" onChange={this.handleInputChange} />
                                    <div className="icon"><i className={`${LOCK_ICON} icon`}></i></div>
                                </div>
                                <div className="text-danger ml-2 error_msg">
                                    {this.state.errors.password}
                                </div>
                                <div className="option_div">
                                    <div></div>
                                    <div className="forget_div">
                                        <a href="/forget-password">Forgot password?</a>
                                    </div>
                                </div>
                                <div className={`input_box ${this.context.styles.brandButton}`}>
                                    <button onClick={this.handleSignIn}>Login</button>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            )
        }
    }
}

LoginPage.contextType = AuthContext;
export default LoginPage