import React, { useState } from "react";
import * as XLSX from "xlsx";
import { getTrackingHistoryByUserId, fetchProgramByProgramIdArray, fetchCandidateByCandidateIdArray, getStepListByStepIdArray, getUserDataByIdBatch, getTrackingHistoryByProgramId } from "lib/axiosUtils";
import { COPY_ICON, LOADING_SPIN_SMALL, COPY_ICON_SMALL } from "assets/constants/Icons";
import ShowToast from "components/ShowToast";
import { TYPE_SUCCESS, INTERVAL } from 'assets/constants/Constants';
import moment from "moment";
import { defaultFormatDate } from "lib/utils";

const AuditReport = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const generateAuditReport = async () => {
        try {
            setIsLoading(true);
            let userTrackingData = [];
            const report = [];
            const groupedData = {};

            const programIdData = [];
            const candidateIdData = [];
            const stepIdData = [];
            const userDataIdData = [];

            let programData = props.isFromTracking ? props.programData : [];
            let candidateData = props.isFromTracking ? props.candidateData : [];
            let stepData = props.isFromTracking ? props.stepData : [];

            if (props.isFromTracking) {
                userTrackingData = await getTrackingHistoryByProgramId({}, {}, props.programId);
            } else {
                userTrackingData = await getTrackingHistoryByUserId({}, {}, props.userId);
            }

            if (userTrackingData.data.result.length > 0) {
                userTrackingData.data.result.forEach(doc => {
                    const key = `${doc.program_id}_${doc.candidate_id}_${doc.step_id}`;
                    if (!groupedData[key]) {
                        groupedData[key] = [];
                    }

                    groupedData[key].push(doc);
                    userDataIdData.push(doc.user_id);

                    if (!props.isFromTracking) {
                        programIdData.push(doc.program_id);
                        candidateIdData.push(doc.candidate_id);
                        stepIdData.push(doc.step_id);
                    }
                });

                if (!props.isFromTracking) {
                    programData = await fetchProgramByProgramIdArray({}, Array.from(new Set(programIdData)));
                    programData = programData.data.result;

                    stepData = await getStepListByStepIdArray({}, Array.from(new Set(stepIdData)));
                    stepData = stepData.data.result;

                    const candidatesParam = { candidateId: Array.from(new Set(candidateIdData)) }
                    candidateData = await fetchCandidateByCandidateIdArray({}, candidatesParam);
                    candidateData = candidateData.data.result;
                }

                let fetchUserData = await getUserDataByIdBatch({}, userDataIdData);
                fetchUserData = fetchUserData.data.result;

                for (const key in groupedData) {
                    const docs = groupedData[key];
                    docs.sort((a, b) => new Date(a.created_date_time) - new Date(b.created_date_time));

                    for (let i = 0; i < docs.length; i++) {
                        const currentDoc = docs[i];
                        const previousDoc = i > 0 ? docs[i - 1] : null;

                        const changeDetails = {
                            program_id: currentDoc.program_id,
                            candidate_id: currentDoc.candidate_id,
                            step_id: currentDoc.step_id,
                            user_id: currentDoc.user_id,
                            created_date_time: new Date(currentDoc.created_date_time).toISOString()
                        };

                        // If there is a previous document, compare and note changes
                        if (previousDoc) {
                            changeDetails.changes = JSON.stringify(Object.keys(currentDoc.data).reduce((changes, key) => {
                                if (currentDoc.data[key] !== previousDoc.data[key]) {
                                    changes[key] = { old: previousDoc.data[key], new: currentDoc.data[key] };
                                }
                                return changes;
                            }, {}));
                        } else {
                            changeDetails.changes = "";
                        }

                        changeDetails.data = JSON.stringify(currentDoc.data);
                        report.push(changeDetails);
                    }
                }

                const worksheetData = report.map(item => ({
                    "Program Name": programData.length > 0 ? (programData.find((programitem) => programitem._id === item.program_id).program_name) : "",
                    "Candidate Name": candidateData.length > 0 ?
                        (candidateData.find(candidateitem => candidateitem._id === item.candidate_id) || {}).first_name + " " + (candidateData.find(candidateitem => candidateitem._id === item.candidate_id) || {}).last_name
                        : "",
                    "Step Name": stepData.length > 0 ? stepData.find((stepid) => stepid._id === item.step_id).step_title : "",
                    "Changes": item.changes === "{}" ? "": item.changes,
                    "Data Saved": item.data,
                    "User": fetchUserData.length > 0 ?
                        (fetchUserData.find(useritem => useritem._id === item.user_id) || {}).first_name + " " + (fetchUserData.find(useritem => useritem._id === item.user_id) || {}).last_name
                        : "",
                    "On Date": defaultFormatDate(item.created_date_time)
                }));

                const worksheet = XLSX.utils.json_to_sheet(worksheetData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

                const todayDate = moment(Date.now()).format("DD_MM_YYYY");
                const filePath = `${props.isFromTracking ? programData[0].program_name : fetchUserData[0].first_name + "_" + fetchUserData[0].last_name}_Audit_Report_${todayDate}.xlsx`;

                XLSX.writeFile(workbook, filePath);
                setIsLoading(false);
            } else {
                ShowToast("No Audit Record Found", TYPE_SUCCESS, INTERVAL, "", "");
                setIsLoading(false);
            }
        } catch (error) {
            console.log("Error while generating audit report:", error);
            setIsLoading(false);
        }
    }

    return (
        isLoading ? <i className={LOADING_SPIN_SMALL} /> :
            (
                props.isFromTracking ? (
                    <div className="material-table-export-audit-outer-div">
                        <button className="material-table-export-audit" title="Export Audit Report"
                            onClick={() => { generateAuditReport() }}
                        >
                            <i className={`${COPY_ICON_SMALL}`} />
                        </button>
                    </div>
                ) : (
                    <i className={`${COPY_ICON}`} onClick={() => generateAuditReport()} title="Download Audit Report" />
                )
            )
    )
}

export default AuditReport;