import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { getStepData, createProgramTrackerData, getTrackerDataByDifferentIds, getCandidateById, getProgramData, updateProgramTrackerData } from "lib/axiosUtils";
import { INTERVAL, TYPE_SUCCESS, TYPE_FAIL, DOCUMENTATION_STEP_ID } from "assets/constants/Constants";
import Spinner from 'components/Spinner';
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv6";
import ShowToast from 'components/ShowToast';
import FileDownloadWidget from "components/FileDownloadWidget";
import DocumentationStepPopup from "components/DocumentationStepPopup";
import { sendDroppedEmailCandidate } from "lib/utils";

const StepCompletePage = (props) => {
    const { stepId, projectId, candidateId } = useParams();
    const [stepData, setStepData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [viewStepData, setViewStepData] = useState(null);
    const [candidateData, setCandidateData] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [readMode, setReadMode] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);

    useEffect(() => {
        const fetchStepData = async () => {
            setIsLoading(true);
            try {
                const response = await getStepData({}, {}, stepId);
                setStepData(response.data.result);
                setIsLoading(false);
            }
            catch (error) {
                console.log("Error Occurred while fetching step Data", error);
                setIsLoading(false);
            }
        }

        fetchStepData();
    }, [stepId])

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await getProgramData({}, {}, projectId);
                setProjectData(response.data.result);
            }
            catch (error) {
                console.log("Error Occurred while fetching project data");
            }
        }
        fetchProjectData()
    }, [projectId]);

    useEffect(() => {

        const fetchCandidateData = async () => {
            try {
                const response = await getCandidateById({}, {}, candidateId);
                setCandidateData(response.data.result);
            }
            catch (error) {
                console.log("Error Occurred while fetching candidate Data:", error);
            }
        }

        fetchCandidateData();
    }, [candidateId])

    const setDocumentStepsSchema = (formData) => {
        if (viewStepData && readMode === true) {
            return {
                ...formData.ui_schema, // Use the existing UI schema
                "Aadhar upload": {
                    ...formData.ui_schema["Aadhar upload"], // Use existing properties
                    "ui:widget": FileDownloadWidget,
                    "ui:help": ""
                },
                "10th certificate upload": {
                    ...formData.ui_schema["10th certificate upload"],
                    "ui:widget": FileDownloadWidget,
                    "ui:help": ""
                },
                "12th certificate upload": {
                    ...formData.ui_schema["12th certificate upload"],
                    "ui:widget": FileDownloadWidget,
                    "ui:help": ""
                },
                "diploma degree certificate upload": {
                    ...formData.ui_schema["diploma degree certificate upload"],
                    "ui:widget": FileDownloadWidget,
                    "ui:help": ""
                },

                "ui:submitButtonOptions": {
                    "submitText": "Submit",
                    "norender": true
                },

                "Aadhar": {
                    ...formData.ui_schema["Aadhar"],
                    "ui:help": ""
                },

                "Other ID(If Needed / Alternative)": {
                    ...formData.ui_schema["Other ID(If Needed / Alternative)"],
                    "ui:help": ""
                },

                "10thCertificate": {
                    ...formData.ui_schema["10thCertificate"],
                    "ui:help": ""
                },

                "12thCertificate": {
                    ...formData.ui_schema["12thCertificate"],
                    "ui:help": ""
                },

                "Diploma/Degree": {
                    ...formData.ui_schema["Diploma/Degree"],
                    "ui:help": ""
                },

                "MandatoryDocument (Pending / Completed)": {
                    ...formData.ui_schema["MandatoryDocument (Pending / Completed)"],
                    "ui:help": ""
                }
            };
        }
        else {
            return ({ ...formData.ui_schema });
        }
    }

    useEffect(() => {
        const fetchAlreadyFilledFormData = async () => {
            const bodyParams = {
                stepId: stepId,
                programId: projectId,
                candidateId: candidateId
            }
            try {
                const response = await getTrackerDataByDifferentIds({}, bodyParams);
                if (response.data.result.length > 0) {
                    setViewStepData(response.data.result);
                    if (DOCUMENTATION_STEP_ID === stepId) {
                        setIsModelOpen(true);
                        setReadMode(true);
                    }
                }
            }
            catch (error) {
                console.log("Error Occurred while fetching the tracker data", error);
            }
        }
        fetchAlreadyFilledFormData();
    }, [stepId, projectId, candidateId]);

    const handleSubmitForm = async (data) => {
        setIsSubmitting(true);
        let candidateStepData = {
            programId: projectId,
            candidateId: candidateId,
            stepId: stepId,
            stepStatus: "Completed",
            data: data.formData
        }
        try {
            if (viewStepData) {
                candidateStepData.data = data.formData;
                const stepCandidateData = await updateProgramTrackerData({}, candidateStepData, viewStepData[0]._id);
                if (stepCandidateData.statusCode === 200) {
                    ShowToast("Data updated Successfully", TYPE_SUCCESS, INTERVAL, props, "");
                    window.location.reload();
                }
                else {
                    ShowToast("Something went wrong", TYPE_FAIL, INTERVAL, props, "");
                }
            }
            else {
                if (stepData.step_title.includes("Dropout") || stepData.step_title.includes("dropout")) {
                    const number = stepData.step_title.match(/\d+/);
                    if (number) {
                        const phaseNumber = parseInt(number[0]);
                        if (data.formData[`dropout phase ${phaseNumber}`]) {
                            const droppedCandidate = {
                                ...candidateData,
                                programId: projectId
                            }
                            await sendDroppedEmailCandidate(droppedCandidate);
                        }
                    }

                    if (data.formData["dropout post placement"]) {
                        const droppedCandidate = {
                            ...candidateData,
                            programId: projectId
                        }
                        await sendDroppedEmailCandidate(droppedCandidate);
                    }
                }
                const stepCandidateData = await createProgramTrackerData({}, candidateStepData);
                if (stepCandidateData.statusCode === 200) {
                    ShowToast("Data saved Successfully", TYPE_SUCCESS, INTERVAL, props, "");
                    window.location.reload();
                } else {
                    ShowToast("Something went wrong", TYPE_FAIL, INTERVAL, props, "");
                }
            }
        }
        catch (error) {
            console.log("Error Occurred while submitting form data", error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const setReadModeHandler = () => {
        if (isModelOpen === true) {
            setIsModelOpen(false);
        }
        if (readMode === true) {
            setReadMode(false);
        }
        else {
            setReadMode(true);
        }
    }

    const cancelHandle = () => {
        if (isModelOpen === true) {
            setIsModelOpen(false);
        }
    }

    return (<>
        {
            isLoading === true ? (
                <Spinner />
            ) : (
                <div className="main-content width-85">
                    <div className="step-complete-page-summary-outer-div">
                        <div className="step-complete-page-summary-inner-div">
                            <span>Project Name:</span> {projectData ? projectData.program_name : ""}
                        </div>
                        <div className="step-complete-page-summary-inner-div">
                            <span>Candidate Name:</span> {candidateData ? candidateData.first_name + " " + candidateData.last_name : ""}
                        </div>
                        <div className="step-complete-page-summary-inner-div">
                            <span>Employer Name:</span> {candidateData && candidateData.employer_unit ? candidateData.employer_unit : ""}
                        </div>
                        <div className="step-complete-page-summary-inner-div">
                            <span>Training Partner Name:</span> {candidateData && candidateData.training_partner ? candidateData.training_partner : ""}
                        </div>
                    </div>
                    {
                        DOCUMENTATION_STEP_ID === stepId && viewStepData && (
                            <div className="edit-read-button-div">
                                <button className="brand-button width-auto" onClick={() => setReadModeHandler()}>
                                    {readMode ? "Edit Mode" : "Read Mode"}
                                </button>
                            </div>
                        )
                    }
                    {
                        stepData && stepData.form_schema && stepData.ui_schema && (
                            <Form
                                schema={stepData.form_schema}
                                uiSchema={DOCUMENTATION_STEP_ID === stepId ? setDocumentStepsSchema(stepData) : stepData.ui_schema}
                                validator={validator}
                                onSubmit={handleSubmitForm}
                                formData={viewStepData ? viewStepData[0].data : null}
                            >
                                {
                                    isSubmitting && (
                                        <div className="submit-button-container">
                                            <button type="submit" className="submit-button">
                                                <span>Saving...</span>
                                            </button>
                                        </div>
                                    )
                                }
                            </Form>
                        )
                    }
                </div>
            )
        }
        <DocumentationStepPopup modelOpen={isModelOpen} closeModel={cancelHandle} setReadModeHandler={setReadModeHandler} />
    </>)
}

export default StepCompletePage;