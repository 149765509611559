import React, { Component } from 'react'
import { emailPattern, TYPE_SUCCESS, INTERVAL , TYPE_FAIL, passwordMatchPattern, TYPE_INFO } from "assets/constants/Constants";
import ShowToast from 'components/ShowToast';
import md5 from 'md5';
import {EMAIL_ICON , KEY_ICON, EYE_ICON, EYE_SLASH_ICON} from 'assets/constants/Icons';
import AuthContext from 'context/AuthProvider';
import { forgotPasswordCall ,resetPasswordByOtpCall} from "lib/axiosUtils";

export class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            emailId: "",
            password: "",
            otp: "",
            showResetPasswordScreen: false,
            newPassword: "",
            confirmPassword: "",
            showConfirmPassword: false,
            showPassword: false,
            error: {},
        }
    }

    emailIdChangeHandler = (event) => {
        this.setState({
            emailId: event.target.value
        });
    }

    changeOTPhandler = (event) => {
        this.setState({
            otp: event.target.value
        });
    }

    changeConfirmPasswordhandler = (event) => {
        this.setState({
            confirmPassword: event.target.value
        });
    }

    changeNewPasswordhandler = (event) => {
        this.setState({
            newPassword: event.target.value
        });
    }

    confirmPasswordViewHandler = (event) => {
        this.setState({
            showConfirmPassword: !this.state.showConfirmPassword
        })
    }

    passwordViewHandler = (event) => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        if (this.state.showResetPasswordScreen) {
            if (!this.state.otp) {
                isValid = false;
                error.otp = "Please enter OTP";
            }
            if (!this.state.newPassword) {
                isValid = false;
                error.newPassword = "Please enter new password";
            }
            if (!this.state.confirmPassword) {
                isValid = false;
                error.confirmPassword = "Please enter confirm password";
            }
            if (this.state.newPassword && this.state.confirmPassword) {
                if (this.state.newPassword !== this.state.confirmPassword) {
                    isValid = false;
                    error.confirmPassword = "Confirm password and password do not match";
                }
            }
            if (this.state.newPassword) {
                if (!passwordMatchPattern.test(this.state.newPassword)) {
                    isValid = false;
                    error.newPassword = "Please enter valid new password";
                    ShowToast('Password must be minimum eight characters including one uppercase letter,\n one special character and alphanumeric characters', TYPE_INFO, INTERVAL, this.props, "");
                }
            }
        }
        else {
            if (this.state.emailId) {
                if (!emailPattern.test(this.state.emailId)) {
                    isValid = false;
                    error.emailId = "Please enter valid email address.";
                }
            }
            if (!this.state.emailId) {
                isValid = false;
                error.emailId = "Please enter email id";
            }
        }
        this.setState({
            error
        });
        return isValid;
    }

    recoverPasswordHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            this.setState({ isLoading: true, })

            const requestData = {
                userEmailId: this.state.emailId,
            }
            ShowToast('Please wait while we are sending OTP', TYPE_SUCCESS, INTERVAL, this.props, "");
            let userResponse = null;
            try {
                userResponse = await forgotPasswordCall({}, requestData);
                if (userResponse && userResponse.statusCode === 200) {
                    if (userResponse.data && userResponse.data.result && Object.entries(userResponse.data.result).length === 0) {
                        ShowToast('User associated with this email id is not found !', TYPE_FAIL, INTERVAL, this.props, "");
                    }
                    else {
                        ShowToast('OTP Sent successfully !', TYPE_SUCCESS, INTERVAL, this.props, "");
                        this.setState({
                            showResetPasswordScreen: true,
                            isLoading: false
                        })
                    }
                }
            }
            catch (error) {
                console.log("Error occurred while sending otp", error);
            }
        }
    }

    resetPasswordHandler = async (event) => {
        event.preventDefault();
        try {
            if (this.isFormValid()) {
                this.setState({ isLoading: true })
                const resetPasswordParams = {
                    userEmailId: this.state.emailId,
                    userOtp: this.state.otp,
                    userPassword: md5(this.state.newPassword)
                };
                const resetResult = await resetPasswordByOtpCall({}, resetPasswordParams);
                if (resetResult && resetResult.data.result && Object.entries(resetResult.data.result).length === 0) {
                    ShowToast(`Error occurred! ${resetResult.data.message}`, TYPE_SUCCESS, INTERVAL, this.props, "");
                }
                else {
                    ShowToast('Password reset successfully! Please login with new password', TYPE_SUCCESS, INTERVAL, this.props, "/login-page");
                }
            }
        }
        catch (error) {
            console.log("Error occurred while reset password", error);
        }
    }

    render() {
        return (
            <div className={`outer_login_div`}>
                {
                    <div className="main_div">
                        <div className="brand_logo_view">
                            <img alt="Brand Logo" className="img-no-padding img-responsive" src="/images/new_brand_logo.png" />
                        </div>
                        <div className="title">{this.state.showResetPasswordScreen ? "Reset Password" : "Forgot password"}</div>
                        <form action="">
                            {
                                this.state.showResetPasswordScreen === false ? (<>
                                    <div className="input_box">
                                        <input type="email" name="emailId" value={this.state.emailId} placeholder="Enter Your Email ID" onChange={this.emailIdChangeHandler} />
                                        <div className="icon"><i className={`${EMAIL_ICON} icon`}></i></div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.emailId}
                                        </div>
                                    </div>
                                </>) : (<>
                                    <label className="form-label">OTP <span className="text-danger">*</span></label>
                                    <div className="input_box reset-password-input-box">
                                        <input type="otp" name="otp" value={this.state.otp} placeholder="Please enter OTP" onChange={this.changeOTPhandler} />
                                        <div className="icon"><i className={`${KEY_ICON} icon`}></i></div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.otp}
                                        </div>
                                    </div>
                                    <label className="form-label">New Password <span className="text-danger">*</span></label>
                                    <div className="input_box reset-password-input-box">
                                        <input type={this.state.showPassword ? "text" : "password"} name="newPassword" value={this.state.newPassword} placeholder="Please enter new password" onChange={this.changeNewPasswordhandler} />
                                        <div className="icon"><i title={this.state.showPassword === false ? "Show Password" : "Hide Password"} className={`${this.state.showPassword === false ? EYE_ICON : EYE_SLASH_ICON} icon`} onClick={this.passwordViewHandler}></i></div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.newPassword}
                                        </div>
                                    </div>
                                    <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                    <div className="input_box reset-password-input-box">
                                        <input type={this.state.showConfirmPassword ? "text" : "password"} name="confirmPassword" value={this.state.confirmPassword} placeholder="Please enter confirm password" onChange={this.changeConfirmPasswordhandler} />
                                        <div className="icon"><i className={`${this.state.showConfirmPassword === false ? EYE_ICON : EYE_SLASH_ICON} icon`} onClick={this.confirmPasswordViewHandler}></i></div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.confirmPassword}
                                        </div>
                                    </div>
                                </>)
                            }
                            <br />
                            <div className={`input_box ${this.context.styles.brandButton}`}>
                                {
                                    this.state.showResetPasswordScreen ? (
                                        <button onClick={this.resetPasswordHandler}>Reset My Password</button>
                                    ) : (
                                        <button onClick={this.recoverPasswordHandler}>Recover My Password</button>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                }
            </div>
        )
    }
}
ForgotPassword.contextType = AuthContext;
export default ForgotPassword