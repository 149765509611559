import React, { useState } from 'react';
import md5 from 'md5';
import { getPermissionMatrix } from "components/Permission";
import ShowToast from 'components/ShowToast';
import config from "../config.json";
import { styles } from '../assets/js-css/styles';
import { setTokenExpireTime } from "../lib/tokenUtils";
import { signInCall, getRoleCall } from "lib/axiosUtils";

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const classes = styles();
    const [userName, setUserName] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isUserAuthenticated, setIsAuthenticated] = useState(false);

    const configFile = { ...config };

    const applicationState = {
        userName,
        userData,
        isUserAuthenticated,
        ...configFile,
        styles: classes
    }

    const signIn = async (username, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestData = {
                    username,
                    password: md5(password),
                    device: 'web'
                };
                const encodedCredentials = Buffer.from(JSON.stringify(requestData)).toString("base64");
                const body = {
                    credentials: encodedCredentials
                };
                const responseData = await signInCall({},body);
                localStorage.setItem("JWToken", responseData.data.result.token);
                const roleResponseData = await getRoleCall({},{},responseData.data.result.result.role);

                await getPermissionMatrix();
                await setTokenExpireTime(responseData.data.result.token);

                let isAuthenticated = false;
                if (responseData.statusCode === 200) {
                    if (responseData.data.result.result) {
                        isAuthenticated = true;
                    }
                }
                localStorage.setItem("isAuthenticated", isAuthenticated);
                localStorage.setItem("userData", JSON.stringify(responseData.data.result.result));
                localStorage.setItem("userRole", JSON.stringify(roleResponseData.data.result));
                setIsAuthenticated(isAuthenticated);
                setUserData(responseData.data.result.result);
                setUserName(username);
                resolve(responseData);
            }
            catch (error) {
                console.log(error);
                reject(null);
            }
        });
    }

    const signOut = () => {
        const loginURL = localStorage.getItem("loginURL");
        setIsAuthenticated(false);
        setUserData(null);
        setUserName(null);
        localStorage.setItem("isAuthenticated", false);
        localStorage.setItem("userData", null);
        localStorage.removeItem("JWToken");
        localStorage.removeItem("metadata");
        localStorage.clear();
        ShowToast('You are logged out!', 'success');
        if (loginURL) {
            window.location.href = loginURL;
        }
        else {
            window.location.href = "/login-page";
        }
    }

    const getUserDetails = async () => {
        return new Promise((resolve) => {
            if (localStorage.getItem("userData")) {
                resolve(JSON.parse(localStorage.getItem("userData")));
            }
            else {
                resolve(null);
            }
        });
    }

    const isAuthenticated = () => {
        if (localStorage.getItem("isAuthenticated") === "false") {
            return false;
        }
        else if (localStorage.getItem("isAuthenticated") === "true") {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <AuthContext.Provider value={{ ...applicationState, isAuthenticated, signIn, signOut, getUserDetails }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext
