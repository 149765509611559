import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "context/AuthProvider";
import { RouterComponent } from "components/RouterComponent";
import { refreshAccessToken } from "./lib/tokenUtils";
import { REFRESH_TOKEN_THRESHOLD } from "./assets/constants/Constants";

// styles
import "assets/css/bootstrap.css";
import "assets/css/main.css";
import "assets/css/fonts.css";

if (localStorage.getItem("tokenExpireTime")) {
  const expiredTime = localStorage.getItem("tokenExpireTime");
  const currentTime = Math.floor(Date.now() / 1000);
  let tokenRefreshTime = expiredTime - currentTime;

  if (tokenRefreshTime < REFRESH_TOKEN_THRESHOLD) {
    refreshAccessToken(localStorage.getItem("JWToken"));
  }
}


ReactDOM.render(
  <>
    <AuthProvider>
      <BrowserRouter>
        <RouterComponent />
      </BrowserRouter>
    </AuthProvider>
  </>,
  document.getElementById("root")
);