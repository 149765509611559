import React, { useState, useEffect} from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root')

function DocumentationStepPopup(props) {
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(props.modelOpen);
    },[props]);

    const closeModal = () => {
        setModalOpen(false);
        props.cancelHandle();
    }
    const cancelHandle = (event) => {
        setModalOpen(false);
        props.closeModel();
    }
    const editHandle = () => {
        setModalOpen(false);
        props.setReadModeHandler();
    }

    return (
        <>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="program-modal" overlayClassName="program-modal-overlay">
                <div className="program-modal-container job-disabled-width partner-modal-div program-modal-div" role="document">
                    <div className="program-modal-inner-div error-modal-inner-div">
                    <h4 className="import-error-heading">Please Read !!</h4>
                        <p>The Step data is already filled, you can view the data and download the files from here. 
                            If you want to edit the data, then please click on the edit button, else click cancel button.
                             Also, you can switch to the read and edit mode using the button, which is just above the heading Documentation</p>
                    </div>
                    <div className="form-button-div mt-5">
                        <button className="brand-button" onClick={editHandle}>Edit</button>
                        <button className="button-secondary ml-3" onClick={cancelHandle}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default DocumentationStepPopup;