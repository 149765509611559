import React, { useState, useContext } from "react";
import AuthContext from 'context/AuthProvider';
import Toast from 'light-toast';
import { USER_ICON, SIGN_OUT_ICON, USERS_ICON, PROGRAM_ICON, STEPS_ICON, BAR_ICON, TIMES_CIRCLE_ICON, EMPLOYER, TRAININGPART, USER_PLUS_ICON ,METER_ICON} from "assets/constants/Icons";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { hasRoutePermission } from "components/Permission";

function SideNavigationBar(props) {
    const authContext = useContext(AuthContext);
    const userDetail = JSON.parse(localStorage.getItem("userData"));
    const userRole = JSON.parse(localStorage.getItem("userRole"));
    const [menuCollapse, setMenuCollapse] = useState(true);
    const [hideNavbar] = useState(window.location.href.includes("/step-complete") ? true : false);

    const editUserHandler = (userDetail) => {
        props.history.push({
            pathname: "/create-user-page",
            state: { ...userDetail, isFromProfile: true }
        });
    }

    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);

        if (!authContext.isAuthenticated()) {
            Toast.fail('You are not logged in. Please Login!', 3000, () => {
                window.location.href = "/login-page";
            });
        }
    };

    return (
        userDetail && (
            <div className={`side-navigation-bar ${hideNavbar === true ? "disaplay-none": ""}`}>
                <div className="side-navigation-bar-hamburger-button" onClick={menuIconClick}>
                    {(menuCollapse) && (<span className={`${BAR_ICON} side-navigation-bar-icon`} aria-hidden="true"></span>)}
                    {(!menuCollapse) && (<span className={`${TIMES_CIRCLE_ICON} side-navigation-bar-icon`} aria-hidden="true"></span>)}
                </div>
                <div className="side-navigation-bar-content">
                    <ProSidebar collapsed={menuCollapse} breakPoint="md" rtl={false}>
                        <SidebarHeader>
                            {userDetail && (
                                <Menu iconShape="circle">
                                    <MenuItem icon={<i className={`${USER_ICON} side-navigation-bar-icon`}></i>} onClick={() => editUserHandler(userDetail)} title="Edit user information">
                                        <span>{`Hi, ${userDetail.first_name} `}</span>
                                        <span>{`(${userRole.user_role})`}</span>
                                    </MenuItem>
                                </Menu>
                            )}
                        </SidebarHeader>

                        <SidebarContent>
                            <Menu iconShape="square">
                                {
                                    hasRoutePermission("/admin-dashboard-page") && (
                                        <MenuItem icon={<i className={`${METER_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Admin Dashboard">
                                            <a className="side-navigation-bar-menu-title" href="/admin-dashboard-page">Admin Dashboard</a>
                                        </MenuItem>
                                    )
                                }
                                {
                                    userDetail && userDetail.employer_id &&(
                                        hasRoutePermission("/employer-dashboard-page") && (
                                            <MenuItem icon={<i className={`${METER_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Employer Dashboard">
                                                <a className="side-navigation-bar-menu-title" href="/employer-dashboard-page">Employer Dashboard</a>
                                            </MenuItem>
                                        )  
                                    )
                                }
                                {
                                    userDetail && userDetail.partner_id &&(
                                        hasRoutePermission("/partner-dashboard-page") && (
                                            <MenuItem icon={<i className={`${METER_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Partner Dashboard">
                                                <a className="side-navigation-bar-menu-title" href="/partner-dashboard-page">Partner Dashboard</a>
                                            </MenuItem>
                                        )  
                                    )
                                }
                                {
                                    hasRoutePermission("/project-list") && (
                                        <MenuItem icon={<i className={`${PROGRAM_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Projects">
                                            <a className="side-navigation-bar-menu-title" href="/project-list">Projects</a>
                                        </MenuItem>
                                    )
                                }
                                {
                                    hasRoutePermission("/employer-page") && (
                                        <MenuItem icon={<i className={`${EMPLOYER} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Employer">
                                            <a className="side-navigation-bar-menu-title" href="/employer-page">Employer</a>
                                        </MenuItem>
                                    )
                                }
                                {
                                    hasRoutePermission("/partner-page") && (
                                        <MenuItem icon={<i className={`${TRAININGPART} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Training Partner">
                                            <a className="side-navigation-bar-menu-title" href="/partner-page">Training Partner</a>
                                        </MenuItem>
                                    )
                                }
                                {
                                    hasRoutePermission("/candidate-page") && (
                                        <MenuItem icon={<i className={`${USERS_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Candidate">
                                            <a className="side-navigation-bar-menu-title" href="/candidate-page">Candidate</a>
                                        </MenuItem>
                                    )
                                }

                                <SidebarFooter>
                                    <Menu>

                                        {
                                            hasRoutePermission("/user-page") && (
                                                <SubMenu id="side-navigation-bar-sub-menu-user" className={ menuCollapse === true ? `side-navigation-bar-sub-menu-user` :""} icon={<i className={`${USER_PLUS_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="User">
                                                    <MenuItem>
                                                        <a className="side-navigation-bar-menu-title" href="/user-page"> User Master</a>
                                                    </MenuItem>
                                                    {
                                                        hasRoutePermission("/roles-page") && (
                                                            <MenuItem>
                                                                <a className="side-navigation-bar-menu-title" href="/roles-page">Role</a>
                                                            </MenuItem>
                                                        )
                                                    }
                                                </SubMenu>
                                            )
                                        }

                                    </Menu>
                                </SidebarFooter>
                                {
                                    hasRoutePermission("/roles-page") && (
                                        <MenuItem icon={<i className={`${STEPS_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Project Steps">
                                            <a className="side-navigation-bar-menu-title" href="/step-list-page">Project Steps</a>
                                        </MenuItem>
                                    )
                                }
                            </Menu>
                            <SidebarFooter>
                                <Menu>
                                    {/* <MenuItem icon={<i className={`${PLUS_SQUARE_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Step Creation">
                                    <a className="side-navigation-bar-menu-title" href="/create-step-page">Step Creation</a>
                                </MenuItem> */}
                                    {/* <MenuItem icon={<i className={`${STEPS_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Project Steps">
                                        <a className="side-navigation-bar-menu-title" href="/step-list-page">Project Steps</a>
                                    </MenuItem> */}
                                    {/* {
                                    hasRoutePermission("/create-field-schema-page") && (
                                        <MenuItem icon={<i className={`${VCARD_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Create Field schema">
                                            <a className="side-navigation-bar-menu-title" href="/create-field-schema-page">Create Field schema</a>
                                        </MenuItem>
                                    )
                                } */}
                                    {/* {
                                        hasRoutePermission("/field-schema-page") && (
                                            <MenuItem icon={<i className={`${LIST_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Project Fields">
                                                <a className="side-navigation-bar-menu-title" href="/field-schema-page">Project Fields</a>
                                            </MenuItem>
                                        )
                                    } */}
                                </Menu>
                            </SidebarFooter>
                        </SidebarContent>

                        <SidebarFooter>
                            <Menu>
                                <MenuItem icon={<i className={`${SIGN_OUT_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Logout" onClick={authContext.signOut}>
                                    <span>Logout</span>
                                </MenuItem>
                            </Menu>
                        </SidebarFooter>
                    </ProSidebar>
                </div>
            </div>
        )

    );
};

export default SideNavigationBar;
