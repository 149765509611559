import React, { Component } from 'react';
import { USER_ICON , EYE_ICON, EYE_SLASH_ICON} from "assets/constants/Icons";
import { SECRET_KEY, PHONE_NUMBER_PATTERN, emailPattern, passwordMatchPattern, TYPE_SUCCESS, TYPE_FAIL, TYPE_INFO, INTERVAL} from "assets/constants/Constants";
import md5 from 'md5';
import Spinner from 'components/Spinner';
import CryptoJS from "crypto-js";
import ShowToast from 'components/ShowToast';
import Select from 'react-select';
import { fetchMetadata, toCapitaliseWord} from 'lib/utils';
import { getRoleListCall, getPartnerList, getEmployerList, findUserNameCall, createUserCall, UpdateUserCall } from "lib/axiosUtils";

export class CreateUserPage extends Component {
    constructor(props) {
        super(props)

        const editData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            isUsernamePresent: false,
            userId: editData ? editData._id : null,
            firstName: editData ? editData.first_name : "",
            lastName: editData ? editData.last_name : "",
            emailId: editData ? editData.email_id : "",
            phoneNumber: editData ? editData.phone_number : "",
            userName: editData ? editData.username : "",
            password: editData ? editData.password : "",
            confirmPassword: editData ? editData.password : "",
            userRole: editData ? editData.role : "",
            isFromProfile: editData ? editData.isFromProfile : "",
            siteList: [],
            roleList: [],
            userList: [],
            error: {},
            selectProfile: editData && editData.select_profile ? {value:editData.select_profile,label:editData.select_profile} : "",
            selectedEmployer: editData ? editData.company_id ? {value:editData.company_id,label:editData.company_id}: null : null,
            employerUnit: editData ? editData.employer_id ? {value:editData.employer_id,label:editData.employer_id}: null : null,
            selectedPartner: editData ? editData.project_id ? {value:editData.project_id,label:editData.project_id}: null : null,
            selectedTrainingPartner: editData ? editData.project_id ? {value:editData.project_id,label:editData.partner_id}: null : null,
            profileOptions :[
                {value: 'employer', label: 'Employer' },
                {value: 'traning partner', label: 'Traning Partner'},
            ],
            PartnerListComponent: "",
            setPartnerList: "",
            setPartnerComponent: "",
            employerList:[],
            list: [],
            employerUnitComponent: "",
            setDisableTrainingPartner: true,
            setDisableEmployerUnit: true,
            partnerList:"",
            setTrainingPartnerComponent:"<option> Training Partner List </option>",
            roleListComponent: "<option> Select User Role </option>",
            showPassword: false,
            showConfirmPassword: false,
            auditflag: editData ? editData.audit_enable : false
        }
    }

    async componentDidMount() {
        const roleData = await getRoleListCall({},{});
        const partnerList = await this.fetchPartnerList();
        this.setState({
            roleList: roleData.data.result,
            isLoading: false,
            partnerList: partnerList.data.result,
            setSelectedTrainingPartner:this.trainingPartnerList,
        }, () => {
            this.PartnerComponent(partnerList.data.result);
            this.setListComponent();
            this.fetchEmployerList();
            this.setUserRoleComponent();
        })
    }

    setUserRoleComponent = () => {
        if(this.state.roleList.length > 0){
            const listComponent = this.state.roleList.map((item) => {
                if(this.state.userRole){
                    if(this.state.userRole === item._id){
                        this.setState({
                            userRole : {label: toCapitaliseWord(item.user_role), value: item._id}
                        });
                    }
                }
                return {label: toCapitaliseWord(item.user_role), value: item._id}
            })
            this.setState({
                roleListComponent:  listComponent
            })
        }
    }

    firstNameChangeHandler = (event) => {
        this.setState({
            firstName: event.target.value
        });
    }

    lastNameChangeHandler = (event) => {
        this.setState({
            lastName: event.target.value
        });
    }

    emailIdChangeHandler = (event) => {
        this.setState({
            emailId: event.target.value
        });
    }

    phoneNumberChangeHandler = (event) => {
        this.setState({
            phoneNumber: event.target.value
        });
    }

    userNameChangeHandler = (event) => {
        this.setState({
            userName: event.target.value
        });
    }

    handleShowPasswordClick = (event) => {
        if (!this.state.userId) {
            this.setState({
                showPassword: !this.state.showPassword
            })
        }
    }

    handleShowConfirmPasswordClick = (event) => {
        if (!this.state.userId) {
            this.setState({
                showConfirmPassword: !this.state.showConfirmPassword
            })
        }
    }

    usernameUniqueHandler = async (event) => {
        if (event.target.value) {
            const userData = await findUserNameCall({},{},event.target.value.trim().toLowerCase());
            if (this.state.userId === null) {
                this.setState({
                    isUsernamePresent: userData.data.result.length > 0 ? true : false
                });

                if (userData.data.result.length > 0) {
                    ShowToast('Username already present, please try with different username!', TYPE_FAIL, INTERVAL, this.props, "");
                }
            }
        }
    }

    passwordChangehandler = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    confirmPasswordChangehandler = (event) => {
        this.setState({
            confirmPassword: event.target.value
        });
    }

    userRoleChaneHandler = (event) => {
        this.setState({
            userRole: event
        })
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.firstName) {
            isValid = false;
            error.firstName = "Please enter first name";
        }

        if (!this.state.lastName) {
            isValid = false;
            error.lastName = "Please Enter last name";
        }

        if (!this.state.emailId) {
            isValid = false;
            error.emailId = "Please enter email id";
        }

        if (this.state.emailId) {
            if (!emailPattern.test(this.state.emailId)) {
                isValid = false;
                error.emailId = "Please enter valid email address.";
            }
        }

        if (!this.state.phoneNumber) {
            isValid = false;
            error.phoneNumber = "Please enter phone number";
        }

        if (this.state.phoneNumber !== "undefined") {
            if (!PHONE_NUMBER_PATTERN.test(this.state.phoneNumber)) {
                isValid = false;
                error.phoneNumber = "Please enter valid phone number.";
            }
        }

        if (!this.state.userName) {
            isValid = false;
            error.userName = "Please enter username";
        }

        if (!this.state.password) {
            isValid = false;
            error.password = "Pleae enter password";
        }

        if (this.state.password) {
            if (this.state.userId === null) {
                if (!passwordMatchPattern.test(this.state.password)) {
                    isValid = false;
                    error.password = "Please enter valid password";
                    ShowToast('Password must be minimum eight characters including one uppercase letter,\n one special character and alphanumeric characters', TYPE_INFO, INTERVAL, this.props, "");
                }
            }
        }

        if (!this.state.confirmPassword) {
            isValid = false;
            error.confirmPassword = "Please enter confirm password";
        }

        if (this.state.password !== this.state.confirmPassword) {
            isValid = false;
            error.confirmPassword = "Entered password and confirm password does not match";
        }

        if (!this.state.userRole) {
            isValid = false;
            error.userRole = "Please select user role";
        }

        if (this.state.isUsernamePresent) {
            if (this.state.userId === null) {
                isValid = false;
                error.userName = "Please enter different username";
            }
        }
      
        if (this.state.selectedEmployer === "") {
            isValid = false;
            error.selectedEmployerError = "Please select Employer";
        }
      
        if(this.state.employerUnit === "" && this.state.setDisableEmployerUnit === false){
            isValid = false;
            error.employerUnitError = "Please select the Employer unit";
        }
        
        if (this.state.selectedPartner === "") {
            isValid = false;
            error.selectedPartnerError = "Please select the Project";
        }
       
        if(this.state.selectedTrainingPartner === "" && this.state.setDisableTrainingPartner === false){
            isValid = false;
            error.selectedTrainingPartnerError = "Please select the training partner";
        }

        this.setState({
            error
        });
        return isValid;
    }

    createUserHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            this.setState({ isLoading: true, })
            const requestData = {
                userFirstName: this.state.firstName,
                userLastName: this.state.lastName,
                userEmailId: this.state.emailId,
                userPhoneNumber: this.state.phoneNumber,
                userName: this.state.userName.trim().toLowerCase(),
                userPassword: this.state.userId === null ? md5(this.state.password) : this.state.password,
                displayPass: CryptoJS.AES.encrypt(this.state.password, SECRET_KEY).toString(),
                userType: this.state.userType,
                userRole: this.state.userRole && this.state.userRole.value ? this.state.userRole.value : "",
                userSelectProfile:  this.state.selectProfile && this.state.selectProfile.label ? this.state.selectProfile.label :  null,
                userSelectedEmployer: this.state.selectProfile.label === "Employer" ? (this.state.selectedEmployer ? this.state.selectedEmployer.value : null): null,
                userEmployerUnit:this.state.selectProfile.label === "Employer" ? (this.state.employerUnit ? this.state.employerUnit.value : null): null,
                userSelectedPartner: this.state.selectProfile.label === "Traning Partner" ? (this.state.selectedPartner ? this.state.selectedPartner.value: null): null,
                userSelectedTrainingPartner: this.state.selectProfile.label === "Traning Partner" && this.state.selectedPartner.label !== "TIMES-PRO" ?  (this.state.selectedTrainingPartner ? this.state.selectedTrainingPartner.value:null): null,
                auditflag: this.state.auditflag
            }
            let userResponse = null;
            if (this.state.userId === null) {
                userResponse = await createUserCall({},requestData);
            }
            else {
                userResponse = await UpdateUserCall({},requestData,this.state.userId);
            }

            if (userResponse.statusCode === 200) {
                this.setState({
                    firstName: "",
                    lastName: "",
                    emailId: "",
                    phoneNumber: "",
                    userName: "",
                    password: "",
                    confirmPassword: "",
                    userType: "",
                    selectProfile: "",
                    selectedEmployer: "",
                    employerUnit: "",
                    selectedPartner: "",
                    selectedTrainingPartner: "",
                    error: {},
                    isLoading: false,
                }, () => {
                    if (this.state.userId === null) {
                        ShowToast('User created Successfully', TYPE_SUCCESS, INTERVAL, this.props, `${this.state.isFromProfile ? "project-list" : "/user-page"}`);
                    }
                    else {
                        ShowToast('User updated Successfully', TYPE_SUCCESS, INTERVAL, this.props, `${this.state.isFromProfile ? "project-list" : "/user-page"}`);
                    }
                    this.props.history.push(`${this.state.isFromProfile ? "project-list" : "/user-page"}`);
                });
            }
        }
    }

    cancelHandler = () => {
        this.props.history.push(`${this.state.isFromProfile ? "project-list" : "/user-page"}`);
    }

    fetchPartnerList = async () => {
        try {
            const response = await getPartnerList({},{});
            this.setState({
                partnerList: response.data.result
            },
                () => {
                    this.state.partnerList.forEach((item) => {
                        if (this.state.selectedPartner) {
                            if (item._id === this.state.selectedPartner.value) {
                                this.setState({
                                    selectedPartner: { label: item.partner_name, value: item._id }
                                })
                                if (item.partner_name === "TIMES-PRO") {
                                    this.setState({
                                        setDisableTrainingPartner: true,
                                        selectedTrainingPartner: null
                                    })
                                }
                            }
                        }
                        if (this.state.selectedTrainingPartner) {
                            if (item._id === this.state.selectedTrainingPartner.value) {
                                this.setState({
                                    selectedTrainingPartner: { label: item.training_partner, value: item._id }
                                })
                            }
                        }
                    });
                })
            return response;
        }
        catch (error) {
            console.log("Error Occurred while fetching training partner list", error);
        }
    }

    PartnerComponent = async (list) => {
        let listComponent = [];
        list.forEach((item) => {
            if (item.partner_name) {
                listComponent.push({ label: item.partner_name, value: item._id })
            }
        });
        const uniqueArray = Array.from(
            listComponent.reduce((map, obj) => map.set(obj.label, obj), new Map()).values()
        );
        this.setState({
            setPartnerList: uniqueArray
        })
    }

    setTrainingPartnerListComponent = async (selectedPartner, list) => {
        let listComponent = [];
        list.forEach((item) => {
            if (item.partner_name === selectedPartner) {
                if (item.training_partner) {
                    listComponent.push({ label: item.training_partner, value: item._id })
                }
            }
        });
        this.setState({
            setTrainingPartnerComponent: listComponent
        })
    }

    setListComponent = async () => {
        let metaData = JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : [];
        if (metaData && metaData.length === 0) {
            metaData = await fetchMetadata();
        }
        const companyMetaData = metaData[0].meta_data.companies;
        const listComponent = companyMetaData.map((item) => {
            if (this.props.location.state) {
                if (this.props.location.state.company_id === item.company_id) {
                    this.setState({
                        selectedEmployer: { label: item.company_name, value: item.company_id }
                    })
                }
            }
            return { label: item.company_name, value: item.company_id }
        });
        this.setState({
            employerListComponent: listComponent
        })
    }

    setEmployerUnitListComponent = (event) => {
        let list = [];
        this.state.employerList.forEach((item) => {
            if (event.label === item.employer_name) {
                list.push({ label: item.unit_name, value: item._id });
                this.setState({
                    employerUnit: ""
                })
            }
        
        if(list.length === 0){
            this.setState({
                setDisableEmployerUnit:true,
                employerUnit:""
            })
        }
        else{
            this.setState({
                setDisableEmployerUnit:false,
            })
        }
    })
        this.setState({
            employerUnitComponent: list
        })
    }

    fetchEmployerList = async () => {
        try {
            const data = await getEmployerList({},{});
            this.setState({
                employerList: data.data.result
            }, () => {
                this.state.employerList.forEach((item) => {
                    if (this.state.employerUnit) {
                        if (item._id === this.state.employerUnit.value) {
                            this.setState({
                                employerUnit: { label: item.unit_name, value: item._id }
                            })
                        }
                    }
                });
            })
            this.setListComponent();
        }
        catch (error) {
            console.log("error while fetching data", error);
        }
    }

    handleProfileChange = (event) => {
        if (event.label === "Employer") {
            this.setState({
                selectedEmployer: "",
                employerUnit: ""
            });
        }
        if(event.label === "Traning Partner"){
            this.setState({
                selectedPartner: "",
                selectedTrainingPartner: "",
            })
        }
        this.setState({
            selectProfile: { label: event.label, value: event.value },
        })
    }

    changeProgram = (event) => {
        if (event.label === "TIMES-PRO") {
            this.setState({
                selectedTrainingPartner: "",
                setDisableTrainingPartner: true
            })
        }
        else {
            this.setState({
                setDisableTrainingPartner: false
            })
        }
        this.setState({
            selectedPartner: { label: event.label, value: event.value },
        }, () => {
            this.setTrainingPartnerListComponent(event.label, this.state.partnerList);
        })
    }

    changeTrainingParnter = (event) => {
        this.setState({
            selectedTrainingPartner: { label: event.label, value: event.value },
        })
    }

    changeCompany = (event) => {
        this.setState({
            selectedEmployer: { label: event.label, value: event.value }
        }, () => {
            this.setEmployerUnitListComponent(event);
        })
    }

    changeEmployerUnit = (event) => {
        this.setState({
            employerUnit: { label: event.label, value: event.value }
        })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/user-page">User Master</a></li>
                            {
                                this.state.userId === null ? (
                                    <li>Add New User</li>
                                ) : (
                                    <li>Edit User</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-user-wrapper">
                        <div className="mx-auto text-left border create-user-div">
                            <form onSubmit={this.handleSubmit} className="pt-1">
                                <div className="user-icon-wrapper">
                                    <h4>{this.state.userId === null ? "Add New User" : "Edit User"}</h4>
                                    <i className={`${USER_ICON} list-table-icon`}></i>
                                </div>

                                <div className="row mt-0">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">First Name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.firstName} onChange={this.firstNameChangeHandler} placeholder="Enter First Name" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.firstName}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.lastName} onChange={this.lastNameChangeHandler} placeholder="Enter Last Name" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.lastName}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-0">
                                    <div className="input-label-group col-md-6 email-input">
                                        <label className="form-label">Email Id <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height lowercase-email" value={this.state.emailId} onChange={this.emailIdChangeHandler} placeholder="Enter Email Id" disabled={this.state.isFromProfile} />
                                        <div className="text-danger error-msg">
                                            {this.state.error.emailId}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.phoneNumber} onChange={this.phoneNumberChangeHandler} placeholder="Enter Phone Number" maxLength="10" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.phoneNumber}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="form-label-outside" htmlFor="general-information-skills">Select Profile</label>
                                            <div className="form-wrap-inner">
                                                <Select
                                                    className={"add-user-input-height"}
                                                    placeholder={`Select Profile`}
                                                    options={this.state.profileOptions}
                                                    value={this.state.selectProfile}
                                                    onChange={this.handleProfileChange}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    isDisabled={this.state.isFromProfile}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.selectProfileError}
                                        </div>
                                    </div>
                                </div>
                                {this.state.selectProfile.label === "Employer" && (
                                    <div className="row">
                                        <div className="input-label-group col-md-6">
                                            <label className="form-label">Select Company <span className="text-danger">*</span></label>
                                            <div className="form-wrap-inner">
                                                <Select
                                                    placeholder={`Select Company`}
                                                    value={this.state.selectedEmployer}
                                                    onChange={this.changeCompany}
                                                    options={this.state.employerListComponent}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    isDisabled={this.state.isFromProfile}
                                                />
                                            </div>
                                            <div className="text-danger error-msg">
                                                {this.state.error.selectedEmployerError}
                                            </div>
                                        </div>
                                        <div className="input-label-group col-md-6">
                                            <label className="form-label">Select Employer Unit <span className="text-danger">*</span></label>
                                            <div className="form-wrap-inner">
                                                <Select
                                                    placeholder={`Select Employer Unit`}
                                                    value={this.state.employerUnit}
                                                    onChange={this.changeEmployerUnit}
                                                    options={this.state.employerUnitComponent}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    isDisabled={this.state.setDisableEmployerUnit}
                                                />
                                            </div>
                                            <div className="text-danger error-msg">
                                                {this.state.error.employerUnitError}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.selectProfile.label === "Traning Partner" && (
                                    <div className="row">
                                        <div className="input-label-group col-md-6">
                                            <label className="form-label">Select Program <span className="text-danger">*</span></label>
                                            <div className="form-wrap-inner">
                                                <Select
                                                    placeholder={`Select Program`}
                                                    value={this.state.selectedPartner}
                                                    onChange={this.changeProgram}
                                                    options={this.state.setPartnerList}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    isDisabled={this.state.isFromProfile}
                                                />
                                            </div>
                                            <div className="text-danger error-msg">
                                                {this.state.error.selectedPartnerError}
                                            </div>
                                        </div>
                                        <div className="input-label-group col-md-6">
                                            <label className="form-label">Select Training Partner <span className="text-danger">*</span> </label>
                                            <div className="form-wrap-inner">
                                                <Select
                                                    placeholder={`Select  Training Partner`}
                                                    value={this.state.selectedTrainingPartner}
                                                    onChange={this.changeTrainingParnter}
                                                    options={this.state.setTrainingPartnerComponent}
                                                    isSearchable={true}
                                                    isDisabled={this.state.setDisableTrainingPartner}
                                                />
                                            </div>
                                            <div className="text-danger error-msg">
                                                {this.state.error.selectedTrainingPartnerError}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="input-label-group col-md-6 email-input">
                                        <label className="form-label">User Name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.userName} onChange={this.userNameChangeHandler} onBlur={this.usernameUniqueHandler} placeholder="Enter User Name" disabled={this.state.isFromProfile} />
                                        <div className="text-danger error-msg">
                                            {this.state.error.userName}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Password <span className="text-danger">*</span></label>
                                        <div className="password-input-div">
                                            {
                                                this.state.showPassword === true ? (<>
                                                    <input type="text" className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.password} onChange={this.passwordChangehandler} placeholder="Enter Password" disabled={this.state.userId ? true : false} />
                                                    <i className={`${EYE_SLASH_ICON} eye-icon-class-for-password`} onClick={this.handleShowPasswordClick} title="View password"></i>
                                                </>) : (<>
                                                    <input type="password" className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.password} onChange={this.passwordChangehandler} placeholder="Enter Password" disabled={this.state.userId ? true : false} />
                                                    <i className={`${EYE_ICON} eye-icon-class-for-password`} onClick={this.handleShowPasswordClick} title="View password"></i>
                                                </>)
                                            }
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.password}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                        <div className="password-input-div">
                                            {
                                                this.state.showConfirmPassword === true ? (<>
                                                    <input type="text" className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.confirmPassword} onChange={this.confirmPasswordChangehandler} placeholder="Confirm Password" disabled={this.state.userId ? true : false} />
                                                    <i className={`${EYE_SLASH_ICON} eye-icon-class-for-password`} onClick={this.handleShowConfirmPasswordClick} title="View Password"></i>
                                                </>) : (<>
                                                    <input type="password" className="form-control brand-font p-3 placeholderColor add-user-input add-user-input-height" value={this.state.confirmPassword} onChange={this.confirmPasswordChangehandler} placeholder="Confirm Password" disabled={this.state.userId ? true : false} />
                                                    <i className={`${EYE_ICON} eye-icon-class-for-password`} onClick={this.handleShowConfirmPasswordClick} title="View Password"></i>
                                                </>)
                                            }
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.confirmPassword}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="form-label-outside" htmlFor="general-information-skills">User Role </label>
                                            <div className="form-wrap-inner">
                                            <Select
                                            placeholder={`Select User Role`}
                                            value={this.state.userRole}
                                            onChange={this.userRoleChaneHandler}
                                            options={this.state.roleListComponent}
                                            isSearchable={true}
                                            isDisabled={this.state.isFromProfile}
                                        />
                                            </div>
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.selectProfileError}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-0 mt-4 mb-5 display-inline">
                                    <div className="form-button-div">
                                        <button className="brand-button" onClick={this.createUserHandler}>Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row dummy_row"></div>
                </div>
            )
        }
    }
}

export default CreateUserPage;